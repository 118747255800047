import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import styles from "./CallToAction.module.scss";

export interface CallToActionProps {
	title: string;
	subtitle: string;
	buttonText?: string;
	onClick?: () => void;
}

export const CallToAction = (props: CallToActionProps):React.JSX.Element => {
	return (
		<div className={styles.callToAction}>
			<div className={styles.title}>{props.title}</div>
			<div className={styles.subtitle}>{props.subtitle}</div>
			{
				(props.buttonText || props.onClick) && (
					<GreenButtonSet buttons={[
						{
							text: props.buttonText,
							onClick: props.onClick
						}
					]} />
				)
			}
		</div>
	);
};

import { QueryClient, QueryKey } from "@tanstack/react-query";
import { paginatedRemove } from "./paginatedRemove";

export const removeFromQueryKeyIfExists = <T>(queryClient: QueryClient, queryKey: QueryKey, predicate: (x: T) => boolean) => {
	if (!queryClient.getQueryData(queryKey)) {
		return;
	}

	queryClient.setQueryData(queryKey, paginatedRemove(predicate));
};

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useOperationNormaliseQuantity } from "@fyendalscollection/app/shared";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";
import { Paragraph } from "@fyendalscollection/app/lib/components/Paragraph";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { NumericEntry } from "@fyendalscollection/app/lib/components/NumericEntry";

export interface ModalNormaliseQuantityOperationProps {
	visible: boolean;
	onCancel: () => void;
	collectionId: string;
}

export const ModalNormaliseQuantityOperation = ({ visible, onCancel, collectionId }: ModalNormaliseQuantityOperationProps): React.JSX.Element => {
	const [normalisedQuantity, setNormalisedQuantity] = useState(0);
	const navigate = useNavigate();

	const normaliseOperation = useOperationNormaliseQuantity();

	useEffect(() => {
		if (normaliseOperation.isSuccess) {
			setNormalisedQuantity(0);
			onCancel();
			navigate("..");
		}
	}, [normaliseOperation.isSuccess]);

	const performNormaliseQuantityOperation = () => {
		if (!collectionId || !normalisedQuantity) {
			return;
		}

		normaliseOperation.mutate(
			{
				requestModel: {
					normalisedQuantity: normalisedQuantity
				},
				collectionId: collectionId
			}
		);
	};

	return (
		<Modal visible={visible} onCancel={onCancel}>
			<Loadable loading={normaliseOperation.isPending}>
				<TitleSecondary text="Normalise Quantity" />

				<Paragraph>
					Enter in the quantity you would like to set for all your transactions.
				</Paragraph>

				<Container>
					<ContainerSegment>
						<NumericEntry
							value={normalisedQuantity}
							onChange={setNormalisedQuantity}
							maxDecimalPlaces={0}
							minValue={0}
							maxValue={100000} />
					</ContainerSegment>
				</Container>

				{
					normaliseOperation.isError && (
						<Container>
							<ContainerSegment>
								<ErrorDisplay problemResponse={normaliseOperation.error}/>
							</ContainerSegment>
						</Container>
					)
				}

				<GreenButtonSet buttons={[
					{
						text: "Normalise",
						disabled: !normalisedQuantity || !normalisedQuantity,
						onClick: performNormaliseQuantityOperation
					},
					{
						text: "Cancel",
						onClick: onCancel
					}
				]} />
			</Loadable>
		</Modal>
	);
};

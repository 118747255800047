import { InfiniteData, Updater } from "@tanstack/react-query";
import { PaginatedResponseModel } from "./PaginatedResponseModel";

export const paginatedUpdate = <T>(x: T, predicate: (x: T) => boolean): Updater<InfiniteData<PaginatedResponseModel<T>> | undefined, InfiniteData<PaginatedResponseModel<T>>> => {
	return (oldData: InfiniteData<PaginatedResponseModel<T>> | undefined): InfiniteData<PaginatedResponseModel<T>> => {
		if (!oldData || oldData.pages.length < 1) {
			return {
				pages: [{
					results: [ x ],
					nextCursor: 1
				}],
				pageParams: [ 0 ]
			};
		}

		return {
			pages: oldData.pages.map(page => ({
				results: page.results.map(result => predicate(result) ? x : result),
				nextCursor: page.nextCursor
			})),
			pageParams: oldData.pageParams
		};
	};
};

import { Select } from "@fyendalscollection/app/lib/components/Select";
import styles from "./PriceSourceSelector.module.scss";
import { PriceSource } from "@fyendalscollection/app/shared";
import { StyledSelect } from "@fyendalscollection/app/lib/components/StyledSelect";
import { ENABLE_SALE_VOLUMES } from "@fyendalscollection/app/lib/config";

export interface PriceSourceSelectorProps {
	value: PriceSource | null;
	onChange: (value: PriceSource) => void;
	styled?: boolean;
}

export const PriceSourceSelector = ({ value, onChange, styled }: PriceSourceSelectorProps): React.JSX.Element => {
	const priceSourceOptions = [
		{
			text: "TCGplayer Market Pricing",
			value: PriceSource.TcgPlayerMarket
		},
		{
			text: "TCGplayer Low Pricing",
			value: PriceSource.TcgPlayerLow
		}
	];

	if (ENABLE_SALE_VOLUMES) {
		priceSourceOptions.push({
			text: "TCGplayer Recent Sales",
			value: PriceSource.TcgPlayerRecent
		});
	}

	return (
		<div className={styles.priceSourceSelector}>
			{
				!styled ?
					<Select value={value || PriceSource.TcgPlayerMarket} onChange={onChange} options={priceSourceOptions}/> :
					<StyledSelect value={value || PriceSource.TcgPlayerMarket} onChange={onChange} options={priceSourceOptions} />
			}
		</div>
	);
};

import styles from "./BannerMessage.module.scss";

export interface BannerMessageProps {
	image: string;
	title: string;
	content?: string;
	useBigImage?: boolean;
}

export const BannerMessage = (props: BannerMessageProps): React.JSX.Element => {
	return (
		<div className={styles.bannerMessage}>
			<img className={props.useBigImage ? styles.bigImage : undefined} src={props.image} alt={props.title}/>
			<div className={styles.title}>{props.title}</div>
			{ props.content && <div className={styles.content}>{props.content}</div> }
		</div>
	);
};

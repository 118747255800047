import styles from "./Footer.module.scss";
import iconWhite from "@fyendalscollection/app/assets/icon.svg";
import discord from "@fyendalscollection/app/assets/discord.svg";
import fb from "@fyendalscollection/app/assets/facebook-icon.svg";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

export const Footer = (): React.JSX.Element => {
	return (
		<div className={styles.footer}>
			<div></div>
			<div>
				<div className={styles.title}>
					<img src={iconWhite} />
					<div>Fyendal&apos;s Collection</div>
				</div>
				<div className={styles.small}>Copyright © {dayjs().year()} Fyendal&apos;s Collection</div>
				<div className={styles.verySmall}>
					Fyendal&apos;s Collection is not affiliated with Legend Story Studios®.  All assets and materials related to the Flesh &amp; Blood™ intellectual property belong to Legend Story Studios®.
				</div>
			</div>
			<div>
				<div className={styles.discord}>
					<Link to="https://www.facebook.com/profile.php?id=61553701346929" target="_blank" rel="noopener noreferrer">
						<img src={fb} />
					</Link>
					<Link to="https://discord.gg/RDYn8yDnFm" target="_blank" rel="noopener noreferrer">
						<img src={discord} />
					</Link>
					<div>
						<div>
							<Link className={styles.subtitle} to="https://discord.gg/RDYn8yDnFm" target="_blank" rel="noopener noreferrer">
								Looking to contact us?
							</Link>
							<Link to="https://discord.gg/RDYn8yDnFm" target="_blank" rel="noopener noreferrer">
								From bug reports to feature ideas, we want to hear from you! Join the Fyendal&apos;s Collection community on Discord!
							</Link>
							<div className={styles.email}>Or alternatively email us at <a href="mailto:support@fyendalscollection.com" className={styles.emailText}>support@fyendalscollection.com</a></div>
						</div>
					</div>
				</div>
			</div>
			<div></div>
		</div>
	);
};

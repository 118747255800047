import { PerformanceDeltaDetailsModel } from "./models/PerformanceDeltaDetailsModel";
import { PerformanceDeltaSet, PerformanceDeltaSort, PerformanceDeltaStep } from "../../domain";
import { HTTPAuthMode, PaginatedResponseModel, useHTTP, useSimpleQueryWithCursor } from "../../http";

export const usePerformanceDeltas = (step: PerformanceDeltaStep, minimumPrice: number, sort: PerformanceDeltaSort, sets: PerformanceDeltaSet[]) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQueryWithCursor(["performanceDeltas", step, minimumPrice, sort, sets], ({ pageParam = 0 }) => {
		return http<PaginatedResponseModel<PerformanceDeltaDetailsModel>>({
			method: "GET",
			url: `/performance-deltas?step=${step}&minimumPrice=${minimumPrice}&sort=${sort}${sets.length != 0 ? `&sets=${sets.join()}` : ""}&cursor=${pageParam}`
		});
	});
};

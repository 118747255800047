import { MutationFunction } from "@tanstack/react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface GetTcgPlayerMassEntryLinkResponseModel {
	tcgPlayerMassEntryLink: string;
}

export const useOperationTcgPlayerMassEntryLink = () => {
	const http = useHTTP(HTTPAuthMode.Required);

	const mutFunc: MutationFunction<Response<GetTcgPlayerMassEntryLinkResponseModel>, string> = collectionId => {
		return http<GetTcgPlayerMassEntryLinkResponseModel>({
			method: "GET",
			url: `/collection/${collectionId}/operation/tcg-player-mass-entry`
		});
	};

	return useSimpleMutation(mutFunc);
};

import { MutationFunction, useQueryClient } from "@tanstack/react-query";
import { PaymentCardModel } from "./models/PaymentCardModel";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export const usePaymentCardDelete = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response, string> = paymentCardId => {
		return http({
			method: "DELETE",
			url: `/payment-card/${paymentCardId}`
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: (_, paymentCardId) => {
			queryClient.setQueryData(["paymentCards"], (oldData: PaymentCardModel[] | undefined) => {
				if (!oldData) {
					return [];
				}
				return oldData.filter(x => x.paymentCardId !== paymentCardId);
			});
		}
	});
};

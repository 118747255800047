import { MutationFunction, useQueryClient } from "@tanstack/react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface SetPreventNotificationEmailsRequestModel {
	preventNotificationEmails: boolean;
}

export const useSetPreventNotificationEmails = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response, SetPreventNotificationEmailsRequestModel> = requestModel => {
		return http<undefined, SetPreventNotificationEmailsRequestModel>({
			method: "POST",
			url: "/settings/prevent-notification-emails",
			body: requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: (_, requestModel) => {
			queryClient.setQueryData(["preventNotificationEmails"], requestModel);
		}
	});
};

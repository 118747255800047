import { MutationFunction, useQueryClient } from "@tanstack/react-query";
import { EmblemResponseModel } from "./models";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface SetUserEmblemRequestModel {
	emblemId: string;
}

export const useSetUserEmblem = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response<EmblemResponseModel>, SetUserEmblemRequestModel> = requestModel => {
		return http<EmblemResponseModel, SetUserEmblemRequestModel>({
			method: "POST",
			url: "/emblem/active",
			body: requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: (data) => {
			queryClient.setQueryData(["emblem"], data.body);
		}
	});
};

import styles from "./LinkButton.module.scss";
import { Link } from "react-router-dom";

export interface LinkButtonProps {
	text: string;
	disabled?: boolean;
	onClick?: () => void;
	link?: string;
	newTab?: boolean;
}

export const LinkButton = ({ text, disabled, onClick, link, newTab }: LinkButtonProps): React.JSX.Element => {
	const linkButtonStyles = [ styles.linkButton ];
	if (disabled || (!onClick && !link)) {
		linkButtonStyles.push(styles.disabled);
	}

	const enrichedOnClick = () => {
		if (disabled || !onClick) {
			return;
		}

		onClick();
	};

	const button = <div className={linkButtonStyles.join(" ")} onClick={enrichedOnClick}>{text}</div>;

	if (link) {
		return <Link rel={newTab ? "noreferrer" : undefined} target={newTab ? "_blank" : undefined} to={link}>{button}</Link>;
	}

	return button;
};

import styles from "./SelectorCheckbox.module.scss";

export interface SelectorCheckboxProps {
	onChange: (value: boolean) => void;
	isChecked: boolean;
	label: string;
	disabled?: boolean;
}

export const SelectorCheckbox = (props: SelectorCheckboxProps): React.JSX.Element => {
	const handleOnClick = () => {
		if (props.disabled) {
			return;
		}
		props.onChange(!props.isChecked);
	};

	const classNames = [styles.checkbox];
	if (props.disabled) {
		classNames.push(styles.disabled);
	}

	const classNamesBox = [styles.box];
	if (props.isChecked) {
		classNamesBox.push(styles.checked);
	}

	return (
		<div className={classNames.join(" ")} onClick={handleOnClick}>
			<div className={classNamesBox.join(" ")}>
				&#x2713;
			</div>
			<div className={styles.label}>
				{props.label}
			</div>
		</div>
	);
};

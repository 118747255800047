import { NotificationsList } from "@fyendalscollection/app/features/notifications/components/NotificationsList";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";

export const PageNotifications = (): React.JSX.Element => {
	useTitle("Notifications");

	return (
		<Limit force={LimitWidth.Large}>
			<Breadcrumb crumbs={[
				{
					text: "Dashboard",
					link: "/dashboard"
				},
				{
					text: "Notifications"
				}
			]} />

			<PageTitle
				title="Notifications"
				subTitle="Notifications List" />

			<NotificationsList notificationsLimit={false} />
		</Limit>
	);
};

import { ModalAddPaymentCard } from "@fyendalscollection/app/features/paymentCards/modals/ModalAddPaymentCard";
import { Container } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { Paragraph } from "@fyendalscollection/app/lib/components/Paragraph";
import { PaymentCardSelector } from "@fyendalscollection/app/lib/components/PaymentCardSelector";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";
import { ProblemResponse } from "@fyendalscollection/app/shared";
import { useState } from "react";

export interface ModalSelectPaymentCardProps {
	visible: boolean;
	onCancel: () => void;
	error: ProblemResponse | null;
	isLoading: boolean;
	onPaymentCardSelected: (paymentCardId: string) => void;
}

export const ModalSelectPaymentCard = (props: ModalSelectPaymentCardProps): React.JSX.Element => {
	const {
		visible,
		onCancel,
		error,
		isLoading,
		onPaymentCardSelected
	} = props;

	const [selectedPaymentCard, setSelectedPaymentCard] = useState<string | null>(null);
	const [addPaymentCardModalVisible, setAddPaymentCardModalVisible] = useState(false);

	const onConfirm = () => {
		if (!selectedPaymentCard) {
			return;
		}

		onPaymentCardSelected(selectedPaymentCard);
	};

	return (
		<>
			<Modal visible={visible && !addPaymentCardModalVisible} onCancel={onCancel}>
				<TitleSecondary text="Select Payment Card" />

				<Paragraph>
					Please select an existing payment card or add a new one.
				</Paragraph>

				<Loadable loading={isLoading}>
					<PaymentCardSelector value={selectedPaymentCard} onChange={setSelectedPaymentCard} onAddPaymentCard={() => setAddPaymentCardModalVisible(true)} />

					{
						error &&
						<Container transparent>
							<ErrorDisplay problemResponse={error} />
						</Container>
					}

					<GreenButtonSet buttons={[
						{
							text: "Confirm",
							onClick: onConfirm,
							disabled: isLoading || !selectedPaymentCard
						}
					]} />
				</Loadable>
			</Modal>

			<ModalAddPaymentCard visible={addPaymentCardModalVisible} onCancel={() => setAddPaymentCardModalVisible(false)} />
		</>
	);
};

import { InfiniteData, Updater } from "@tanstack/react-query";
import { PaginatedResponseModel } from "./PaginatedResponseModel";

export const paginatedPrepend = <T>(x: T): Updater<InfiniteData<PaginatedResponseModel<T>> | undefined, InfiniteData<PaginatedResponseModel<T>>> => {
	return (oldData: InfiniteData<PaginatedResponseModel<T>> | undefined): InfiniteData<PaginatedResponseModel<T>> => {
		if (!oldData || oldData.pages.length < 1) {
			return {
				pages: [{
					results: [ x ],
					nextCursor: 1
				}],
				pageParams: [ 0 ]
			};
		}

		const newFirstPage = {
			results: [ x, ...oldData.pages[0].results ],
			nextCursor: oldData.pages[0].nextCursor
		};

		return {
			pages: [ newFirstPage, ...oldData.pages.slice(1)],
			pageParams: oldData.pageParams
		};
	};
};

import { EvSetType, Money } from "../../domain";
import { HTTPAuthMode, PaginatedResponseModel, useHTTP, useSimpleQueryWithCursor } from "../../http";

export interface EvBreakdownCategoryVariantsResponseModel {
	readonly variantId: string;
	readonly productId: string;
	readonly setType: EvSetType;
	readonly category: string;
	readonly marketPrice: Money;
	readonly productName: string;
	readonly fcId: string;
	readonly description: string;
	readonly imageUrl: string;
	readonly shortPrintDescription: string;
}

export const useEvBreakdownCategoryVariants = (setType: EvSetType, category: string) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQueryWithCursor(["evBreakdownCategoryVariants", setType, category], ({ pageParam = 0 }) => {
		return http<PaginatedResponseModel<EvBreakdownCategoryVariantsResponseModel>>({
			method: "GET",
			url: `/ev/breakdown/variants?setType=${setType}&category=${category}&cursor=${pageParam}`
		});
	});
};

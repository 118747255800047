import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import styles from "./PublicCollectionTopBar.module.scss";
import { Money } from "@fyendalscollection/app/shared";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";

export interface PublicCollectionTopBarProps {
	totalQuantity: number;
	totalMarketValue: Money;
}

export const PublicCollectionTopBar = (props: PublicCollectionTopBarProps) => {
	return (
		<Container>
			<ContainerSegment>
				<div className={styles.publicCollectionTopBar}>
					<div className={styles.grid}>
						<div>
							<div className={styles.title}>Total Quantity</div>
							<div className={styles.valueText}>{props.totalQuantity}</div>
						</div>
						<div>
							<div className={styles.title}>Total Market Value</div>
							<PrettyMoney className={styles.valueText} money={props.totalMarketValue} mode={MoneyDisplayMode.Standard} />
						</div>
					</div>
				</div>
			</ContainerSegment>
		</Container>
	);
};

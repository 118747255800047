import { MutationFunction, useQueryClient } from "@tanstack/react-query";
import { clearCacheAfterTransactionMutation } from "./clearCacheAfterTransactionMutation";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";
import { Currency, PriceSource } from "../../domain";

export interface BulkTransactionsOptions {
	readonly collectionId: string;
	readonly requestModel: BulkTransactionsRequestModel;
}

export interface BulkTransactionsRequestModel {
	readonly currency: Currency;
	readonly priceSource: PriceSource;
	readonly itemsLeaving: BulkTransactionsRequestModelVariantQuantityPair[];
	readonly itemsEntering: BulkTransactionsRequestModelVariantQuantityPair[];
	readonly cashLeaving: number;
	readonly cashEntering: number;
	readonly occurredDate: string;
}

export interface BulkTransactionsRequestModelVariantQuantityPair {
	readonly variantId: string;
	readonly quantity: number;
}

export const useBulkTransactions = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response<undefined>, BulkTransactionsOptions> = options => {
		return http<undefined, BulkTransactionsRequestModel>({
			method: "POST",
			url: "/collection/" + options.collectionId + "/operation/bulk-transactions",
			body: options.requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: (_, requestProperties) => {
			clearCacheAfterTransactionMutation(queryClient, requestProperties.collectionId);
			queryClient.invalidateQueries({ queryKey: ["transactions", requestProperties.collectionId] });
		}
	});
};

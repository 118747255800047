import { MutationFunction, useQueryClient } from "@tanstack/react-query";
import { CollectionModel } from "./models/CollectionModel";
import { HTTPAuthMode, Response, useHTTP } from "../../http/useHTTP";
import { paginatedRemove, useSimpleMutation } from "../../http";
import { CollectionsSortOrder } from "../../domain";

export const useCollectionDelete = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response, string> = collectionId => {
		return http({
			method: "DELETE",
			url: `/collection/${collectionId}`
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: (_, collectionId) => {
			queryClient.setQueryData(["collections", CollectionsSortOrder.Alphabetical], paginatedRemove<CollectionModel>(x => x.collectionId === collectionId));
			queryClient.setQueryData(["collections", CollectionsSortOrder.Chronological], paginatedRemove<CollectionModel>(x => x.collectionId === collectionId));
			queryClient.setQueryData(["collections", CollectionsSortOrder.Value], paginatedRemove<CollectionModel>(x => x.collectionId === collectionId));
			queryClient.setQueryData(["collections", CollectionsSortOrder.PercentageGain], paginatedRemove<CollectionModel>(x => x.collectionId === collectionId));
			queryClient.invalidateQueries({ queryKey: ["dashboardCurrentPosition"] });
			queryClient.invalidateQueries({ queryKey: ["dashboardHistoricalPositions"] });
		}
	});
};

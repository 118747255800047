import { ProblemResponse } from "@fyendalscollection/app/shared";
import styles from "./Error.module.scss";
import errorBubble from "@fyendalscollection/app/assets/error-bubble.svg";

export type ErrorDisplayProps = {
	message: string | undefined;
} | {
	problemResponse: ProblemResponse | undefined;
}

export const ErrorDisplay = (props: ErrorDisplayProps): React.JSX.Element => {
	const text = "message" in props
		? props.message
		: props.problemResponse?.problem?.description;

	return (
		<>
			{
				text &&
				<div className={styles.error}>
					<img src={errorBubble} />
					{text}
				</div>
			}
		</>
	);
};

import { MutationFunction, useQueryClient } from "@tanstack/react-query";
import { SubscriptionModel } from "./models/SubscriptionModel";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface SubscriptionDeleteRequestModel {
	cancellationReason: string;
}

export const useSubscriptionDelete = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response, SubscriptionDeleteRequestModel> = requestModel => {
		return http({
			method: "POST",
			url: "/subscription/delete",
			body: requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: () => {
			queryClient.setQueryData<SubscriptionModel | undefined>(["subscription"], (oldData: SubscriptionModel | undefined) => {
				if (!oldData) {
					return undefined;
				}

				const newData: SubscriptionModel = {
					...oldData,
					autoRenewPaymentCardId: null,
					autoRenewPlanId: null,
					autoRenewPlanName: null
				};

				return newData;
			});
		}
	});
};

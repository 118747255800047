import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import styles from "./ModalCollectionImage.module.scss";
import { collectionArts } from "@fyendalscollection/app/features/collections/CollectionArts";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";
import { Paragraph } from "@fyendalscollection/app/lib/components/Paragraph";
import { Container } from "@fyendalscollection/app/lib/components/Container";

export interface ModalCollectionImageProps {
	visible: boolean;
	onCancel: () => void;
	onImageSelect: (index: number) => void;
}

export const ModalCollectionImage = (props: ModalCollectionImageProps): React.JSX.Element => {

	return (
		<Modal visible={props.visible} onCancel={props.onCancel}>
			<TitleSecondary text="Change Image" />

			<Paragraph>
				Please choose an image you would like to use for your collection
			</Paragraph>

			<Container>
				<div className={styles.collectionImageContainer}>
					{
						collectionArts.map((x, i) => (

							<div onClick={() => props.onImageSelect(i)} style={{ backgroundImage: "url('" + x + "')" }} key={x}></div>
						))
					}
				</div>
			</Container>
		</Modal>
	);
};

import { API_BASE_URL } from "@fyendalscollection/app/lib/config";
import { HTTPEnvironment, useAuthState } from "@fyendalscollection/app/shared";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export const useAppHTTPEnvironment = (): HTTPEnvironment => {
	const { state, dispatchAuthLoggedIn } = useAuthState();
	const navigate = useNavigate();

	return useMemo(() => {
		return {
			getAccessToken: () => state.accessToken || null,
			setAccessToken: dispatchAuthLoggedIn,
			enableMaintenanceMode: () => navigate("/maintenance"),
			getApiBaseUrl: () => API_BASE_URL
		};
	}, [state.accessToken, navigate]);
};

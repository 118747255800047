import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";

export interface GetPreventNotificationEmailsResponseModel {
	preventNotificationEmails: boolean;
}

export const usePreventNotificationEmails = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["preventNotificationEmails"], () => {
		return http<GetPreventNotificationEmailsResponseModel>({
			method: "GET",
			url: "/settings/prevent-notification-emails"
		});
	});
};

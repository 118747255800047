import styles from "./Paragraph.module.scss";

export interface ParagraphProps {
	children: React.ReactNode;
}

export const Paragraph = ({ children }: ParagraphProps): React.JSX.Element => {
	return (
		<div className={styles.paragraph}>{children}</div>
	);
};

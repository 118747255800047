import { Link } from "react-router-dom";
import styles from "./Breadcrumb.module.scss";

export interface Crumb {
	text: string;
	link?: string;
}

export interface BreadcrumbProps {
	crumbs: Crumb[];
}

export const Breadcrumb = ({ crumbs }: BreadcrumbProps): React.JSX.Element => {
	const breadcrumbs = crumbs.map((crumb, i) => {
		let result = <>{crumb.text}</>;
		if (crumb.link) {
			result = <Link to={crumb.link} className={styles.link}>{result}</Link>;
		}

		if (i !== crumbs.length - 1) {
			result = <>{result}<div className={styles.arrow}>→</div></>;
		}

		return <div key={i}>{result}</div>;
	});

	return (
		<div className={styles.breadcrumbs}>
			{breadcrumbs}
		</div>
	);
};

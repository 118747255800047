import { MutationFunction } from "@tanstack/react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface PushAppLoadRequestModel {
	readonly attribution?: string;
}

export const usePushAppLoad = () => {
	const http = useHTTP(HTTPAuthMode.Required);

	const mutFunc: MutationFunction<Response, PushAppLoadRequestModel> = requestModel => {
		return http<undefined, PushAppLoadRequestModel>({
			method: "POST",
			url: "/analytics/app-load",
			body: requestModel
		});
	};

	return useSimpleMutation(mutFunc);
};

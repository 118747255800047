import { MutationFunction, useQueryClient } from "@tanstack/react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface OperationNormaliseQuantityRequestModel {
	normalisedQuantity: number;
}

export interface OperationNormaliseQuantityProps {
	requestModel: OperationNormaliseQuantityRequestModel;
	collectionId: string;
}

export const useOperationNormaliseQuantity = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response, OperationNormaliseQuantityProps> = (requestProperties: OperationNormaliseQuantityProps) => {
		return http<undefined, OperationNormaliseQuantityRequestModel>({
			method: "POST",
			url: "/collection/" + requestProperties.collectionId + "/operation/normalise-quantity",
			body: requestProperties.requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: (_, requestProperties) => {
			queryClient.invalidateQueries({ queryKey: ["collections"] });
			queryClient.invalidateQueries({ queryKey: ["collection", requestProperties.collectionId] });
			queryClient.invalidateQueries({ queryKey: ["collectionVariantDetails", requestProperties.collectionId] });
			queryClient.invalidateQueries({ queryKey: ["dashboardCurrentPosition"] });
			queryClient.invalidateQueries({ queryKey: ["dashboardHistoricalPositions"] });
			queryClient.invalidateQueries({ queryKey: ["transactionSummaries"] });
			queryClient.invalidateQueries({ queryKey: ["transactions", requestProperties.collectionId] });
		}
	});
};

import { useEffect, useState } from "react";
import { EditCollectionImage } from "./EditCollectionImage";
import { collectionArts } from "@fyendalscollection/app/features/collections/CollectionArts";
import { ModalCollectionImage } from "@fyendalscollection/app/features/collections/components/ModalCollectionImage";
import { CollectionModel, useCollectionDelete, useCollectionUpdate } from "@fyendalscollection/app/shared";
import { useFocus } from "@fyendalscollection/app/lib/useFocus";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";
import { Paragraph } from "@fyendalscollection/app/lib/components/Paragraph";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { TextEntry } from "@fyendalscollection/app/lib/components/TextEntry";
import { Checkbox } from "@fyendalscollection/app/lib/components/Checkbox";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { ModalConfirmation } from "@fyendalscollection/app/lib/components/ModalConfirmation";

export interface ModalUpdateCollectionProps {
	onCancel: () => void;
	collection: CollectionModel | null;
}

export const ModalUpdateCollection = (props: ModalUpdateCollectionProps): React.JSX.Element => {
	const [ref, focus] = useFocus();

	const [collectionNameText, setCollectionNameText] = useState("");
	const [collectionDescriptionText, setCollectionDescriptionText] = useState("");
	const [isValued, setIsValued] = useState(false);
	const [image, setImage] = useState(0);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);
	const [collectionImageModalVisibility, setCollectionImageModalVisibility] = useState(false);

	const updateCollection = useCollectionUpdate();
	const deleteCollection = useCollectionDelete();

	// Focus the value TextEntry when we land on the page.
	useEffect(() => {
		if (props.collection) {
			focus();
			setCollectionNameText(props.collection.name || "");
			setCollectionDescriptionText(props.collection.description || "");
			setIsValued(props.collection.valued);
			setImage(props.collection.image);
			deleteCollection.reset();
		}
	}, [props.collection]);

	useEffect(() => {
		if (updateCollection.data) {
			props.onCancel();
		}
	}, [updateCollection.data]);

	useEffect(() => {
		if (deleteCollection.isSuccess) {
			props.onCancel();
		}
	}, [deleteCollection.isSuccess]);

	const confirmButtonDisabled = !props.collection || (
		collectionNameText === props.collection.name &&
		collectionDescriptionText === props.collection.description &&
		isValued === props.collection.valued &&
		collectionArts[image] === collectionArts[props.collection.image]
	);

	const performCollectionUpdate = () => {
		if (!props.collection) {
			return;
		}
		updateCollection.mutate(
			{
				requestModel: {
					name: collectionNameText,
					valued: isValued,
					description: collectionDescriptionText,
					pinned: props.collection.pinned,
					image: image
				},
				id: props.collection.collectionId
			}
		);
	};

	const onImageSelect = (index: number) => {
		setImage(index);
		setCollectionImageModalVisibility(false);
	};

	return (
		<>
			<Modal visible={!!props.collection} onCancel={props.onCancel}>
				<Loadable loading={updateCollection.isPending || deleteCollection.isPending}>
					<TitleSecondary text="Edit Collection" />

					<Paragraph>
						Edit the name and description of your collection.
					</Paragraph>

					{
						deleteCollection.error &&
						<Container>
							<ContainerSegment>
								<ErrorDisplay problemResponse={deleteCollection.error} />
							</ContainerSegment>
						</Container>
					}

					<Container>
						<ContainerSegment>
							<TextEntry
								ref={ref}
								placeholder="Collection Name"
								value={collectionNameText}
								onChange={setCollectionNameText}
								onEnter={performCollectionUpdate} />
						</ContainerSegment>

						<ContainerSegment>
							<TextEntry
								placeholder="Collection Description"
								value={collectionDescriptionText}
								onChange={setCollectionDescriptionText}
								onEnter={performCollectionUpdate} />
						</ContainerSegment>

						<ContainerSegment>
							<Checkbox
								onChange={setIsValued}
								isChecked={isValued}
								label="Include the value of this collection in the dashboard summary" />
						</ContainerSegment>

						<EditCollectionImage onClick={() => setCollectionImageModalVisibility(true)} image={collectionArts[image]} />
					</Container>

					<GreenButtonSet buttons={[
						{
							text: "Save Changes",
							disabled: confirmButtonDisabled,
							onClick: performCollectionUpdate
						},
						{
							text: "Delete",
							onClick: () => setDeleteModalVisible(true)
						}
					]} />
				</Loadable>
			</Modal>

			<ModalCollectionImage visible={collectionImageModalVisibility} onCancel={() => setCollectionImageModalVisibility(false)} onImageSelect={onImageSelect} />

			{
				props.collection &&
				<ModalConfirmation
					title="Delete Collection"
					description={`Are you sure you want to delete your "${props.collection.name}" collection?  All transactions in this collection will be deleted permanently.`}
					visible={deleteModalVisible}
					onCancel={() => setDeleteModalVisible(false)}
					onConfirm={() => {
						if (!props.collection) {
							return;
						}

						deleteCollection.mutate(props.collection.collectionId);
					}} />
			}
		</>
	);
};

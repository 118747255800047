import { useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { NotificationDisplay } from "@fyendalscollection/app/features/notifications/components/NotificationDisplay";
import { useNotification } from "@fyendalscollection/app/shared";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";

export const PageNotification = (): React.JSX.Element => {
	const params = useParams();
	const notificationId = params["notificationId"] as string;
	const notification = useNotification(notificationId);
	const queryClient = useQueryClient();

	const notificationSubject = notification.data ? notification.data.subject : "Notification";

	useEffect(() => {
		if (!notification.isSuccess) {
			return;
		}

		queryClient.invalidateQueries({ queryKey: ["notifications"] });
		queryClient.invalidateQueries({ queryKey: ["notificationUnreadCount"] });
	}, [notification.isSuccess]);

	return (
		<Limit force={LimitWidth.Large}>
			<Breadcrumb crumbs={[
				{
					text: "Dashboard",
					link: "/dashboard"
				},
				{
					text: "Notifications",
					link: "/notifications"
				},
				{
					text: notificationSubject
				}
			]} />

			<PageTitle
				title={notificationSubject}
				subTitle="Notification" />

			{
				notification.isError &&
				<Container>
					<ContainerSegment>
						<ErrorDisplay problemResponse={notification.error}/>
					</ContainerSegment>
				</Container>
			}

			<Loadable loading={notification.isLoading}>
				<Container>
					<ContainerSegment>
						{
							notification.data &&
							<NotificationDisplay
								notification={notification.data}
								showReadStatus={true} />
						}
					</ContainerSegment>
				</Container>
			</Loadable>
		</Limit>
	);
};

import React from "react";
import styles from "./ContainerListItemRadioButton.module.scss";

export type ContainerListItemRadioButtonProps = {
	image?: string;
	selected: boolean;
	onClick?: () => void;
} & ({
	title: string;
	subtitle?: React.ReactNode;
} | {
	title?: string;
	subtitle: React.ReactNode;
});

export const ContainerListItemRadioButton = ({ image, selected, onClick, title, subtitle }: ContainerListItemRadioButtonProps): React.JSX.Element => {
	return (
		<div className={styles.containerListItemRadioButton} onClick={onClick}>
			{image && <img src={image}/> }
			<div className={styles.text}>
				{title && <div className={styles.title}>{title}</div>}
				{subtitle && <div className={styles.subtitle}>{subtitle}</div>}
			</div>
			<div className={selected ? styles.selected : undefined}>
				<div></div>
			</div>
		</div>
	);
};

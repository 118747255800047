import styles from "./DaySelector.module.scss";
import { useUserPreferencesState } from "@fyendalscollection/app/shared";

export interface DaySelectorProps {
	children?: React.ReactNode;
}

export const DaySelector = ({ children }: DaySelectorProps): React.JSX.Element => {

	const {
		state,
		dispatchSetGraphDays
	} = useUserPreferencesState();

	return (
		<div className={styles.daySelector}>
			<div onClick={() => dispatchSetGraphDays(7)} className={state.graphDays == 7 ? styles.selected : undefined}>
				1W
			</div>
			<div onClick={() => dispatchSetGraphDays(14)} className={state.graphDays == 14 ? styles.selected : undefined}>
				2W
			</div>
			<div onClick={() => dispatchSetGraphDays(31)} className={state.graphDays == 31 ? styles.selected : undefined}>
				1M
			</div>
			<div onClick={() => dispatchSetGraphDays(92)} className={state.graphDays == 92 ? styles.selected : undefined}>
				3M
			</div>
			<div onClick={() => dispatchSetGraphDays(183)} className={state.graphDays == 183 ? styles.selected : undefined}>
				6M
			</div>
			<div onClick={() => dispatchSetGraphDays(365)} className={state.graphDays == 365 ? styles.selected : undefined}>
				1Y
			</div>
			<div onClick={() => dispatchSetGraphDays(730)} className={state.graphDays == 730 ? styles.selected : undefined}>
				2Y
			</div>
			{children && <span>{children}</span>}
		</div>
	);
};

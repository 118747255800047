import { MutationFunction, useQueryClient } from "@tanstack/react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";
import { SubscriptionModel } from "@fyendalscollection/app/shared/api/subscriptions/models";

export interface ChangePaymentCardRequestModel {
	paymentCardId: string;
}

export const useChangePaymentCard = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response, ChangePaymentCardRequestModel> = requestModel => {
		return http<undefined, ChangePaymentCardRequestModel>({
			method: "POST",
			url: "/subscription/change-payment-card",
			body: requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: (_, requestModel) => {
			const subscription = queryClient.getQueryData<SubscriptionModel>(["subscription"]);
			if (!subscription) {
				return;
			}

			const updatedSubscription: SubscriptionModel = {
				...subscription,
				autoRenewPaymentCardId: requestModel.paymentCardId
			};

			queryClient.setQueryData(["subscription"], updatedSubscription);
		}
	});
};

import { MutationFunction } from "@tanstack/react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface ImportPreflightRequestOptions {
	collectionId: string | undefined;
	requestModel: ImportPreflightRequestModel;
}

export interface ImportPreflightRequestModel {
	readonly headers: string;
}

export interface ImportPreflightResponseModel {
	readonly csvImportToken: string;
}

export const useImportPreflight = () => {
	const http = useHTTP(HTTPAuthMode.Required);

	const mutFunc: MutationFunction<Response<ImportPreflightResponseModel>, ImportPreflightRequestOptions> = options => {
		return http<ImportPreflightResponseModel, ImportPreflightRequestModel>({
			method: "POST",
			url: options.collectionId ? `/collection/${options.collectionId}/import` : "/import",
			body: options.requestModel
		});
	};

	return useSimpleMutation(mutFunc);
};

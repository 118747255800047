import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { InfiniteScroller } from "@fyendalscollection/app/lib/components/InfiniteScroller";
import { ScreenSize } from "@fyendalscollection/app/lib/components/ScreenSize";
import { ScreenSizeView } from "@fyendalscollection/app/lib/ScreenSizeContext";
import { ModalShareableLink } from "@fyendalscollection/app/features/collections/components/ModalShareableLink";
import { useDebouncedState } from "@fyendalscollection/app/lib/useDebouncedState";
import { CollectionProductSearch } from "@fyendalscollection/app/features/collections/components/CollectionProductSearch";
import { TransactionSummariesSortOrder, TransactionSummariesView, useCollection, useTransactionSummaries, useUserPreferencesState } from "@fyendalscollection/app/shared";
import { useSubscription } from "@fyendalscollection/app/shared";
import { GreenButton, GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { CallToAction } from "@fyendalscollection/app/lib/components/CallToAction";
import { CollectionGalleryView } from "@fyendalscollection/app/features/collections/components/CollectionGalleryView";
import { CollectionListView } from "@fyendalscollection/app/features/collections/components/CollectionListView";
import { CollectionGraphView } from "@fyendalscollection/app/features/collections/components/CollectionGraphView";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { CollectionTab, CollectionTabSelector } from "@fyendalscollection/app/features/collections/components/CollectionTabSelector";
import { Checkbox } from "@fyendalscollection/app/lib/components/Checkbox";
import { StyledSelect } from "@fyendalscollection/app/lib/components/StyledSelect";
import { TopBar } from "@fyendalscollection/app/features/collections/components/TopBar";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { SearchSyntaxModal } from "@fyendalscollection/app/features/products/components/SearchSyntaxModal";

export const PageCollectionView = (): React.JSX.Element => {
	const {
		state,
		dispatchSetTransactionSummariesSortOrder,
		dispatchSetTransactionSummariesView,
		dispatchSetShowSoldItems
	} = useUserPreferencesState();

	const params = useParams();
	const collectionId = params.collectionId as string;
	const navigate = useNavigate();

	const [modalVisible, setModalVisible] = useState(false);
	const [showSyntaxModal, setShowSyntaxModal] = useState(false);
	const [debouncedSearchQuery, searchQuery, setSearchQuery] = useDebouncedState("", 500);

	const collection = useCollection(collectionId);
	const transactionSummaries = useTransactionSummaries(collectionId, state.transactionSummariesSortOrder, debouncedSearchQuery);
	const subscription = useSubscription();

	const buttons: GreenButton[] = [
		{
			text: "Export",
			link: `/collections/${collectionId}/csv-export`
		}
	];

	if (subscription.data?.activeSubscription) {
		buttons.push(
			{
				text: "Share",
				onClick: () => setModalVisible(true)
			}
		);
	}

	useTitle(collection.data ? collection.data.name : "Collection");

	let totalSummaries = transactionSummaries.data
		? transactionSummaries.data.pages.flatMap(x => x.results)
		: [];

	if(!state.showSoldItems) {
		totalSummaries = totalSummaries.filter(x => x.quantity > 0);
	}

	const callToAction = (
		<CallToAction
			title="There isn't anything here yet!"
			subtitle="Record your first transaction for this collection!"
			buttonText="Create Transaction"
			onClick={() => navigate("transactions")} />
	);

	const noSearchResults = (
		<CallToAction
			title="No search results found!"
			subtitle="Try a different search term or click the help icon in the search bar for our filters"
		/>
	);

	const display = () => {
		if(totalSummaries.length === 0 && !transactionSummaries.isLoading && !transactionSummaries.error) {
			if (searchQuery.length > 0)
				return noSearchResults;

			return callToAction;
		}

		switch (state.transactionSummariesView) {
		case TransactionSummariesView.Gallery:
			return <CollectionGalleryView collectionId={collectionId} scrollerElement={<InfiniteScroller forQuery={transactionSummaries} />} transactionSummaries={totalSummaries}/>;
		case TransactionSummariesView.List:
			return <CollectionListView collectionId={collectionId} scrollerElement={<InfiniteScroller forQuery={transactionSummaries} />} transactionSummaries={totalSummaries}/>;
		case TransactionSummariesView.Graph:
			return <CollectionGraphView />;
		}
	};

	const displayMobile = () => {
		if (totalSummaries.length === 0) {
			if (searchQuery.length > 0)
				return noSearchResults;
			return callToAction;
		}

		switch (state.transactionSummariesView) {
		case TransactionSummariesView.Gallery:
		case TransactionSummariesView.List:
			return <CollectionGalleryView collectionId={collectionId} scrollerElement={<InfiniteScroller forQuery={transactionSummaries} />} transactionSummaries={totalSummaries}/>;
		case TransactionSummariesView.Graph:
			return <CollectionGraphView/>;
		}
	};

	return (
		<>
			<Limit force={LimitWidth.Massive}>
				<Loadable loading={collection.isLoading}>
					{!collection.data ?
						<></> :
						<>
							<Breadcrumb crumbs={[
								{
									text: "Dashboard",
									link: "/dashboard"
								},
								{
									text: "Collections",
									link: "/collections"
								},
								{
									text: collection.data.name
								}
							]}/>

							<PageTitle
								title={collection.data.name}
								subTitle={collection.data.description}
								primaryActions={<CollectionTabSelector selected={CollectionTab.Collection} />}
								secondaryActions={
									<>
										<Checkbox
											label="Show sold items"
											onChange={dispatchSetShowSoldItems}
											isChecked={state.showSoldItems} />

										<ScreenSize screenSizeView={ScreenSizeView.DesktopAndTablet}>
											<StyledSelect value={state.transactionSummariesView} onChange={dispatchSetTransactionSummariesView} options={[
												{ text: "View: Gallery", value: TransactionSummariesView.Gallery },
												{ text: "View: List", value: TransactionSummariesView.List },
												{ text: "View: Graph", value: TransactionSummariesView.Graph }
											]} />
										</ScreenSize>

										<ScreenSize screenSizeView={ScreenSizeView.Mobile}>
											<StyledSelect value={state.transactionSummariesView} onChange={dispatchSetTransactionSummariesView} options={[
												{ text: "View: Gallery", value: TransactionSummariesView.Gallery },
												{ text: "View: Graph", value: TransactionSummariesView.Graph }
											]} />
										</ScreenSize>

										<StyledSelect disabled={state.transactionSummariesView === TransactionSummariesView.Graph || searchQuery.length > 0} value={state.transactionSummariesSortOrder} onChange={dispatchSetTransactionSummariesSortOrder} options={[
											{ text: "Sort: Alphabetical", value: TransactionSummariesSortOrder.Alphabetical },
											{ text: "Sort: Total Value", value: TransactionSummariesSortOrder.MarketValue },
											{ text: "Sort: Unit Value", value: TransactionSummariesSortOrder.UnitValue },
											{ text: "Sort: Flat Gain", value: TransactionSummariesSortOrder.Gain },
											{ text: "Sort: Percentage Gain", value: TransactionSummariesSortOrder.PercentageGain },
											{ text: "Sort: Quantity", value: TransactionSummariesSortOrder.Quantity },
											{ text: "Sort: Card Number", value: TransactionSummariesSortOrder.CardNumber }
										]} />

										<GreenButtonSet buttons={buttons} />
									</>
								} />

							<TopBar
								quantity={collection.data.quantity}
								marketValue={collection.data.marketValue}
								invested={collection.data.invested}
								gain={collection.data.gain}
								profit={collection.data.profit}
								cashPosition={collection.data.cashPosition} />

							{
								state.transactionSummariesView !== TransactionSummariesView.Graph &&
								collection.data.quantity != 0 &&
								<CollectionProductSearch
									value={searchQuery}
									onChange={setSearchQuery}
									helpOnClick={() => setShowSyntaxModal(true)} />
							}

							{
								transactionSummaries.isError &&
									<div>
										<ErrorDisplay problemResponse={transactionSummaries.error} />
									</div>
							}

							<SearchSyntaxModal showModal={showSyntaxModal} closeModal={() => setShowSyntaxModal(false)} />

							<Loadable loading={transactionSummaries.isLoading}>
								<ScreenSize screenSizeView={ScreenSizeView.DesktopAndTablet}>
									{display()}
								</ScreenSize>
								<ScreenSize screenSizeView={ScreenSizeView.Mobile}>
									{displayMobile()}
								</ScreenSize>
							</Loadable>
						</>
					}

					<ModalShareableLink visible={modalVisible} onCancel={() => setModalVisible(false)} collectionId={collectionId} />
				</Loadable>
			</Limit>
		</>
	);
};

import { PerformanceDeltaSort, PerformanceDeltaStep } from "../../domain";
import { PaginatedResponseModel, useSimpleQueryWithCursor } from "../../http";
import { HTTPAuthMode, useHTTP } from "../../http/useHTTP";
import { CollectionPerformanceDeltaDetailsModel } from "./models";

export const useCollectionPerformanceDeltas = (collectionId: string, step: PerformanceDeltaStep, minimumPrice: number, sort: PerformanceDeltaSort) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQueryWithCursor(["collection", collectionId, "performanceDeltas", step, minimumPrice, sort], ({ pageParam = 0 }) => {
		return http<PaginatedResponseModel<CollectionPerformanceDeltaDetailsModel>>({
			method: "GET",
			url: `/performance-deltas/collection/${collectionId}?step=${step}&minimumPrice=${minimumPrice}&sort=${sort}&cursor=${pageParam}`
		});
	});
};

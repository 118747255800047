import { MutationFunction, useQueryClient } from "@tanstack/react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface DuplicateCollectionResponseModel {
	collectionId: string;
}

export const useOperationDuplicate = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response<DuplicateCollectionResponseModel>, string> = collectionId => {
		return http<DuplicateCollectionResponseModel, undefined>({
			method: "POST",
			url: `/collection/${collectionId}/operation/duplicate`,
			body: undefined
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["collections"] });
		}
	});
};

import dayjs from "dayjs";
import plus from "@fyendalscollection/app/assets/plus.svg";
import { usePaymentCards } from "@fyendalscollection/app/shared";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ContainerListItemRadioButton } from "@fyendalscollection/app/lib/components/ContainerListItemRadioButton";
import { ContainerListItem, ImageSize } from "@fyendalscollection/app/lib/components/ContainerListItem";
import { useEffect } from "react";
import { getPaymentCardProviderImage } from "@fyendalscollection/app/lib/getPaymentCardProviderImage";

export interface PaymentCardSelectorProps {
	value: string | null;
	onChange: (value: string | null) => void;
	onAddPaymentCard: () => void;
}

export const PaymentCardSelector = ({ value, onChange, onAddPaymentCard }: PaymentCardSelectorProps): React.JSX.Element => {
	const cards = usePaymentCards();

	useEffect(() => {
		if (!cards.data || cards.data.length === 0 || cards.data.find(x => x.paymentCardId === value)) {
			return;
		}

		onChange(cards.data[0].paymentCardId);
	}, [cards.data]);

	return (
		<Container>
			{(cards.data || []).map(card => (
				<ContainerSegment key={card.paymentCardId} onClick={() => onChange(card.paymentCardId)}>
					<ContainerListItemRadioButton
						image={getPaymentCardProviderImage(card.provider)}
						selected={value === card.paymentCardId}
						title={`Card ending in ${card.last4}`}
						subtitle={card.expiry.valueOf() === 0 ? "●●/●●" : dayjs(card.expiry).format("MM/YY")} />
				</ContainerSegment>
			))}

			<ContainerSegment onClick={onAddPaymentCard}>
				<ContainerListItem
					title="Add Payment Card"
					image={plus}
					imageSize={ImageSize.Small} />
			</ContainerSegment>
		</Container>
	);
};


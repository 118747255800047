import { InfiniteData, QueryFunction, QueryKey, useInfiniteQuery, UseInfiniteQueryResult } from "@tanstack/react-query";
import { ProblemResponse, Response } from "./useHTTP";
import { PaginatedResponseModel } from "./PaginatedResponseModel";

export const useSimpleQueryWithCursor = <T>(
	queryKey: QueryKey,
	queryFunc: QueryFunction<Response<PaginatedResponseModel<T>>>,
	options?: { retry?: boolean }
): UseInfiniteQueryResult<InfiniteData<PaginatedResponseModel<T>, number>, ProblemResponse> => {
	return useInfiniteQuery({
		...options,
		queryKey,
		queryFn: async ctx => {
			const result = await Promise.resolve(queryFunc(ctx));
			return result.body;
		},
		initialPageParam: 0,
		getNextPageParam: lastPage => lastPage.nextCursor
	});
};

import { MutationFunction } from "@tanstack/react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface AuthChangePasswordRequestModel {
	currentPassword: string;
	newPassword: string;
}

export const useAuthChangePassword = () => {
	const http = useHTTP(HTTPAuthMode.Required);

	const mutFunc: MutationFunction<Response, AuthChangePasswordRequestModel> = requestModel => {
		return http<undefined, AuthChangePasswordRequestModel>({
			method: "POST",
			url: "/auth/change-password",
			body: requestModel
		});
	};

	return useSimpleMutation(mutFunc);
};

import { MutationFunction, useQueryClient } from "@tanstack/react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";
import { OfficialDeckCreateCollectionModel } from "./models/OfficialDeckCreateCollectionModel";

export interface OfficialDeckCollectionCreateRequestModel {
	officialDeckId: string;
}

export const useOfficialDeckCollectionCreate = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response<OfficialDeckCreateCollectionModel>, OfficialDeckCollectionCreateRequestModel > = requestModel => {
		return http<OfficialDeckCreateCollectionModel, OfficialDeckCollectionCreateRequestModel >({
			method: "POST",
			url: "/official-deck/collection",
			body: requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["collections"] });
		}
	});
};

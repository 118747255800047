import { useState } from "react";
import { ResponsiveLine } from "@nivo/line";
import dayjs from "dayjs";
import styles from "./LineGraph.module.scss";
import padlock from "@fyendalscollection/app/assets/padlock.svg";
import { useNavigate } from "react-router-dom";
import { Theme, useTheme } from "@fyendalscollection/app/lib/ThemeContext";
import { ScreenSizeView, useScreenSize } from "@fyendalscollection/app/lib/ScreenSizeContext";
import { GraphTooltip } from "@fyendalscollection/app/lib/components/GraphTooltip";

interface DataPoint {
	x: Date;
	y: number | null;
}
export interface DataGroup {
	id: string;
	label: string;
	data: DataPoint[];
	color: string;
	premiumOnly?: boolean;
}
interface LineGraphProps {
	data: DataGroup[];
	defaultSelected?: string[];
}

export const LineGraph = ({ data, defaultSelected }: LineGraphProps) => {
	const theme = useTheme();
	const [selected, setSelected] = useState(defaultSelected ? defaultSelected: data.map(x => x.id));
	const screenSize = useScreenSize();
	const navigate = useNavigate();

	const legendOnClick = (id: string, isEnabled: boolean) => isEnabled &&
		setSelected(selected.indexOf(id) === -1 ? [id, ...selected] : selected.filter(x => x !== id));

	return (
		<>
			<div className={styles.legend}>
				{data.map(x => {
					const isEnabled = x.data.length !== 0;
					const isDeselected = selected.indexOf(x.id) === -1 || !isEnabled;
					const showLegendItem = isEnabled || !isEnabled && !x.premiumOnly;
					return (
						<div key={x.id} className={`${isDeselected && styles.deselected}`} onClick={() => legendOnClick(x.id, isEnabled)}>
							{showLegendItem ?
								<div className={styles.icon} style={{ backgroundColor: isDeselected ? "#AAAAAA" : x.color }} />
								:
								<img className={styles.image} src={padlock} onClick={() => navigate("../settings/Subscription")}/>
							}
							<div className={styles.label}>{x.id}</div>
						</div>
					);
				})}
			</div>
			<ResponsiveLine
				enableArea={true}
				data={data.filter(x => selected.indexOf(x.id) !== -1)}
				colors={{ datum: "color" }}
				pointSize={0}
				lineWidth={4}
				xScale={{ format: "%Y-%m-%d", type: "time" }}
				yFormat={">-.2f"}
				xFormat={value => dayjs(value).format("DD/MM/YY")}
				axisBottom={{
					format: (value) => dayjs(value).format("DD/MM/YY"),
					tickRotation: 40,
					tickPadding: 8,
					tickValues: determineTickValue(screenSize)
				}}
				margin={{ top: 10, right: 44, bottom: 100, left: 82 }}
				theme={{
					text: {
						fontFamily: "Rubik",
						fontSize: 12,
						fill: theme === Theme.Light ? "#444" : "#BBB"
					},
					grid: {
						line: {
							stroke: theme === Theme.Light ? "#EEE" : "#333"
						}
					}
				}}
				yScale={{
					type: "linear",
					min: "auto",
					max: "auto",
					clamp: true,
					nice: true
				}}
				useMesh={true}
				pointLabelYOffset={4}
				axisLeft={{
					tickSize: 5,
					tickPadding: 12,
					tickValues: 8
				}}
				tooltip={(value) => <GraphTooltip color={value.point.color} labels={[{ label: "Date", value: value.point.data.xFormatted}, { label: "Price", value: value.point.data.yFormatted}]} />}
			/>
		</>
	);
};

const determineTickValue = (screenSizeView: ScreenSizeView): number | undefined => {
	switch (screenSizeView) {
	case ScreenSizeView.Desktop:
		return undefined;
	case ScreenSizeView.Tablet:
	case ScreenSizeView.Mobile:
		return 8;
	}
};

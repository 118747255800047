import { EmblemRarity } from "@fyendalscollection/app/shared";

export const getGoldenThresholdForEmblemRarity = (rarity: EmblemRarity): number => {
	switch (rarity) {
	case EmblemRarity.Rare:
		return 5;
	case EmblemRarity.Majestic:
		return 4;
	case EmblemRarity.Legendary:
		return 3;
	case EmblemRarity.Fabled:
		return 2;
	default:
		return 10;
	}
};

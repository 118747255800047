import { HTTPAuthMode, PaginatedResponseModel, useHTTP, useSimpleQueryWithCursor } from "../../http";

export interface SearchProductsResponseModel {
	readonly productId: string;
	readonly name: string;
	readonly imageUrl: string;
	readonly number?: string;
}

export const useProductsSearch = (searchQuery: string, isFullSearch = false) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQueryWithCursor(["productSearch", searchQuery, isFullSearch], ({ pageParam = 0 }) => {
		return http<PaginatedResponseModel<SearchProductsResponseModel>>({
			method: "GET",
			url: `/product/search?q=${searchQuery}&cursor=${pageParam}`
		});
	}, {
		retry: false
	});
};

import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { Checkbox } from "@fyendalscollection/app/lib/components/Checkbox";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { usePreventNotificationEmails } from "@fyendalscollection/app/shared/api/settings/usePreventNotificationEmails";
import { useSetPreventNotificationEmails } from "@fyendalscollection/app/shared/api/settings/useSetPreventNotificationEmails";
import { JSX } from "react";

export const PageNotificationSettings = (): JSX.Element => {
	useTitle("Notification Settings");

	const setPreventNotificationEmails = useSetPreventNotificationEmails();
	const preventNotificationEmails = usePreventNotificationEmails();

	const onChange = (value: boolean) => {
		if (!preventNotificationEmails.data) {
			return;
		}

		if (value !== preventNotificationEmails.data.preventNotificationEmails) {
			setPreventNotificationEmails.mutate({ preventNotificationEmails: value });
		}
	};

	return (
		<Loadable loading={setPreventNotificationEmails.isPending || preventNotificationEmails.isLoading}>
			<Limit force={LimitWidth.Tiny}>

				<Breadcrumb crumbs={[{
					text: "Settings",
					link: "/settings"
				},
				{
					text: "Notification Settings"
				}]}/>

				<PageTitle
					title="Notification Settings"
					subTitle="Configure your notification settings" />

				<Container>
					<ContainerSegment>
						<Checkbox
							label="Don't send notifications to my email"
							isChecked={preventNotificationEmails.data ? preventNotificationEmails.data.preventNotificationEmails : false}
							onChange={onChange} />
					</ContainerSegment>

					{
						setPreventNotificationEmails.isError &&
						<ContainerSegment>
							<ErrorDisplay problemResponse={setPreventNotificationEmails.error}/>
						</ContainerSegment>
					}
				</Container>
			</Limit>
		</Loadable>
	);
};

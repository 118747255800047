import { PriceSourceSelector } from "@fyendalscollection/app/lib/components/PriceSourceSelector";
import { PriceSource, usePreferredPriceSource, useSetPreferredPriceSource } from "@fyendalscollection/app/shared";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";

export const PageSetPreferredPriceSource = (): React.JSX.Element => {
	useTitle("Preferred Price Source");

	const setPreferredPriceSource = useSetPreferredPriceSource();
	const preferredPriceSource = usePreferredPriceSource();

	const onSelectChange = (priceSource: PriceSource) => {
		if (!preferredPriceSource.data) {
			return;
		}
		if (priceSource !== preferredPriceSource.data.priceSource) {
			setPreferredPriceSource.mutate({ priceSource });
		}
	};

	return (
		<Loadable loading={setPreferredPriceSource.isPending || preferredPriceSource.isLoading}>
			<Limit force={LimitWidth.Tiny}>

				<Breadcrumb crumbs={[{
					text: "Settings",
					link: "/settings"
				},
				{
					text: "Preferred Price Source"
				}]}/>

				<PageTitle
					title="Preferred Price Source"
					subTitle="Configure which source of pricing information to use." />

				<Container>
					<ContainerSegment>
						<PriceSourceSelector value={preferredPriceSource.data ? preferredPriceSource.data.priceSource : null} onChange={onSelectChange} />
					</ContainerSegment>

					{setPreferredPriceSource.isError && <ContainerSegment>
						<ErrorDisplay problemResponse={setPreferredPriceSource.error}/>
					</ContainerSegment>}
				</Container>
			</Limit>
		</Loadable>
	);
};


import { useEffect } from "react";
import { Currency, PriceOverrideDetailsModel, PriceOverrideUpdateRequestModel, usePriceOverrideUpdate } from "@fyendalscollection/app/shared";

export const usePriceOverride = (
	price: number,
	currency: Currency,
	priceOverride: PriceOverrideDetailsModel
) => {

	const updatePriceOverride = usePriceOverrideUpdate();

	const performPriceOverrideUpdate = (requestModel: PriceOverrideUpdateRequestModel) => {
		updatePriceOverride.mutate(
			{
				requestModel: requestModel,
				priceOverrideId: priceOverride.priceOverrideId
			}
		);
	};

	useEffect(() => {
		if (isNaN(price)) {
			return;
		}

		if (price == priceOverride.price.value && currency == priceOverride.price.currency) {
			return;
		}

		const requestModel: PriceOverrideUpdateRequestModel = {
			price: {
				value: price,
				currency: currency
			}
		};

		const timer = setTimeout(() => performPriceOverrideUpdate(requestModel), 500);

		return () => clearTimeout(timer);
	}, [price, currency]);

	return updatePriceOverride;
};

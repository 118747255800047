import { MutationFunction, useQueryClient } from "@tanstack/react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface ConfirmImportResponseModel {
	readonly collectionId: string;
}

export const useConfirmImport = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response<ConfirmImportResponseModel>, string> = async importId => {
		return http<ConfirmImportResponseModel>({
			method: "POST",
			url: `/import/${importId}/confirm`,
			body: undefined
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: data => {
			queryClient.invalidateQueries({ queryKey: ["importStatus"] });
			queryClient.invalidateQueries({ queryKey: ["collections"] });
			queryClient.invalidateQueries({ queryKey: ["collection", data.body.collectionId] });
			queryClient.invalidateQueries({ queryKey: ["transactions"] });
			queryClient.invalidateQueries({ queryKey: ["transactionSummaries"] });
			queryClient.invalidateQueries({ queryKey: ["dashboardCurrentPosition"] });
			queryClient.invalidateQueries({ queryKey: ["dashboardHistoricalPositions"] });
		}
	});
};

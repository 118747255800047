import { useCallback } from "react";
import styles from "./ModalEmblemsExplain.module.scss";
import { Emblem } from "@fyendalscollection/app/features/emblems/components/Emblem";
import { EmblemRarity, useStorage } from "@fyendalscollection/app/shared";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";
import { getColorForEmblemRarity } from "@fyendalscollection/app/features/emblems/getColorForEmblemRarity";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";

export interface ModalEmblemsExplainProps {
	visible: boolean;
	onCancel: () => void;
}

export const ModalEmblemsExplain = ({ visible, onCancel }: ModalEmblemsExplainProps): React.JSX.Element => {
	const storage = useStorage();

	const onCancelEnriched = useCallback(() => {
		storage.setPersistent("hasDisplayedEmblemExplainModal", true);
		onCancel();
	}, [onCancel]);

	return (
		<Modal visible={visible} onCancel={onCancelEnriched}>
			<div className={styles.container}>
				<div className={styles.grid}>
					<Emblem emblemId="CRACKED_BAUBLE" rarity={EmblemRarity.Common} count={1} />
					<Emblem emblemId="PRISM" rarity={EmblemRarity.Rare} count={1} />
					<Emblem emblemId="AETHER_WILDFIRE" rarity={EmblemRarity.Majestic} count={1} />
					<Emblem emblemId="FYENDALS_SPRING_TUNIC" rarity={EmblemRarity.Legendary} count={1} />
					<Emblem emblemId="HEART_OF_FYENDAL" rarity={EmblemRarity.Fabled} count={1} />
				</div>

				<TitleSecondary text="Welcome to Emblems!" />

				<div className={styles.explanation}>
					Fyendal&apos;s Collection recently celebrated its 1 year
					anniversary! 🎉<br /><br />

					To commemorate the occasion, and for a bit of fun, we&apos;ve
					introduced <i>Emblems</i>.  Collecting emblems is a fun way to
					personalize your experience on the site and satisfy your inner
					collector!<br /><br />

					There are 5 different rarities of emblems: Common, Rare,
					Majestic, Legendary and Fabled.  Emblems are awarded randomly.
					<br /><br />

					Free users receive 2 emblem rolls each day, while premium users
					receive 5.  Rolls reset each day at midnight UTC time.
					<br /><br />

					You can roll emblems you already own, but don&apos;t worry, this
					only gets you closer to the elusive golden version of the
					emblem!
				</div>

				<div className={styles.rarityTable}>
					<div>
						<div>Rarity</div>
						<div>Pull Rate</div>
						<div>Golden</div>
					</div>
					<div>
						<div>
							<div style={{ backgroundColor: getColorForEmblemRarity(EmblemRarity.Common)}}></div>
							Common
						</div>
						<div>50%</div>
						<div>10 of emblem</div>
					</div>
					<div>
						<div>
							<div style={{ backgroundColor: getColorForEmblemRarity(EmblemRarity.Rare)}}></div>
							Rare
						</div>
						<div>32.5%</div>
						<div>5 of emblem</div>
					</div>
					<div>
						<div>
							<div style={{ backgroundColor: getColorForEmblemRarity(EmblemRarity.Majestic)}}></div>
							Majestic
						</div>
						<div>12.5%</div>
						<div>4 of emblem</div>
					</div>
					<div>
						<div>
							<div style={{ backgroundColor: getColorForEmblemRarity(EmblemRarity.Legendary)}}></div>
							Legendary
						</div>
						<div>4.9%</div>
						<div>3 of emblem</div>
					</div>
					<div>
						<div>
							<div style={{ backgroundColor: getColorForEmblemRarity(EmblemRarity.Fabled)}}></div>
							Fabled
						</div>
						<div>0.1%</div>
						<div>2 of emblem</div>
					</div>
				</div>

				<GreenButtonSet buttons={[
					{
						text: "Got it!",
						onClick: onCancelEnriched
					}
				]} />
			</div>
		</Modal>
	);
};

import { CollectionsSortOrder } from "../../domain";
import { HTTPAuthMode, PaginatedResponseModel, useHTTP, useSimpleQueryWithCursor } from "../../http";
import { CollectionModel } from "./models/CollectionModel";

export const useCollections = (sortOrder: CollectionsSortOrder) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQueryWithCursor(["collections", sortOrder], ({ pageParam = 0 }) => {
		return http<PaginatedResponseModel<CollectionModel>>({
			method: "GET",
			url: `/collection?cursor=${pageParam}&sort=${sortOrder}`
		});
	});
};

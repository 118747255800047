import { Link } from "react-router-dom";
import { TabularData } from "@fyendalscollection/app/lib/components/TabularData";
import { TabularDataItem } from "@fyendalscollection/app/lib/components/TabularDataItem";
import styles from "./CollectionListView.module.scss";
import { Money, TransactionSummaryModel } from "@fyendalscollection/app/shared";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";
import { ProductImage } from "@fyendalscollection/app/lib/components/ProductImage";
import { Padlocked } from "@fyendalscollection/app/lib/components/Padlocked";

export interface CollectionListViewProps {
	transactionSummaries: TransactionSummaryModel[];
	scrollerElement: React.ReactNode;
	collectionId: string;
}

interface ItemImageProps {
	item: TransactionSummaryModel;
}

export const CollectionListView = (props: CollectionListViewProps): React.JSX.Element => {

	const getSingleItemValue = (total: Money, quantity: number): Money => {
		if (quantity === 0) {
			return {
				value: 0,
				currency: total.currency
			};
		}

		return {
			value: total.value/quantity,
			currency: total.currency
		};
	};

	const ItemImage = ({item}: ItemImageProps): React.JSX.Element => {
		return (
			<Link className={styles.productDisplay} to={`/collections/${props.collectionId}/transactions/${item.variantId}`}>
				<ProductImage className={styles.productImage} image={item.imageUrl} />
				<div>
					<div>{item.productName}</div>
					<div>{item.fcId}</div>
				</div>
			</Link>
		);
	};

	return (
		<>
			<TabularData headers={[ "PRODUCT", "VALUE", "QTY", "TOTAL", "GAIN", "INVESTED", "PROFIT", "CASH POSITION" ]}>
				{
					props.transactionSummaries.map(x => (
						<TabularDataItem key={x.variantId}>
							<>
								<ItemImage item={x} />
							</>
							<>
								<PrettyMoney className={styles.bold} money={getSingleItemValue(x.marketValue, x.quantity)} mode={MoneyDisplayMode.Standard} />
							</>
							<>
								<div className={styles.bold}>{x.quantity}</div>
							</>
							<>
								<PrettyMoney className={styles.bold} money={x.marketValue} mode={MoneyDisplayMode.Standard} />
							</>
							<>
								<Padlocked on={x.gain} element={gain => (
									<PrettyMoney className={styles.bold} money={gain} mode={MoneyDisplayMode.DeltaPercentage} originalValue={x.invested?.value || 0} />)} />
							</>
							<>
								<Padlocked on={x.invested} element={invested => (
									<PrettyMoney className={styles.bold} money={invested} mode={MoneyDisplayMode.Standard} />)} />
							</>
							<>
								<Padlocked on={x.profit} element={profit => (
									<PrettyMoney className={styles.bold} money={profit} mode={MoneyDisplayMode.Delta} />)} />
							</>
							<>
								<Padlocked on={x.cashPosition} element={cashPosition => (
									<PrettyMoney className={styles.bold} money={cashPosition} mode={MoneyDisplayMode.Delta} />)} />
							</>
						</TabularDataItem>
					))
				}
			</TabularData>

			{props.scrollerElement}
		</>
	);
};

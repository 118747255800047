import { MutationFunction, useMutation, UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { ProblemResponse, Response } from "./useHTTP";

export const useSimpleMutation = <T, U>(
	mutFunc: MutationFunction<Response<T>, U>,
	options?: UseMutationOptions<Response<T>, ProblemResponse, U>
): UseMutationResult<Response<T>, ProblemResponse, U> => useMutation({
		...options,
		mutationFn: mutFunc
	});

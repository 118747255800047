import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { Checkbox } from "@fyendalscollection/app/lib/components/Checkbox";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { useUserPreferencesState } from "@fyendalscollection/app/shared";

export const PageSearchPanelSettings = () => {
	useTitle("Side Panel Settings");

	const {
		state,
		dispatchSetCloseSearchPanelAfterSelect
	} = useUserPreferencesState();

	return (
		<Limit force={LimitWidth.Tiny}>
			<Breadcrumb crumbs={[{
				text: "Settings",
				link: "/settings"
			},
			{
				text: "Search Panel"
			}]}/>

			<PageTitle
				title="Search Panel"
				subTitle="Adjust the behavior of your search panel" />

			<Container>
				<ContainerSegment>
					When this box is checked, the search panel will close after
					selecting a card when editing your collection.  Otherwise,
					it will remain open.
				</ContainerSegment>

				<ContainerSegment>
					<Checkbox
						label="Close search panel after selection"
						onChange={dispatchSetCloseSearchPanelAfterSelect}
						isChecked={state.closeSearchPanelAfterSelect} />
				</ContainerSegment>
			</Container>
		</Limit>
	);
};

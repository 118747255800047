import { MutationFunction } from "@tanstack/react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export type PaymentCardSetupIntentResponseModel = {
	setupIntentClientSecret: string;
};

export const usePaymentCardSetupIntent = () => {
	const http = useHTTP(HTTPAuthMode.Required);

	const mutFunc: MutationFunction<Response<PaymentCardSetupIntentResponseModel>> = () => {
		return http({
			method: "POST",
			url: "/stripe/setup-intent",
			body: undefined
		});
	};

	return useSimpleMutation(mutFunc);
};

import { Children, useContext } from "react";
import { TabularDataContext } from "./TabularData";

export interface TabularDataItemProps {
	children: React.ReactNode;
}

export const TabularDataItem = ({ children }: TabularDataItemProps): React.JSX.Element => {
	const headers = useContext(TabularDataContext);

	return (
		<div>
			{
				Children.map(children, (x, i) => !x ? x : (
					<div>
						<div>{headers[i]}</div>
						<div>{x}</div>
					</div>
				))
			}
		</div>
	);
};

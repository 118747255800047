import { MutationFunction } from "@tanstack/react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface AuthResendVerifyEmailRequestModel {
	userId: string;
}

export const useAuthResendVerifyEmail = () => {
	const http = useHTTP(HTTPAuthMode.None);

	const mutFunc: MutationFunction<Response, AuthResendVerifyEmailRequestModel> = requestModel => {
		return http<undefined, AuthResendVerifyEmailRequestModel>({
			method: "POST",
			url: "/auth/resend",
			body: requestModel
		});
	};

	return useSimpleMutation(mutFunc);
};

import { MutationFunction, useQueryClient } from "@tanstack/react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";
import { Currency } from "../../domain";

export interface SetPreferredCurrencyRequestModel {
	currency: Currency;
}

export const useSetPreferredCurrency = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response, SetPreferredCurrencyRequestModel> = requestModel => {
		return http<undefined, SetPreferredCurrencyRequestModel>({
			method: "POST",
			url: "/settings/preferred-currency",
			body: requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: (_, requestModel) => {
			queryClient.resetQueries();
			queryClient.setQueryData(["preferredCurrency"], requestModel);
		}
	});
};

import { useEffect, useRef, useState } from "react";
import styles from "./SearchPanel.module.scss";
import { TextEntry } from "@fyendalscollection/app/lib/components/TextEntry";
import { useFocus } from "@fyendalscollection/app/lib/useFocus";
import { LinkButton } from "@fyendalscollection/app/lib/components/LinkButton";
import { useDebouncedState } from "@fyendalscollection/app/lib/useDebouncedState";
import { GetProductAndVariantsMarketPriceResponseModel, useProduct, useProductsSearch, useUserPreferencesState, VariantMarketPriceResponseModel } from "@fyendalscollection/app/shared";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { InfiniteScroller } from "@fyendalscollection/app/lib/components/InfiniteScroller";
import { SearchPanelUsage, SearchPanelVariant, useSearchPanelManager } from "@fyendalscollection/app/lib/useSearchPanel";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";

export interface SearchPanelProps {
	usage: SearchPanelUsage | null;
	onCancel: () => void;
}

export const SearchPanel = (props: SearchPanelProps): React.JSX.Element => {
	const {
		usage,
		onCancel
	} = props;

	const [searchQuery, searchText, setSearchText] = useDebouncedState("", 350);
	const [searchRef, focusSearch] = useFocus();
	const [hasVisibleClass, setHasVisibleClass] = useState(false);
	const [hasDisplayedClass, setHasDisplayedClass] = useState(false);
	const [selectionFeedbackVisible, setSelectionFeedbackVisible] = useState(false);
	const [selectedProductId, setSelectedProductId] = useState<string | null>(null);
	const manager = useSearchPanelManager();
	const { state } = useUserPreferencesState();

	useEffect(() => {
		setSelectedProductId(null);
	}, [searchText]);

	useEffect(() => {
		if (usage !== null) {
			setHasDisplayedClass(true);

			const timeout = setTimeout(() => setHasVisibleClass(true), 20);
			return () => clearTimeout(timeout);
		} else {
			setHasVisibleClass(false);

			const timeout = setTimeout(() => setHasDisplayedClass(false), 200);
			return () => clearTimeout(timeout);
		}
	}, [usage]);

	useEffect(() => {
		if (!selectionFeedbackVisible) {
			return;
		}

		const timeout = setTimeout(() => setSelectionFeedbackVisible(false), 900);
		return () => clearTimeout(timeout);
	}, [selectionFeedbackVisible]);

	useEffect(() => {
		if (!hasVisibleClass) {
			return;
		}

		focusSearch();
	}, [hasVisibleClass]);

	const backDropStyles = [ styles.backDrop ];
	if (hasVisibleClass) {
		backDropStyles.push(styles.visible);
	}
	if (hasDisplayedClass) {
		backDropStyles.push(styles.displayed);
	}

	const panelStyles = [ styles.panel ];
	if (hasVisibleClass) {
		panelStyles.push(styles.visible);
	}

	const selectionFeedback = [ styles.selectionFeedback ];
	if (selectionFeedbackVisible) {
		selectionFeedback.push(styles.visible);
	}

	const onVariantSelected = (variant: SearchPanelVariant) => {
		manager.emit(variant);
		setSelectedProductId(null);

		if (usage === SearchPanelUsage.SingleVariant || state.closeSearchPanelAfterSelect) {
			onCancel();
			return;
		}

		setSelectionFeedbackVisible(true);
	};

	return (
		<div className={backDropStyles.join(" ")} onClick={onCancel}>
			<div className={panelStyles.join(" ")} onClick={e => e.stopPropagation()}>
				<div className={styles.searchHolder}>
					<TextEntry
						value={searchText}
						onChange={setSearchText}
						placeholder="Search"
						ref={searchRef} />
				</div>

				<div className={styles.mobileCancelButton}>
					<LinkButton text="Cancel Search" onClick={onCancel} />
				</div>

				<div className={selectionFeedback.join(" ")}>✅ Added!</div>

				{
					selectedProductId &&
					<VariantSelector productId={selectedProductId} onVariantSelected={onVariantSelected} />
				}

				{
					searchQuery &&
					<SearchResults searchQuery={searchQuery} onProductSelected={setSelectedProductId} />
				}
			</div>
		</div>
	);
};

interface SearchResultsProps {
	searchQuery: string;
	onProductSelected: (productId: string) => void;
}

const SearchResults = ({ searchQuery, onProductSelected }: SearchResultsProps): React.JSX.Element => {
	const productsSearch = useProductsSearch(searchQuery);
	const scrollRef = useRef<HTMLDivElement>(null);

	if (productsSearch.error) {
		return (
			<ErrorDisplay problemResponse={productsSearch.error} />
		);
	}

	if (!productsSearch.data) {
		return (
			<Loadable loading={true}><div className={styles.loaderPadding}></div></Loadable>
		);
	}

	const searchResults = productsSearch.data.pages.flatMap(x => x.results);

	return (
		<>
			{
				searchResults.length < 1 &&
				<div className={styles.noResults}>
					No search results found!
				</div>
			}

			<div ref={scrollRef} className={styles.searchResults}>
				{
					searchResults.map(x => (
						<div key={x.productId} className={styles.searchResult} onClick={() => onProductSelected(x.productId)}>
							<img src={x.imageUrl} />
							<div>{x.name}</div>
							<div>{x.number}</div>
						</div>
					))
				}

				<InfiniteScroller forQuery={productsSearch} scrollRef={scrollRef} />
			</div>
		</>
	);
};

interface VariantSelectorProps {
	productId: string;
	onVariantSelected: (variant: SearchPanelVariant) => void;
}

const VariantSelector = ({ productId, onVariantSelected }: VariantSelectorProps): React.JSX.Element => {
	const product = useProduct(productId);

	useEffect(() => {
		if (!product.data) {
			return;
		}

		if (product.data.variants.length === 1) {
			onVariantSelected(getSearchPanelVariant(product.data, product.data.variants[0]));
		}
	}, [product.data]);

	if (product.error) {
		return (
			<ErrorDisplay problemResponse={product.error} />
		);
	}

	if (!product.data) {
		return (
			<Loadable loading={true}><div className={styles.loaderPadding}></div></Loadable>
		);
	}

	if (product.data.variants.length < 1) {
		return (
			<div className={styles.noResults}>
				No variants found for this product!
			</div>
		);
	}

	return (
		<div className={styles.variantSelector}>
			{
				product.data.variants.map(x => (
					<div key={x.variantId} onClick={() => onVariantSelected(getSearchPanelVariant(product.data, x))}>
						<div>{x.variantName}</div>
						<div>{x.marketPrice && <PrettyMoney mode={MoneyDisplayMode.Standard} money={x.marketPrice} />}</div>
						<div><LinkButton text="Select" onClick={() => undefined} /></div>
					</div>
				))
			}
		</div>
	);
};

const getSearchPanelVariant = (product: GetProductAndVariantsMarketPriceResponseModel, variant: VariantMarketPriceResponseModel): SearchPanelVariant => {
	return {
		variantId: variant.variantId,
		productId: product.productId,
		imageUrl: product.imageUrl,
		fcId: variant.fcId,
		marketPrice: variant.marketPrice,
		productName: product.name,
		variantName: variant.variantName
	};
};

import { useUserPreferencesState } from "@fyendalscollection/app/shared";
import { StyledSelect } from "@fyendalscollection/app/lib/components/StyledSelect";
import { EvProductType, EvSort, EvStep } from "@fyendalscollection/app/shared";

export const EvTrackerFilter = (): React.JSX.Element => {
	const {
		state,
		dispatchSetEvStep,
		dispatchSetEvSort,
		dispatchSetEvProductType
	} = useUserPreferencesState();

	const {
		evStep,
		evSort,
		evProductType
	} = state;

	return (
		<>
			<StyledSelect value={evProductType} onChange={dispatchSetEvProductType} options={[
				{ text: "Products: All", value: null },
				{ text: "Products: Packs", value: EvProductType.Pack },
				{ text: "Products: Boxes", value: EvProductType.Box },
				{ text: "Products: Cases", value: EvProductType.Case }
			]} />

			<StyledSelect value={evSort} onChange={dispatchSetEvSort} options={[
				{ text: "Sort: Oldest Set", value: EvSort.OldestReleaseDate },
				{ text: "Sort: Newest Set", value: EvSort.NewestReleaseDate },
				{ text: "Sort: Highest Estimated Value", value: EvSort.HighestEstimatedValue},
				{ text: "Sort: Lowest Estimated Value", value: EvSort.LowestEstimatedValue},
				{ text: "Sort: Percentage Gain", value: EvSort.PercentageGain },
				{ text: "Sort: Percentage Loss", value: EvSort.PercentageLoss },
				{ text: "Sort: Flat Gain", value: EvSort.FlatGain },
				{ text: "Sort: Flat Loss", value: EvSort.FlatLoss },
				{ text: "Sort: Market Delta Positive", value: EvSort.MarketDeltaPositive },
				{ text: "Sort: Market Delta Negative", value: EvSort.MarketDeltaNegative }
			]} />

			<StyledSelect value={evStep} onChange={dispatchSetEvStep} options={[
				{ text: "Time: 24 Hours", value: EvStep.Step24Hours },
				{ text: "Time: 3 Days", value: EvStep.Step3Days },
				{ text: "Time: 7 Days", value: EvStep.Step7Days },
				{ text: "Time: 14 Days", value: EvStep.Step14Days },
				{ text: "Time: 1 Month", value: EvStep.Step1Month },
				{ text: "Time: 3 Months", value: EvStep.Step3Months },
				{ text: "Time: 6 Months", value: EvStep.Step6Months },
				{ text: "Time: 1 Year", value: EvStep.Step1Year }
			]} />
		</>
	);
};

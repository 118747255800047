import { MutationFunction } from "@tanstack/react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface AuthInitiateForgotPasswordRequestModel {
	emailAddress: string;
}

export const useAuthInitiateForgotPassword = () => {
	const http = useHTTP(HTTPAuthMode.None);

	const mutFunc: MutationFunction<Response, AuthInitiateForgotPasswordRequestModel> = requestModel => {
		return http<undefined, AuthInitiateForgotPasswordRequestModel>({
			method: "POST",
			url: "/auth/forgot/initiate",
			body: requestModel
		});
	};

	return useSimpleMutation(mutFunc);
};

import { Route, Routes } from "react-router-dom";
import { PageMfaDisabled } from "./features/auth/pages/PageMfaDisabled";
import { PageMfaEnabled } from "./features/auth/pages/PageMfaEnabled";
import { FrameLogin } from "./features/auth/FrameLogin";
import { FrameDashboard } from "./lib/components/FrameDashboard";
import { PageMfa } from "./features/settings/pages/PageMfa";
import { PageBrowse } from "./features/products/pages/PageBrowse";
import { PageProduct } from "./features/products/pages/PageProduct";
import { PageImport } from "./features/import/pages/PageImport";
import { PageCsvTutorial } from "./features/import/pages/PageCsvTutorial";
import { PagePerformanceDeltas } from "./features/performanceDeltas/pages/PagePerformanceDeltas";
import { PageEvBreakdown } from "./features/ev/pages/PageEvBreakdown";
import { PageEvCategoryVariants } from "@fyendalscollection/app/features/ev/pages/PageEvCategoryVariants";
import { PageMaintenance } from "@fyendalscollection/app/lib/PageMaintenance";
import { PageNotifications } from "@fyendalscollection/app/features/notifications/pages/PageNotifications";
import { PageNotification } from "@fyendalscollection/app/features/notifications/pages/PageNotification";
import { PagePublicCollection } from "@fyendalscollection/app/features/collections/pages/PagePublicCollection";
import { PageSponsorPerformance } from "@fyendalscollection/app/features/sponsor/pages/PageSponsorPerformance";
import { PageEmblems } from "@fyendalscollection/app/features/emblems/pages/PageEmblems";
import { PageCollectionProgress } from "@fyendalscollection/app/features/collections/pages/PageCollectionProgress";
import { Landing } from "@fyendalscollection/app/lib/components/Landing";
import { PageLogin } from "@fyendalscollection/app/features/auth/pages/PageLogin";
import { PageRegister } from "@fyendalscollection/app/features/auth/pages/PageRegister";
import { PageInitiateForgotPassword } from "@fyendalscollection/app/features/auth/pages/PageInitiateForgotPassword";
import { PageConcludeForgotPassword } from "@fyendalscollection/app/features/auth/pages/PageConcludeForgotPassword";
import { PageEmailVerify } from "@fyendalscollection/app/features/auth/pages/PageEmailVerify";
import { PageDashboard } from "@fyendalscollection/app/features/collections/pages/PageDashboard";
import { PageCollections } from "@fyendalscollection/app/features/collections/pages/PageCollections";
import { PageCollectionView } from "@fyendalscollection/app/features/collections/pages/PageCollectionView";
import { PageCollectionsCSVExport } from "@fyendalscollection/app/features/collections/pages/PageCollectionsCSVExport";
import { PageCollectionPerformanceDeltas } from "@fyendalscollection/app/features/collections/pages/PageCollectionPerformanceDeltas";
import { PageTransactions } from "@fyendalscollection/app/features/transactions/pages/PageTransactions";
import { PageVariantTransactions } from "@fyendalscollection/app/features/transactions/pages/PageVariantTransactions";
import { PageCollectionOperations } from "@fyendalscollection/app/features/operations/pages/PageCollectionOperations";
import { PageOperationCompare } from "@fyendalscollection/app/features/operations/pages/PageOperationCompare";
import { PageEvTracker } from "@fyendalscollection/app/features/ev/pages/PageEvTracker";
import { PageSettings } from "@fyendalscollection/app/features/settings/pages/PageSettings";
import { PageChangePassword } from "@fyendalscollection/app/features/settings/pages/PageChangePassword";
import { PageSetPreferredCurrency } from "@fyendalscollection/app/features/settings/pages/PageSetPreferredCurrency";
import { PageSetPreferredPriceSource } from "@fyendalscollection/app/features/settings/pages/PageSetPreferredPriceSource";
import { PagePriceOverrides } from "@fyendalscollection/app/features/settings/pages/PagePriceOverrides";
import { PagePaymentCards } from "@fyendalscollection/app/features/paymentCards/pages/PagePaymentCards";
import { PageSubscription } from "@fyendalscollection/app/features/subscriptions/pages/PageSubscription";
import { PageNotFound } from "@fyendalscollection/app/lib/PageNotFound";
import { PageTransactionsCSVExport } from "@fyendalscollection/app/features/transactions/pages/PageTransactionsCSVExport";
import { PageOfficialDecks } from "@fyendalscollection/app/features/officialDecks/pages/PageOfficialDecks";
import { PageOfficialDeckDetails } from "@fyendalscollection/app/features/officialDecks/pages/PageOfficialDeckDetails";
import { PageTransactionsBulk } from "@fyendalscollection/app/features/transactions/pages/PageTransactionsBulk";
import { BulkTransactionsProvider } from "@fyendalscollection/app/features/transactions/BulkTransactionState";
import { PageSearchPanelSettings } from "@fyendalscollection/app/features/settings/pages/PageSearchPanelSettings";
import { PageNotificationSettings } from "@fyendalscollection/app/features/settings/pages/PageNotificationSettings";
import { PageCollectionProgressCSVExport } from "@fyendalscollection/app/features/collections/pages/PageCollectionProgressCSVExport";

export const Router = (): React.JSX.Element => {
	return (
		<Routes>
			<Route index element={<Landing />} />
			<Route path="maintenance" element={<FrameLogin />}>
				<Route index element={<PageMaintenance />} />
			</Route>

			<Route path="login" element={<FrameLogin />}>
				<Route index element={<PageLogin />} />
				<Route path="register" element={<PageRegister />} />
				<Route path="forgot-password/initiate" element={<PageInitiateForgotPassword />} />
				<Route path="forgot-password/conclude" element={<PageConcludeForgotPassword />} />
				<Route path="email-verify" element={<PageEmailVerify />} />
				<Route path="mfa/enabled" element={<PageMfaEnabled />} />
				<Route path="mfa/disabled" element={<PageMfaDisabled />} />
			</Route>

			<Route path="dashboard" element={<FrameDashboard />}>
				<Route index element={<PageDashboard />} />
			</Route>

			<Route path="collections" element={<FrameDashboard />}>
				<Route index element={<PageCollections />} />
				<Route path="csv-tutorial" element={<PageCsvTutorial />} />
				<Route path="csv-import" element={<PageImport />} />
				<Route path=":collectionId">
					<Route index element={<PageCollectionView />} />
					<Route path="csv-export" element={<PageCollectionsCSVExport />}/>
					<Route path="trends" element={<PageCollectionPerformanceDeltas />}/>

					<Route path="transactions">
						<Route index element={<PageTransactions />} />
						<Route path="csv-import" element={<PageImport />} />
						<Route path="csv-export" element={<PageTransactionsCSVExport />} />
						<Route path="bulk" element={<BulkTransactionsProvider><PageTransactionsBulk /></BulkTransactionsProvider>} />
						<Route path=":variantId" element={<PageVariantTransactions />} />
					</Route>

					<Route path="progress">
						<Route index element={<PageCollectionProgress />} />
						<Route path="csv-export" element={<PageCollectionProgressCSVExport />} />
					</Route>

					<Route path="tools">
						<Route index element={<PageCollectionOperations />} />
						<Route path="compare/:compareCollectionId" element={<PageOperationCompare />} />
					</Route>
				</Route>
			</Route>

			<Route path="public/:shareToken" element={<PagePublicCollection />} />

			<Route path="browse" element={<FrameDashboard />}>
				<Route index element={<PageBrowse />} />
				<Route path="product/:productId" element={<PageProduct />} />
			</Route>

			<Route path="market-trends" element={<FrameDashboard />}>
				<Route index element={<PagePerformanceDeltas />} />
			</Route>

			<Route path="ev-tracker" element={<FrameDashboard />}>
				<Route index element={<PageEvTracker />} />
				<Route path="breakdown/:setType/:productType" element={<PageEvBreakdown />} />
				<Route path="breakdown/:setType/:productType/:category" element={<PageEvCategoryVariants />}/>
			</Route>

			<Route path="settings" element={<FrameDashboard />}>
				<Route index element={<PageSettings />} />
				<Route path="change-password" element={<PageChangePassword />} />
				<Route path="mfa" element={<PageMfa />} />
				<Route path="preferred-currency" element={<PageSetPreferredCurrency />} />
				<Route path="preferred-price-source" element={<PageSetPreferredPriceSource />} />
				<Route path="search-panel" element={<PageSearchPanelSettings />}/>
				<Route path="price-overrides" element={<PagePriceOverrides />} />
				<Route path="payment-cards" element={<PagePaymentCards />} />
				<Route path="subscription" element={<PageSubscription />} />
				<Route path="notifications" element={<PageNotificationSettings />} />
			</Route>

			<Route path="notifications" element={<FrameDashboard />}>
				<Route index element={<PageNotifications />} />
				<Route path=":notificationId" element={<PageNotification />} />
			</Route>

			<Route path="sponsor" element={<FrameDashboard />}>
				<Route index element={<PageSponsorPerformance />} />
			</Route>

			<Route path="emblems" element={<FrameDashboard />}>
				<Route index element={<PageEmblems />} />
			</Route>

			<Route path="*" element={<FrameLogin />}>
				<Route path="*" element={<PageNotFound />} />
			</Route>

			<Route path="official-decks" element={<FrameDashboard />}>
				<Route index element={<PageOfficialDecks />} />
				<Route path=":officialDeckId" element={<PageOfficialDeckDetails />} />
			</Route>
		</Routes>
	);
};

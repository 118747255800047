import { MutationFunction, useQueryClient } from "@tanstack/react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export const useMarkNotificationRead = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response, string> = (notificationId: string) => {
		return http({
			method: "PATCH",
			url: `/notification/${notificationId}/read`
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ["notifications"] });
			queryClient.invalidateQueries({ queryKey: ["notificationUnreadCount"] });
		}
	});
};

import { useEffect, useMemo, useRef, useState } from "react";
import * as Three from "three";
import confetti from "canvas-confetti";
import styles from "./ModalEmblemsRoll.module.scss";
import { ThreeCanvas } from "@fyendalscollection/app/lib/components/ThreeCanvas";
import { PromiseGLTFLoader } from "@fyendalscollection/app/lib/PromiseGLTFLoader";
import { Emblem } from "@fyendalscollection/app/features/emblems/components/Emblem";
import { getColorForEmblemRarity } from "@fyendalscollection/app/features/emblems/getColorForEmblemRarity";
import { getGoldenThresholdForEmblemRarity } from "@fyendalscollection/app/features/emblems/getGoldenThresholdForEmblemRarity";
import { EmblemRarity, useRoll } from "@fyendalscollection/app/shared";

export interface ModalEmblemRollProps {
	visible: boolean;
	onCancel: () => void;
}

interface DrawArgs {
	scene: Three.Scene;
	camera: Three.Camera;
	renderer: Three.WebGLRenderer;
	tt: number;
	ot: number;
	chosenAt: number | null;
	gemCommon: Three.Group<Three.Object3DEventMap>;
	gemRare: Three.Group<Three.Object3DEventMap>;
	gemMajestic: Three.Group<Three.Object3DEventMap>;
	gemLegendary: Three.Group<Three.Object3DEventMap>;
	gemFabled: Three.Group<Three.Object3DEventMap>;
}

export const ModalEmblemsRoll = ({ visible, onCancel }: ModalEmblemRollProps) => {
	const [gemsLoaded, setGemsLoaded] = useState(false);
	const gems = useRef<Three.Group<Three.Object3DEventMap>[] | null>(null);
	const roll = useRoll();
	const updateFunc = useMemo(() => updateRollScene(roll.data?.body.rarity), [roll.data]);
	const [emblemVisible, setEmblemVisible] = useState(false);

	useEffect(() => {
		const gltfLoader = new PromiseGLTFLoader();
		const promises = ["common", "rare", "majestic", "legendary", "fabled"].map(x => gltfLoader.load(`/gems/${x}.glb`));
		Promise.all(promises).then(models => {
			gems.current = models.map(x => x.scene);
			setGemsLoaded(true);
		});
	}, []);

	useEffect(() => {
		if (!visible || !gemsLoaded) {
			return;
		}

		setTimeout(() => roll.mutate(undefined), 3500);
	}, [visible, gemsLoaded]);

	useEffect(() => {
		if (!roll.isSuccess) {
			return;
		}

		setTimeout(() => confetti({
			particleCount: getParticleCountForRarity(roll.data.body.rarity),
			spread: 120,
			origin: { y: 0.5 },
			colors: getColorsForRarity(roll.data.body.rarity)
		}), 800);

		if (roll.data.body.count === getGoldenThresholdForEmblemRarity(roll.data.body.rarity)) {
			const goldenConfetti = () => confetti({
				particleCount: 100,
				spread: 100,
				origin: { y: 0.5 },
				colors: [ "#ffae00" ]
			});

			setTimeout(goldenConfetti, 800);
			setTimeout(goldenConfetti, 1000);
			setTimeout(goldenConfetti, 1200);
			setTimeout(goldenConfetti, 1400);
			setTimeout(goldenConfetti, 1600);
			setTimeout(goldenConfetti, 1800);
		}

		setTimeout(() => setEmblemVisible(true), 1200);
		setTimeout(() => setEmblemVisible(false), 4000);

		setTimeout(() => {
			onCancel();
			roll.reset();
		}, 6000);
	}, [roll.isSuccess]);

	if (!visible || !gemsLoaded || !gems.current) {
		return <></>;
	}

	const [canvasWidth, canvasHeight] = [window.innerWidth, Math.min(window.innerWidth, window.innerHeight)];

	const emblemOverlayClassNames = [styles.emblemOverlay];
	if (emblemVisible) {
		emblemOverlayClassNames.push(styles.visible);
	}

	return (
		<div className={styles.canvasContainer}>
			<div className={emblemOverlayClassNames.join(" ")}>
				<div>
					{
						roll.data &&
						<Emblem
							{...roll.data.body}
							count={roll.data.body.count > getGoldenThresholdForEmblemRarity(roll.data.body.rarity) ? 1 : roll.data.body.count} />
					}
				</div>
				{
					roll.data &&
					<div>
						<div>{roll.data.body.name}</div>
						<div style={{ backgroundColor: getColorForEmblemRarity(roll.data.body.rarity) }}>
							{roll.data.body.rarity}
						</div>
					</div>
				}
			</div>

			<ThreeCanvas
				width={canvasWidth}
				height={canvasHeight}
				setupArgs={gems.current}
				setupFunc={setupRollScene}
				updateFunc={updateFunc} />
		</div>
	);
};

const setupRollScene = (canvas: HTMLCanvasElement, ctx: WebGLRenderingContext, gems: Three.Group<Three.Object3DEventMap>[]): DrawArgs => {
	const scene = new Three.Scene();
	const camera = new Three.PerspectiveCamera(75, canvas.width / canvas.height, 0.1, 1000);

	camera.position.z = 0;
	camera.lookAt(new Three.Vector3(0, 0, 0));

	const ambientLight = new Three.AmbientLight(0xFFFFFF);
	scene.add(ambientLight);

	const directionalLight = new Three.DirectionalLight(0xffffff);
	directionalLight.position.set(5, 5, 5).normalize();
	scene.add(directionalLight);

	const renderer = new Three.WebGLRenderer({
		canvas: canvas,
		context: ctx,
		antialias: true
	});
	renderer.setSize(canvas.width, canvas.height);

	gems.forEach(x => scene.add(x));

	return {
		scene,
		camera,
		renderer,
		tt: 0,
		ot: new Date().getTime(),
		chosenAt: 0,
		gemCommon: gems[0],
		gemRare: gems[1],
		gemMajestic: gems[2],
		gemLegendary: gems[3],
		gemFabled: gems[4]
	};
};

const updateRollScene = (rarity: EmblemRarity | undefined) => (args: DrawArgs, dt: number) => {
	args.tt += dt;

	if (rarity && !args.chosenAt) {
		args.chosenAt = args.tt;
	}

	if (args.tt > 1500) {
		args.camera.position.z = 32;
	} else {
		args.camera.position.z = Math.sin((args.tt / 1500) * Math.PI / 2) * 32;
	}

	const ct = args.tt - (args.chosenAt || args.tt);
	const chosenDistance = 12 - Math.sin(Math.min(ct / 1500, 1) * Math.PI / 2) * 12;

	// Common.
	const commonDistance = rarity && rarity === EmblemRarity.Common ? chosenDistance : 12;
	args.gemCommon.position.x = Math.sin(((args.tt + args.ot) / 1000) + 0 * Math.PI * 2 / 5) * commonDistance;
	args.gemCommon.position.y = Math.cos(((args.tt + args.ot) / 1000) + 0 * Math.PI * 2 / 5) * commonDistance;
	args.gemCommon.rotation.x = Math.sin((args.tt / 3000) + 0 * Math.PI * 2 / 5) * Math.PI * 2;
	args.gemCommon.rotation.y = Math.cos((args.tt / 3000) + 0 * Math.PI * 2 / 5) * Math.PI * 2;

	if (rarity && rarity !== EmblemRarity.Common) {
		args.gemCommon.scale.setScalar(1 - Math.min(ct / 1500, 1));
	} else if (rarity === EmblemRarity.Common) {
		args.gemCommon.scale.setScalar(Math.max(1 - (Math.max(ct - 3000, 0) / 1000), 0));
	} else {
		args.gemCommon.scale.setScalar(1);
	}

	// Rare.
	const rareDistance = rarity && rarity === EmblemRarity.Rare ? chosenDistance : 12;
	args.gemRare.position.x = Math.sin(((args.tt + args.ot) / 1000) + 1 * Math.PI * 2 / 5) * rareDistance;
	args.gemRare.position.y = Math.cos(((args.tt + args.ot) / 1000) + 1 * Math.PI * 2 / 5) * rareDistance;
	args.gemRare.rotation.x = Math.sin((args.tt / 3000) + 3 * Math.PI * 2 / 5) * Math.PI * 2;
	args.gemRare.rotation.y = Math.cos((args.tt / 3000) + 3 * Math.PI * 2 / 5) * Math.PI * 2;

	if (rarity && rarity !== EmblemRarity.Rare) {
		args.gemRare.scale.setScalar(1 - Math.min(ct / 1500, 1));
	} else if (rarity === EmblemRarity.Rare) {
		args.gemRare.scale.setScalar(Math.max(1 - (Math.max(ct - 3000, 0) / 1000), 0));
	} else {
		args.gemRare.scale.setScalar(1);
	}

	// Majestic.
	const majesticDistance = rarity && rarity === EmblemRarity.Majestic ? chosenDistance : 12;
	args.gemMajestic.position.x = Math.sin(((args.tt + args.ot) / 1000) + 2 * Math.PI * 2 / 5) * majesticDistance;
	args.gemMajestic.position.y = Math.cos(((args.tt + args.ot) / 1000) + 2 * Math.PI * 2 / 5) * majesticDistance;
	args.gemMajestic.rotation.x = Math.sin((args.tt / 3000) + 2 * Math.PI * 2 / 5) * Math.PI * 2;
	args.gemMajestic.rotation.y = Math.cos((args.tt / 3000) + 2 * Math.PI * 2 / 5) * Math.PI * 2;

	if (rarity && rarity !== EmblemRarity.Majestic) {
		args.gemMajestic.scale.setScalar(1 - Math.min(ct / 1500, 1));
	} else if (rarity === EmblemRarity.Majestic) {
		args.gemMajestic.scale.setScalar(Math.max(1 - (Math.max(ct - 3000, 0) / 1000), 0));
	} else {
		args.gemMajestic.scale.setScalar(1);
	}

	// Legendary.
	const legendaryDistance = rarity && rarity === EmblemRarity.Legendary ? chosenDistance : 12;
	args.gemLegendary.position.x = Math.sin(((args.tt + args.ot) / 1000) + 3 * Math.PI * 2 / 5) * legendaryDistance;
	args.gemLegendary.position.y = Math.cos(((args.tt + args.ot) / 1000) + 3 * Math.PI * 2 / 5) * legendaryDistance;
	args.gemLegendary.rotation.x = Math.sin((args.tt / 3000) + 3 * Math.PI * 2 / 5) * Math.PI * 2;
	args.gemLegendary.rotation.y = Math.cos((args.tt / 3000) + 3 * Math.PI * 2 / 5) * Math.PI * 2;

	if (rarity && rarity !== EmblemRarity.Legendary) {
		args.gemLegendary.scale.setScalar(1 - Math.min(ct / 1500, 1));
	} else if (rarity === EmblemRarity.Legendary) {
		args.gemLegendary.scale.setScalar(Math.max(1 - (Math.max(ct - 3000, 0) / 1000), 0));
	} else {
		args.gemLegendary.scale.setScalar(1);
	}

	// Fabled.
	const fabledDistance = rarity && rarity === EmblemRarity.Fabled ? chosenDistance : 12;
	args.gemFabled.position.x = Math.sin(((args.tt + args.ot) / 1000) + 4 * Math.PI * 2 / 5) * fabledDistance;
	args.gemFabled.position.y = Math.cos(((args.tt + args.ot) / 1000) + 4 * Math.PI * 2 / 5) * fabledDistance;
	args.gemFabled.rotation.x = Math.sin((args.tt / 3000) + 5 * Math.PI * 2 / 5) * Math.PI * 2;
	args.gemFabled.rotation.y = Math.cos((args.tt / 3000) + 5 * Math.PI * 2 / 5) * Math.PI * 2;

	if (rarity && rarity !== EmblemRarity.Fabled) {
		args.gemFabled.scale.setScalar(1 - Math.min(ct / 1500, 1));
	} else if (rarity === EmblemRarity.Fabled) {
		args.gemFabled.scale.setScalar(Math.max(1 - (Math.max(ct - 3000, 0) / 1000), 0));
	} else {
		args.gemFabled.scale.setScalar(1);
	}

	args.renderer.render(args.scene, args.camera);
};

const getColorsForRarity = (rarity: EmblemRarity): string[] => {
	switch (rarity) {
	case EmblemRarity.Rare:
		return ["#EEEEEE", "#124AED", "#459AFF"];
	case EmblemRarity.Majestic:
		return ["#ED2A12", "#FF5A45", "#FFEEDD"];
	case EmblemRarity.Legendary:
		return ["#FFDD12", "#DD9945", "#FFEEDD", "#ED2A12"];
	case EmblemRarity.Fabled:
		return ["#5AAA3E", "#67AA4F", "#EEFFDD"];
	default:
		return ["#FFFFFF", "#CCCCCC", "#EEEEEE", "#DDDDDD", "#666666"];
	}
};

const getParticleCountForRarity = (rarity: EmblemRarity): number => {
	switch (rarity) {
	case EmblemRarity.Rare:
		return 200;
	case EmblemRarity.Majestic:
		return 250;
	case EmblemRarity.Legendary:
		return 300;
	case EmblemRarity.Fabled:
		return 600;
	default:
		return 100;
	}
};

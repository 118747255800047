import { useEffect, useState } from "react";
import styles from "./PageBrowse.module.scss";
import searchIcon from "@fyendalscollection/app/assets/search.svg";
import { InfiniteScroller } from "@fyendalscollection/app/lib/components/InfiniteScroller";
import { useQueryParams } from "@fyendalscollection/app/lib/useQueryParams";
import { Link, useSearchParams } from "react-router-dom";
import { useProductsSearch } from "@fyendalscollection/app/shared";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { Limit } from "@fyendalscollection/app/lib/components/Limit";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { LinkButton } from "@fyendalscollection/app/lib/components/LinkButton";
import { TextEntry } from "@fyendalscollection/app/lib/components/TextEntry";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { ProductImage } from "@fyendalscollection/app/lib/components/ProductImage";
import { SearchSyntaxModal } from "@fyendalscollection/app/features/products/components/SearchSyntaxModal";

export const PageBrowse = (): React.JSX.Element => {
	useTitle("Browse");

	const queryParams = useQueryParams();
	const queryFromURL = queryParams.get("q") || "";

	const [showHelpModal, setShowHelpModal] = useState(false);

	return (
		<Limit>
			<Breadcrumb crumbs={[
				{
					text: "Dashboard",
					link: "/dashboard"
				},
				{
					text: "Browse"
				}
			]} />

			<PageTitle
				title="Browse"
				subTitle="General product search and information"
				primaryActions={
					<LinkButton text="Help" onClick={() => setShowHelpModal(true)}/>
				} />

			<SearchSyntaxModal showModal={showHelpModal} closeModal={() => setShowHelpModal(false)} />

			<ProductSearchBar queryFromURL={queryFromURL} />
		</Limit>
	);
};

interface ProductSearchBarProps {
	queryFromURL: string;
}

const ProductSearchBar = (props: ProductSearchBarProps): React.JSX.Element => {
	const [searchQueryText, setSearchQueryText] = useState(props.queryFromURL);
	const [searchQuery, setSearchQuery] = useState("");
	const [, setSearchParams] = useSearchParams();

	useEffect(() => {
		setSearchQuery("");

		if (!searchQueryText) {
			return;
		}

		const timeout = setTimeout(() => setSearchQuery(searchQueryText), 500);
		return () => clearTimeout(timeout);
	}, [searchQueryText]);

	useEffect(() => {
		if (searchQuery) {
			setSearchParams({ q: searchQuery });
		}
	}, [searchQuery]);

	useEffect(() => {
		if (props.queryFromURL) {
			setSearchQueryText(props.queryFromURL);
		}
	}, [props.queryFromURL]);

	return (
		<div>
			<div className={styles.search}>
				<img src={searchIcon} />
				<div>
					<TextEntry
						placeholder="Search"
						value={searchQueryText}
						onChange={setSearchQueryText} />
				</div>
			</div>
			{
				searchQuery &&
				<ProductSearchResults
					searchQuery={searchQuery} />
			}

			{
				!searchQuery && !searchQueryText &&
				<ProductSearchResults
					searchQuery="s=hnt" />
			}
		</div>
	);
};

interface ProductSearchResultsProps {
	searchQuery: string;
}

const ProductSearchResults = (props: ProductSearchResultsProps): React.JSX.Element => {
	const productsSearch = useProductsSearch(props.searchQuery, true);

	if (productsSearch.isLoading) {
		return <></>;
	}

	if (productsSearch.isError) {
		return (
			<div>
				<ErrorDisplay problemResponse={productsSearch.error} />
			</div>
		);
	}

	if (!productsSearch.data) {
		return <></>;
	}

	const resultDivs = productsSearch.data.pages.flatMap(x => x.results).map(result => (
		<div className={styles.results} key={result.productId}>
			<Link to={`/browse/product/${result.productId}`}>
				<div className={styles.productContainer}>
					<div className={styles.productName}>{result.name}</div>
					<div className={styles.productNumber}>{result.number}</div>
					<ProductImage className={styles.productImage} image={result.imageUrl} />
				</div>
			</Link>
		</div>
	));

	if (resultDivs.length < 1) {
		return (
			<div className={styles.noResults}>
				No results found
			</div>
		);
	}

	return (
		<>
			<div className={styles.resultsContainer}>
				{resultDivs}
				<InfiniteScroller forQuery={productsSearch} />
			</div>
		</>
	);
};

import cog from "@fyendalscollection/app/assets/cog.svg";
import styles from "./CollectionCard.module.scss";
import { Link } from "react-router-dom";
import pinned from "@fyendalscollection/app/assets/pinned.svg";
import unPinned from "@fyendalscollection/app/assets/unpinned.svg";
import { collectionArts } from "@fyendalscollection/app/features/collections/CollectionArts";
import { Money, useCollectionUpdate } from "@fyendalscollection/app/shared";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { Padlocked } from "@fyendalscollection/app/lib/components/Padlocked";

export interface CollectionCardProps {
	id: string;
	title: string;
	description: string;
	marketValue: Money;
	gain: Money | undefined;
	onCogClick: () => void;
	invested: Money | undefined;
	linkTo: string;
	isValued: boolean;
	pinned: boolean;
	image: number;
}

const gradient = "linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 38.02%)";

export const CollectionCard = (props: CollectionCardProps): React.JSX.Element => {
	const collectionArt = collectionArts[props.image];
	const backgroundImageStyle = gradient + ", url('" + collectionArt + "')";

	const updateCollection = useCollectionUpdate();

	const classNames = [styles.pin];
	if (!props.pinned) {
		classNames.push(styles.transparent);
	}

	return (
		<Loadable loading={updateCollection.isPending}>
			<div className={styles.collectionCard}>
				<img className={classNames.join(" ")} src={props.pinned ? pinned : unPinned} alt="favourites pin" onClick={() => updateCollection.mutate(
					{
						requestModel: {
							name: props.title,
							valued: props.isValued,
							description: props.description,
							pinned: !props.pinned,
							image: props.image
						},
						id: props.id
					}
				)} />
				<img className={styles.cog} src={cog} alt="Collection Settings" onClick={props.onCogClick} />
				<div className={styles.details}>
					<Link to={props.linkTo}>
						<div className={styles.bannerImage} style={{ backgroundImage: backgroundImageStyle }}></div>
						<div className={styles.title}>{props.title}</div>
						<div className={styles.description}>{props.description}</div>
					</Link>
					<div className={styles.stats}>
						<div>
							<div className={styles.statsTitle}>Market Value</div>
							<PrettyMoney className={styles.moneyValue} money={props.marketValue} mode={MoneyDisplayMode.Standard} />
						</div>
						<div>
							<div className={styles.statsTitle}>Gain</div>
							<Padlocked on={props.gain} element={gain => <PrettyMoney className={styles.moneyValue} money={gain} mode={MoneyDisplayMode.DeltaPercentage} originalValue={props.invested?.value || 0}/>} />
						</div>
					</div>
				</div>
			</div>
		</Loadable>
	);
};

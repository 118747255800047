import { Link } from "react-router-dom";
import styles from "./ContainerListItem.module.scss";

export type ContainerListItemProps = {
	children?: React.ReactNode;
	image?: string;
	imageSize?: ImageSize;
	imageHover?: boolean;
	link?: string;
	disabled?: boolean;
} & ({
	title: React.ReactNode;
	subtitle?: React.ReactNode;
} | {
	title?: React.ReactNode;
	subtitle: React.ReactNode;
});

export enum ImageSize {
	Standard,
	Small,
}

export const ContainerListItem = ({ children, image, imageSize = ImageSize.Standard, imageHover, link, disabled, title, subtitle }: ContainerListItemProps): React.JSX.Element => {
	const imageClassNames = getClassNameForImageSize(imageSize, imageHover);
	const listItemContainerStyles = [styles.listItemContainer];

	if (disabled) {
		listItemContainerStyles.push(styles.disabled);
	}

	const listItemDisplay = (
		<div className={styles.listItemDisplay}>
			{image && <div className={styles.imageHolder}><img className={imageClassNames} src={image}/></div> }
			<div className={styles.text}>
				{title && <div className={styles.title}>{title}</div>}
				{subtitle && <div className={styles.subtitle}>{subtitle}</div>}
			</div>
		</div>
	);

	return (
		<div className={listItemContainerStyles.join(" ")}>
			{link ? <Link to={link}>{listItemDisplay}</Link> : <>{listItemDisplay}</>}

			{children}
		</div>
	);
};

const getClassNameForImageSize = (imageSize: ImageSize, imageHover?: boolean) => {
	const classNames = imageHover ? [styles.imageHover] : [];

	switch (imageSize) {
	case ImageSize.Small:
		classNames.push(styles.small);
		break;
	default:
		classNames.push(styles.standard);
		break;
	}

	return classNames.join(" ");
};

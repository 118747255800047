import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ModalNormaliseUnitAmountOperation.module.scss";
import { Currency, useOperationNormaliseUnitAmount, usePreferredCurrency } from "@fyendalscollection/app/shared";
import { CurrencySelector } from "@fyendalscollection/app/lib/components/CurrencySelector";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";
import { Paragraph } from "@fyendalscollection/app/lib/components/Paragraph";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { NumericEntry } from "@fyendalscollection/app/lib/components/NumericEntry";

export interface ModalNormaliseUnitAmountOperationProps {
	visible: boolean;
	onCancel: () => void;
	collectionId: string;
}

export const ModalNormaliseUnitAmountOperation = ({ visible, onCancel, collectionId }: ModalNormaliseUnitAmountOperationProps): React.JSX.Element => {
	const [normalisedUnitAmount, setNormalisedUnitAmount] = useState(0);
	const navigate = useNavigate();

	const normaliseOperation = useOperationNormaliseUnitAmount();
	const preferredCurrency = usePreferredCurrency();

	const [currency, setCurrency] = useState<Currency>(preferredCurrency.data?.currency ?? Currency.USD);

	useEffect(() => {
		if (normaliseOperation.isSuccess) {
			setNormalisedUnitAmount(0);
			onCancel();
			navigate("..");
		}
	}, [normaliseOperation.isSuccess]);

	const performNormaliseUnitAmountOperation = () => {
		if (!collectionId || !normalisedUnitAmount) {
			return;
		}
		normaliseOperation.mutate(
			{
				requestModel: {
					normalisedUnitAmount: {
						value: normalisedUnitAmount,
						currency: currency
					}
				},
				collectionId: collectionId
			}
		);
	};

	return (
		<Modal visible={visible} onCancel={onCancel}>
			<Loadable loading={normaliseOperation.isPending}>
				<TitleSecondary text="Normalise Unit Amount" />

				<Paragraph>
					Enter the unit amount you would like to set for all your transactions.
				</Paragraph>

				<Container>
					<ContainerSegment>
						<div className={styles.flex}>
							<NumericEntry
								value={normalisedUnitAmount}
								onChange={setNormalisedUnitAmount}
								maxDecimalPlaces={2}
								minValue={0}
								maxValue={100000} />
							<CurrencySelector value={currency} onChange={setCurrency} />
						</div>
					</ContainerSegment>
				</Container>

				{
					normaliseOperation.isError && (
						<Container>
							<ContainerSegment>
								<ErrorDisplay problemResponse={normaliseOperation.error}/>
							</ContainerSegment>
						</Container>
					)
				}

				<GreenButtonSet buttons={[
					{
						text: "Normalise",
						onClick: performNormaliseUnitAmountOperation
					},
					{
						text: "Cancel",
						onClick: onCancel
					}
				]} />
			</Loadable>
		</Modal>
	);
};

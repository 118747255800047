import { CollectionPerformanceDeltasView, CollectionsSortOrder, Currency, EvProductType, EvSort, EvStep, ExcludedEvCategoriesType, PerformanceDeltaSet, PerformanceDeltaSort, PerformanceDeltaStep, PriceSource, ProgressSummariesView, ProgressSummarySortOrder, PublicTransactionSummariesSortOrder, PublicTransactionSummariesView, TransactionDetailsSortOrder, TransactionSummariesSortOrder, TransactionSummariesView, OfficialDecksSortOrder } from "../domain";
import { OfficialDecksFormatFilter } from "../domain/OfficialDecksFormatFilter";
import { StorageService, useStorage } from "../storage";
import { createGlobalState } from "./createGlobalState";

export interface UserPreferencesState {
	collectionsSortOrder: CollectionsSortOrder;
	transactionSummariesSortOrder: TransactionSummariesSortOrder;
	transactionSummariesView: TransactionSummariesView;
	collectionPerformanceDeltasView: CollectionPerformanceDeltasView;
	showSoldItems: boolean;
	performanceDeltaStep: PerformanceDeltaStep;
	performanceDeltaMinimum: number;
	performanceDeltaSort: PerformanceDeltaSort;
	performanceDeltaSets: PerformanceDeltaSet[];
	evStep: EvStep;
	evSort: EvSort;
	evProductType: EvProductType | null;
	graphDays: number;
	excludedEvCategories: ExcludedEvCategoriesType;
	publicTransactionSummariesSortOrder: PublicTransactionSummariesSortOrder;
	publicTransactionSummariesView: PublicTransactionSummariesView;
	publicCollectionCurrency: Currency | null;
	publicCollectionPriceSource: PriceSource | null;
	transactionDetailsSortOrder: TransactionDetailsSortOrder;
	progressSummarySortOrder: ProgressSummarySortOrder;
	includeCompleted: boolean;
	progressSummariesView: ProgressSummariesView;
	officialDecksSortOrder: OfficialDecksSortOrder;
	officialDecksFormatFilter: OfficialDecksFormatFilter | null;
	officialDecksHeroFilter: string | null;
	closeSearchPanelAfterSelect: boolean;
}

type UserPreferencesAction = {
	type: UserPreferencesActionType.SetCollectionsSortOrder;
	collectionsSortOrder: CollectionsSortOrder;
} | {
	type: UserPreferencesActionType.SetTransactionSummariesSortOrder;
	transactionSummariesSortOrder: TransactionSummariesSortOrder;
} | {
	type: UserPreferencesActionType.SetTransactionSummariesView;
	transactionSummariesView: TransactionSummariesView;
} | {
	type: UserPreferencesActionType.SetCollectionPerformanceDeltasView;
	collectionPerformanceDeltasView: CollectionPerformanceDeltasView;
} | {
	type: UserPreferencesActionType.SetShowSoldItems;
	showSoldItems: boolean;
} | {
	type: UserPreferencesActionType.SetPerformanceDeltaStep;
	step: PerformanceDeltaStep;
} | {
	type: UserPreferencesActionType.SetPerformanceDeltaMinimum;
	minimum: number;
} | {
	type: UserPreferencesActionType.SetPerformanceDeltaSort;
	sort: PerformanceDeltaSort;
} | {
	type: UserPreferencesActionType.SetPerformanceDeltaSets;
	sets: PerformanceDeltaSet[];
} | {
	type: UserPreferencesActionType.SetEvStep;
	step: EvStep;
} | {
	type: UserPreferencesActionType.SetEvSort;
	sort: EvSort;
} | {
	type: UserPreferencesActionType.SetEvProductType;
	productType: EvProductType | null;
} | {
	type: UserPreferencesActionType.SetGraphDays;
	graphDays: number;
} | {
	type: UserPreferencesActionType.SetExcludedEvCategories;
	excludedEvCategories: ExcludedEvCategoriesType;
} | {
	type: UserPreferencesActionType.SetPublicTransactionSummariesSortOrder;
	publicTransactionSummariesSortOrder: PublicTransactionSummariesSortOrder;
} | {
	type: UserPreferencesActionType.SetPublicTransactionSummariesView;
	publicTransactionSummariesView: PublicTransactionSummariesView;
} | {
	type: UserPreferencesActionType.SetPublicCollectionCurrency;
	publicCollectionCurrency: Currency;
} | {
	type: UserPreferencesActionType.SetPublicCollectionPriceSource;
	publicCollectionPriceSource: PriceSource;
} | {
	type: UserPreferencesActionType.SetTransactionDetailsSortOrder;
	transactionDetailsSortOrder: TransactionDetailsSortOrder;
} | {
	type: UserPreferencesActionType.SetProgressSummarySortOrder;
	progressSummarySortOrder: ProgressSummarySortOrder;
} | {
	type: UserPreferencesActionType.SetIncludeCompleted;
	includeCompleted: boolean;
} | {
	type: UserPreferencesActionType.SetProgressSummariesView;
	progressSummariesView: ProgressSummariesView;
} | {
	type: UserPreferencesActionType.SetOfficialDecksSortOrder;
	officialDecksSortOrder: OfficialDecksSortOrder;
} | {
	type: UserPreferencesActionType.SetOfficialDecksFormatFilter;
	officialDecksFormatFilter: OfficialDecksFormatFilter | null;
} | {
	type: UserPreferencesActionType.SetOfficialDecksHeroFilter;
	officialDecksHeroFilter: string | null;
} | {
	type: UserPreferencesActionType.SetCloseSearchPanelAfterSelect;
	closeSearchPanelAfterSelect: boolean;
};

enum UserPreferencesActionType {
	SetCollectionsSortOrder,
	SetTransactionSummariesSortOrder,
	SetTransactionSummariesView,
	SetCollectionPerformanceDeltasView,
	SetShowSoldItems,
	SetPerformanceDeltaStep,
	SetPerformanceDeltaMinimum,
	SetPerformanceDeltaSort,
	SetPerformanceDeltaSets,
	SetEvStep,
	SetEvSort,
	SetEvProductType,
	SetGraphDays,
	SetExcludedEvCategories,
	SetPublicTransactionSummariesSortOrder,
	SetPublicTransactionSummariesView,
	SetPublicCollectionCurrency,
	SetPublicCollectionPriceSource,
	SetTransactionDetailsSortOrder,
	SetProgressSummarySortOrder,
	SetIncludeCompleted,
	SetProgressSummariesView,
	SetOfficialDecksSortOrder,
	SetOfficialDecksFormatFilter,
	SetOfficialDecksHeroFilter,
	SetCloseSearchPanelAfterSelect
}

const getInitialState = (storage: StorageService): UserPreferencesState => {
	const collectionsSortOrder = storage.getPersistent<CollectionsSortOrder>("collectionsSortOrder");
	const transactionSummariesSortOrder = storage.getPersistent<TransactionSummariesSortOrder>("transactionSummariesSortOrder");
	const transactionSummariesView = storage.getPersistent<TransactionSummariesView>("transactionSummariesView");
	const collectionPerformanceDeltasView = storage.getPersistent<CollectionPerformanceDeltasView>("collectionPerformanceDeltasView");
	const showSoldItems = storage.getPersistent<boolean>("showSoldItems");
	const performanceDeltaStep = storage.getPersistent<PerformanceDeltaStep>("performanceDeltaStep");
	const performanceDeltaMinimum = storage.getPersistent<number>("performanceDeltaMinimum");
	const performanceDeltaSort = storage.getPersistent<PerformanceDeltaSort>("performanceDeltaSort");
	const performanceDeltaSets = storage.getPersistent<PerformanceDeltaSet[]>("performanceDeltaSets");
	const evStep = storage.getPersistent<EvStep>("evStep");
	const evSort = storage.getPersistent<EvSort>("evSort");
	const evProductType = storage.getPersistent<EvProductType>("evProductType");
	const graphDays = storage.getPersistent<number>("graphDays");
	const evCategories = storage.getPersistent<ExcludedEvCategoriesType>("excludedEvCategories");
	const publicTransactionSummariesSortOrder = storage.getPersistent<PublicTransactionSummariesSortOrder>("publicTransactionSummariesSortOrder");
	const publicTransactionSummariesView = storage.getPersistent<PublicTransactionSummariesView>("publicTransactionSummariesView");
	const publicCollectionCurrency = storage.getPersistent<Currency>("publicCollectionCurrency");
	const publicCollectionPriceSource = storage.getPersistent<PriceSource>("publicCollectionPriceSource");
	const transactionDetailsSortOrder = storage.getPersistent<TransactionDetailsSortOrder>("transactionDetailsSortOrder");
	const progressSummarySortOrder = storage.getPersistent<ProgressSummarySortOrder>("progressSummarySortOrder");
	const includeCompleted = storage.getPersistent<boolean>("includeCompleted");
	const progressSummariesView = storage.getPersistent<ProgressSummariesView>("progressSummariesView");
	const officialDecksSortOrder = storage.getPersistent<OfficialDecksSortOrder>("officialDecksSortOrder");
	const officialDecksFormatFilter = storage.getPersistent<OfficialDecksFormatFilter | null>("officialDecksFormatFilter");
	const officialDecksHeroFilter = storage.getPersistent<string | null>("officialDecksHeroFilter");
	const closeSearchPanelAfterSelect = storage.getPersistent<boolean>("closeSearchPanelAfterSelect");

	return {
		collectionsSortOrder: collectionsSortOrder || CollectionsSortOrder.Chronological,
		transactionSummariesSortOrder: transactionSummariesSortOrder || TransactionSummariesSortOrder.Alphabetical,
		transactionSummariesView: transactionSummariesView || TransactionSummariesView.Gallery,
		collectionPerformanceDeltasView: collectionPerformanceDeltasView || CollectionPerformanceDeltasView.List,
		showSoldItems: showSoldItems || false,
		performanceDeltaStep: performanceDeltaStep || PerformanceDeltaStep.Step24Hours,
		performanceDeltaMinimum: performanceDeltaMinimum || 0,
		performanceDeltaSort: performanceDeltaSort || PerformanceDeltaSort.PercentageGain,
		performanceDeltaSets: performanceDeltaSets || [],
		evStep: evStep || EvStep.Step24Hours,
		evSort: evSort || EvSort.OldestReleaseDate,
		evProductType: !evProductType ? null : evProductType,
		graphDays: graphDays || 31,
		excludedEvCategories: evCategories || {},
		publicTransactionSummariesSortOrder: publicTransactionSummariesSortOrder|| PublicTransactionSummariesSortOrder.Alphabetical,
		publicTransactionSummariesView: publicTransactionSummariesView || PublicTransactionSummariesView.Gallery,
		publicCollectionCurrency: !publicCollectionCurrency ? null : publicCollectionCurrency,
		publicCollectionPriceSource: !publicCollectionPriceSource ? null : publicCollectionPriceSource,
		transactionDetailsSortOrder: transactionDetailsSortOrder || TransactionDetailsSortOrder.Chronological,
		progressSummarySortOrder: progressSummarySortOrder || ProgressSummarySortOrder.Alphabetical,
		includeCompleted: includeCompleted || false,
		progressSummariesView: progressSummariesView || ProgressSummariesView.Gallery,
		officialDecksSortOrder: officialDecksSortOrder || OfficialDecksSortOrder.Date,
		officialDecksFormatFilter: officialDecksFormatFilter || null,
		officialDecksHeroFilter: officialDecksHeroFilter || null,
		closeSearchPanelAfterSelect: closeSearchPanelAfterSelect || false
	};
};

const reducer = (state: UserPreferencesState, action: UserPreferencesAction): UserPreferencesState => {
	switch(action.type) {
	case UserPreferencesActionType.SetCollectionsSortOrder:
		return {
			...state,
			collectionsSortOrder: action.collectionsSortOrder
		};
	case UserPreferencesActionType.SetTransactionSummariesSortOrder:
		return {
			...state,
			transactionSummariesSortOrder: action.transactionSummariesSortOrder
		};
	case UserPreferencesActionType.SetTransactionSummariesView:
		return {
			...state,
			transactionSummariesView: action.transactionSummariesView
		};
	case UserPreferencesActionType.SetCollectionPerformanceDeltasView:
		return {
			...state,
			collectionPerformanceDeltasView: action.collectionPerformanceDeltasView
		};
	case UserPreferencesActionType.SetShowSoldItems:
		return {
			...state,
			showSoldItems: action.showSoldItems
		};
	case UserPreferencesActionType.SetPerformanceDeltaStep:
		return {
			...state,
			performanceDeltaStep: action.step
		};
	case UserPreferencesActionType.SetPerformanceDeltaMinimum:
		return {
			...state,
			performanceDeltaMinimum: action.minimum
		};
	case UserPreferencesActionType.SetPerformanceDeltaSort:
		return {
			...state,
			performanceDeltaSort: action.sort
		};
	case UserPreferencesActionType.SetPerformanceDeltaSets:
		return {
			...state,
			performanceDeltaSets: action.sets
		};
	case UserPreferencesActionType.SetEvStep:
		return {
			...state,
			evStep: action.step
		};
	case UserPreferencesActionType.SetEvSort:
		return {
			...state,
			evSort: action.sort
		};
	case UserPreferencesActionType.SetEvProductType:
		return {
			...state,
			evProductType: action.productType
		};
	case UserPreferencesActionType.SetGraphDays:
		return {
			...state,
			graphDays: action.graphDays
		};

	case UserPreferencesActionType.SetExcludedEvCategories:
		return {
			...state,
			excludedEvCategories: action.excludedEvCategories
		};

	case UserPreferencesActionType.SetPublicTransactionSummariesSortOrder:
		return {
			...state,
			publicTransactionSummariesSortOrder: action.publicTransactionSummariesSortOrder
		};

	case UserPreferencesActionType.SetPublicTransactionSummariesView:
		return {
			...state,
			publicTransactionSummariesView: action.publicTransactionSummariesView
		};

	case UserPreferencesActionType.SetPublicCollectionCurrency:
		return {
			...state,
			publicCollectionCurrency: action.publicCollectionCurrency
		};

	case UserPreferencesActionType.SetPublicCollectionPriceSource:
		return {
			...state,
			publicCollectionPriceSource: action.publicCollectionPriceSource
		};

	case UserPreferencesActionType.SetTransactionDetailsSortOrder:
		return {
			...state,
			transactionDetailsSortOrder: action.transactionDetailsSortOrder
		};

	case UserPreferencesActionType.SetProgressSummarySortOrder:
		return {
			...state,
			progressSummarySortOrder: action.progressSummarySortOrder
		};

	case UserPreferencesActionType.SetIncludeCompleted:
		return {
			...state,
			includeCompleted: action.includeCompleted
		};

	case UserPreferencesActionType.SetProgressSummariesView:
		return {
			...state,
			progressSummariesView: action.progressSummariesView
		};

	case UserPreferencesActionType.SetOfficialDecksSortOrder:
		return {
			...state,
			officialDecksSortOrder: action.officialDecksSortOrder
		};

	case UserPreferencesActionType.SetOfficialDecksFormatFilter:
		return {
			...state,
			officialDecksFormatFilter: action.officialDecksFormatFilter
		};

	case UserPreferencesActionType.SetOfficialDecksHeroFilter:
		return {
			...state,
			officialDecksHeroFilter: action.officialDecksHeroFilter
		};

	case UserPreferencesActionType.SetCloseSearchPanelAfterSelect:
		return {
			...state,
			closeSearchPanelAfterSelect: action.closeSearchPanelAfterSelect
		};
	}
};

const [UserPreferencesStateProvider, useUserPreferencesStateReducer] = createGlobalState(reducer, getInitialState);
export { UserPreferencesStateProvider };

export const useUserPreferencesState = () => {
	const storage = useStorage();
	const [state, dispatch] = useUserPreferencesStateReducer();

	const dispatchSetCollectionsSortOrder = (collectionsSortOrder: CollectionsSortOrder) => {
		dispatch({
			type: UserPreferencesActionType.SetCollectionsSortOrder,
			collectionsSortOrder
		});

		storage.setPersistent("collectionsSortOrder", collectionsSortOrder);
	};

	const dispatchSetTransactionSummariesSortOrder = (transactionSummariesSortOrder: TransactionSummariesSortOrder) => {
		dispatch({
			type: UserPreferencesActionType.SetTransactionSummariesSortOrder,
			transactionSummariesSortOrder
		});

		storage.setPersistent("transactionSummariesSortOrder", transactionSummariesSortOrder);
	};

	const dispatchSetTransactionSummariesView = (transactionSummariesView: TransactionSummariesView) => {
		dispatch({
			type: UserPreferencesActionType.SetTransactionSummariesView,
			transactionSummariesView
		});

		storage.setPersistent("transactionSummariesView", transactionSummariesView);
	};

	const dispatchSetCollectionPerformanceDeltasView = (collectionPerformanceDeltasView: CollectionPerformanceDeltasView) => {
		dispatch({
			type: UserPreferencesActionType.SetCollectionPerformanceDeltasView,
			collectionPerformanceDeltasView
		});

		storage.setPersistent("collectionPerformanceDeltasView", collectionPerformanceDeltasView);
	};

	const dispatchSetShowSoldItems = (showSoldItems: boolean) => {
		dispatch({
			type: UserPreferencesActionType.SetShowSoldItems,
			showSoldItems
		});

		storage.setPersistent("showSoldItems", showSoldItems);
	};

	const dispatchSetPerformanceDeltaStep = (step: PerformanceDeltaStep) => {
		dispatch({
			type: UserPreferencesActionType.SetPerformanceDeltaStep,
			step
		});

		storage.setPersistent("performanceDeltaStep", step);
	};

	const dispatchSetPerformanceDeltaMinimum = (minimum: number) => {
		dispatch({
			type: UserPreferencesActionType.SetPerformanceDeltaMinimum,
			minimum
		});

		storage.setPersistent("performanceDeltaMinimum", minimum);
	};

	const dispatchSetPerformanceDeltaSort = (sort: PerformanceDeltaSort) => {
		dispatch({
			type: UserPreferencesActionType.SetPerformanceDeltaSort,
			sort
		});

		storage.setPersistent("performanceDeltaSort", sort);
	};

	const dispatchSetPerformanceDeltaSets = (sets: PerformanceDeltaSet[]) => {
		dispatch({
			type: UserPreferencesActionType.SetPerformanceDeltaSets,
			sets
		});

		storage.setPersistent("performanceDeltaSets", sets);
	};

	const dispatchSetEvStep = (step: EvStep) => {
		dispatch({
			type: UserPreferencesActionType.SetEvStep,
			step
		});

		storage.setPersistent("evStep", step);
	};

	const dispatchSetEvSort = (sort: EvSort) => {
		dispatch({
			type: UserPreferencesActionType.SetEvSort,
			sort
		});

		storage.setPersistent("evSort", sort);
	};

	const dispatchSetEvProductType = (productType: EvProductType | null) => {
		dispatch({
			type: UserPreferencesActionType.SetEvProductType,
			productType
		});

		storage.setPersistent("evProductType", productType);
	};

	const dispatchSetGraphDays = (graphDays: number) => {
		dispatch({
			type: UserPreferencesActionType.SetGraphDays,
			graphDays
		});

		storage.setPersistent("graphDays", graphDays);
	};

	const dispatchSetExcludedEvCategories = (excludedEvCategories: ExcludedEvCategoriesType) => {
		dispatch({
			type: UserPreferencesActionType.SetExcludedEvCategories,
			excludedEvCategories
		});

		storage.setPersistent("excludedEvCategories", excludedEvCategories);
	};

	const dispatchSetPublicTransactionSummariesSortOrder = (publicTransactionSummariesSortOrder: PublicTransactionSummariesSortOrder) => {
		dispatch({
			type: UserPreferencesActionType.SetPublicTransactionSummariesSortOrder,
			publicTransactionSummariesSortOrder
		});

		storage.setPersistent("publicTransactionSummariesSortOrder", publicTransactionSummariesSortOrder);
	};

	const dispatchSetPublicTransactionSummariesView = (publicTransactionSummariesView: PublicTransactionSummariesView) => {
		dispatch({
			type: UserPreferencesActionType.SetPublicTransactionSummariesView,
			publicTransactionSummariesView
		});

		storage.setPersistent("publicTransactionSummariesSortOrder", publicTransactionSummariesView);
	};

	const dispatchSetPublicCollectionCurrency = (publicCollectionCurrency: Currency) => {
		dispatch({
			type: UserPreferencesActionType.SetPublicCollectionCurrency,
			publicCollectionCurrency
		});

		storage.setPersistent("publicCollectionCurrency", publicCollectionCurrency);
	};

	const dispatchSetPublicCollectionPriceSource = (publicCollectionPriceSource: PriceSource) => {
		dispatch({
			type: UserPreferencesActionType.SetPublicCollectionPriceSource,
			publicCollectionPriceSource
		});

		storage.setPersistent("publicCollectionPriceSource", publicCollectionPriceSource);
	};

	const dispatchSetTransactionDetailsSortOrder = (transactionDetailsSortOrder: TransactionDetailsSortOrder) => {
		dispatch({
			type: UserPreferencesActionType.SetTransactionDetailsSortOrder,
			transactionDetailsSortOrder
		});

		storage.setPersistent("transactionDetailsSortOrder", transactionDetailsSortOrder);
	};

	const dispatchSetProgressSummarySortOrder = (progressSummarySortOrder: ProgressSummarySortOrder) => {
		dispatch({
			type: UserPreferencesActionType.SetProgressSummarySortOrder,
			progressSummarySortOrder
		});

		storage.setPersistent("progressSummarySortOrder", progressSummarySortOrder);
	};

	const dispatchSetIncludeCompleted = (includeCompleted: boolean) => {
		dispatch({
			type: UserPreferencesActionType.SetIncludeCompleted,
			includeCompleted
		});

		storage.setPersistent("includeCompleted", includeCompleted);
	};

	const dispatchSetProgressSummariesView = (progressSummariesView: ProgressSummariesView) => {
		dispatch({
			type: UserPreferencesActionType.SetProgressSummariesView,
			progressSummariesView
		});

		storage.setPersistent("progressSummariesView", progressSummariesView);
	};

	const dispatchSetOfficialDecksSortOrder = (officialDecksSortOrder: OfficialDecksSortOrder) => {
		dispatch({
			type: UserPreferencesActionType.SetOfficialDecksSortOrder,
			officialDecksSortOrder
		});

		storage.setPersistent("officialDecksSortOrder", officialDecksSortOrder);
	};

	const dispatchSetOfficialDecksFormatFilter = (officialDecksFormatFilter: OfficialDecksFormatFilter | null) => {
		dispatch({
			type: UserPreferencesActionType.SetOfficialDecksFormatFilter,
			officialDecksFormatFilter
		});

		storage.setPersistent("officialDecksFormatFilter", officialDecksFormatFilter);
	};

	const dispatchSetOfficialDecksHeroFilter = (officialDecksHeroFilter: string | null) => {
		dispatch({
			type: UserPreferencesActionType.SetOfficialDecksHeroFilter,
			officialDecksHeroFilter
		});
	};

	const dispatchSetCloseSearchPanelAfterSelect = (closeSearchPanelAfterSelect: boolean) => {
		dispatch({
			type: UserPreferencesActionType.SetCloseSearchPanelAfterSelect,
			closeSearchPanelAfterSelect
		});
	};

	return {
		state,
		dispatchSetCollectionsSortOrder,
		dispatchSetTransactionSummariesSortOrder,
		dispatchSetTransactionSummariesView,
		dispatchSetCollectionPerformanceDeltasView,
		dispatchSetShowSoldItems,
		dispatchSetPerformanceDeltaStep,
		dispatchSetPerformanceDeltaMinimum,
		dispatchSetPerformanceDeltaSort,
		dispatchSetPerformanceDeltaSets,
		dispatchSetEvStep,
		dispatchSetEvSort,
		dispatchSetEvProductType,
		dispatchSetGraphDays,
		dispatchSetExcludedEvCategories,
		dispatchSetPublicTransactionSummariesSortOrder,
		dispatchSetPublicTransactionSummariesView,
		dispatchSetPublicCollectionCurrency,
		dispatchSetPublicCollectionPriceSource,
		dispatchSetTransactionDetailsSortOrder,
		dispatchSetProgressSummarySortOrder,
		dispatchSetIncludeCompleted,
		dispatchSetProgressSummariesView,
		dispatchSetOfficialDecksSortOrder,
		dispatchSetOfficialDecksFormatFilter,
		dispatchSetOfficialDecksHeroFilter,
		dispatchSetCloseSearchPanelAfterSelect
	};
};

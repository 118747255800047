import { useRef, useState } from "react";
import styles from "./FileSelector.module.scss";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";

export interface FileUploader {
	loading?: boolean;
	file: File | null;
	onFileSelected: (file: File) => void;
	accept?: string;
}

export const FileSelector = (props: FileUploader): React.JSX.Element => {
	const {
		loading = false,
		file,
		onFileSelected,
		accept
	} = props;

	const inputRef = useRef<HTMLInputElement | null>(null);
	const [hovering, setHovering] = useState(false);
	const [error, setError] = useState<string | null>(null);

	const onClick = () => {
		if (!inputRef.current) {
			return;
		}

		inputRef.current.click();
	};

	const onDragOver = (e: React.DragEvent<HTMLDivElement>) => e.preventDefault();
	const onDragEnter = () => setHovering(true);
	const onDragExit = () => setHovering(false);

	const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();

		const file = e.dataTransfer.files.item(0);
		if (!file || (accept && accept.indexOf(file.type) === -1)) {
			setHovering(false);
			setError("This file type cannot be used here.  Try again.");
			return;
		}

		onFileSelected(e.dataTransfer.files[0]);
	};

	const onChange = () => {
		if (!inputRef.current || !inputRef.current.files || inputRef.current.files.length < 1) {
			return;
		}

		onFileSelected(inputRef.current.files[0]);
	};

	const classNames = [ styles.fileUploader ];
	if (hovering) {
		classNames.push(styles.hovering);
	}
	if (file) {
		classNames.push(styles.hasFile);
	}

	return (
		<Loadable loading={loading}>
			<div
				className={classNames.join(" ")}
				onClick={onClick}
				onDragOver={onDragOver}
				onDragEnter={onDragEnter}
				onDragExit={onDragExit}
				onDrop={onDrop}>

				{
					file
						? file.name
						: error
							? error
							: hovering
								? "Drop to select this file"
								: "Drag and drop or click here to upload"
				}

				<input ref={inputRef} onChange={onChange} type="file" multiple={false} accept={accept} />
			</div>
		</Loadable>
	);
};

import { QueryClient, QueryKey } from "@tanstack/react-query";
import { paginatedUpdate } from "./paginatedUpdate";

export const updateQueryKeyIfExists = <T>(queryClient: QueryClient, queryKey: QueryKey, predicate: (x: T) => boolean, data: T) => {
	if (!queryClient.getQueryData(queryKey)) {
		return;
	}

	queryClient.setQueryData(queryKey, paginatedUpdate(data, predicate));
};

import styles from "./NumericEntry.module.scss";
import minusDark from "@fyendalscollection/app/assets/minus-dark.svg";
import minusLight from "@fyendalscollection/app/assets/minus-light.svg";
import plusDark from "@fyendalscollection/app/assets/plus-dark.svg";
import plusLight from "@fyendalscollection/app/assets/plus-light.svg";
import { ThemedImage } from "@fyendalscollection/app/lib/components/ThemedImage";
import { useEffect, useState } from "react";

export interface NumericEntryProps {
	value: number;
	onChange: (value: number) => void;
	maxDecimalPlaces?: number;
	minValue?: number;
	maxValue?: number;
}

export const NumericEntry = (props: NumericEntryProps): React.JSX.Element => {
	const {
		value,
		onChange,
		maxDecimalPlaces = null,
		minValue = null,
		maxValue = null
	} = props;

	const [text, setText] = useState(formatValue(value, maxDecimalPlaces));

	const minusButtonDisabled = minValue !== null && (value - 1) < minValue;
	const plusButtonDisabled = maxValue !== null && (value + 1) > maxValue;

	useEffect(() => {
		setText(formatValue(value, maxDecimalPlaces));
	}, [value]);

	const onBlur = () => {
		const newValue = parseText(text, maxDecimalPlaces);
		if (newValue === null || (minValue !== null && newValue < minValue) || (maxValue !== null && newValue > maxValue)) {
			setText(formatValue(value, maxDecimalPlaces));
			return;
		}

		onChange(newValue);
		setText(formatValue(newValue, maxDecimalPlaces));
	};

	const onMinus = () => {
		if (minusButtonDisabled) {
			return;
		}

		onChange(value - 1);
		setText(formatValue(value - 1, maxDecimalPlaces));
	};

	const onPlus = () => {
		if (plusButtonDisabled) {
			return;
		}

		onChange(value + 1);
		setText(formatValue(value + 1, maxDecimalPlaces));
	};

	return (
		<div className={styles.numericEntry}>
			<div className={minusButtonDisabled ? styles.disabled : undefined} onClick={onMinus}>
				<ThemedImage srcLight={minusLight} srcDark={minusDark} />
			</div>

			<input
				size={1}
				type="text"
				inputMode="numeric"
				value={text}
				onBlur={onBlur}
				onChange={e => setText(e.target.value)} />

			<div className={plusButtonDisabled ? styles.disabled : undefined} onClick={onPlus}>
				<ThemedImage srcLight={plusLight} srcDark={plusDark} />
			</div>
		</div>
	);
};

const parseText = (text: string, maxDecimalPlaces: number | null): number | null => {
	const value = parseFloat(text);
	if (isNaN(value)) {
		return null;
	}

	if (maxDecimalPlaces !== null) {
		return roundTo(value, maxDecimalPlaces);
	}

	return value;
};

const formatValue = (value: number, maxDecimalPlaces: number | null): string => {
	if (maxDecimalPlaces === null) {
		return value.toString();
	}

	return value.toFixed(maxDecimalPlaces);
};

const roundTo = (value: number, decimalPlaces: number): number => {
	const factor = Math.pow(10, decimalPlaces);
	return Math.round(value * factor) / factor;
};

import { useState } from "react";
import dayjs from "dayjs";
import visa from "@fyendalscollection/app/assets/visa.svg";
import mastercard from "@fyendalscollection/app/assets/mastercard.svg";
import amex from "@fyendalscollection/app/assets/amex.svg";
import card from "@fyendalscollection/app/assets/card.svg";
import { usePaymentCardDelete, usePaymentCards } from "@fyendalscollection/app/shared";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ContainerListItem } from "@fyendalscollection/app/lib/components/ContainerListItem";
import { LinkButton } from "@fyendalscollection/app/lib/components/LinkButton";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { ModalConfirmation } from "@fyendalscollection/app/lib/components/ModalConfirmation";
import { ModalAddPaymentCard } from "@fyendalscollection/app/features/paymentCards/modals/ModalAddPaymentCard";

export const PagePaymentCards = (): React.JSX.Element => {
	useTitle("Payment Cards");

	const [paymentCardIdToDelete, setPaymentCardIdToDelete] = useState<string | null>(null);
	const [modalAddPaymentCardVisible, setModalAddPaymentCardVisible] = useState(false);

	const cards = usePaymentCards();
	const deleteCards = usePaymentCardDelete();

	const getProviderImage = (provider: string) => {
		switch (provider) {
		case "visa":
			return visa;
		case "mastercard":
			return mastercard;
		case "amex":
			return amex;
		default:
			return card;
		}
	};

	const cardList = (cards.data || []).map(x => (
		<ContainerSegment key={x.paymentCardId}>
			<ContainerListItem
				image={getProviderImage(x.provider)}
				title={`Card ending in ${x.last4}`}
				subtitle={x.expiry.valueOf() === 0 ? "●●/●●" : dayjs(x.expiry).format("MM/YY")}>

				<LinkButton text="Remove" onClick={() => setPaymentCardIdToDelete(x.paymentCardId)} />
			</ContainerListItem>
		</ContainerSegment>
	));

	return (
		<>
			<Loadable loading={cards.isLoading || deleteCards.isPending}>
				<Limit force={LimitWidth.Tiny}>
					{
						deleteCards.isError &&
						<Container>
							<ContainerSegment>
								<ErrorDisplay problemResponse={deleteCards.error}/>
							</ContainerSegment>
						</Container>
					}

					<Breadcrumb crumbs={[{
						text: "Settings",
						link: "/settings"
					},
					{
						text: "Payment Cards"
					}]}/>

					<PageTitle
						title="Payment Cards"
						subTitle="Add or remove payment cards"
						primaryActions={
							<GreenButtonSet buttons={[
								{
									text: "Add Card",
									onClick: () => setModalAddPaymentCardVisible(true)
								}
							]} />
						}/>

					<ModalConfirmation
						title="Remove Payment Card"
						description="Are you sure you want to remove this payment card?"
						visible={!!paymentCardIdToDelete}
						onConfirm={() => deleteCards.mutate(paymentCardIdToDelete || "")}
						onCancel={() => setPaymentCardIdToDelete(null)}/>

					<Container>
						{cards.isError && <ContainerSegment>
							<ErrorDisplay problemResponse={cards.error}/>
						</ContainerSegment>}
						{cardList}
					</Container>
				</Limit>
			</Loadable>

			<ModalAddPaymentCard visible={modalAddPaymentCardVisible} onCancel={() => setModalAddPaymentCardVisible(false)}/>
		</>
	);
};

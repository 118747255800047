import { Select, SelectProps } from "@fyendalscollection/app/lib/components/Select";
import styles from "./StyledSelect.module.scss";

export interface StyledSelectProps<T> extends SelectProps<T> {
	disabled?: boolean;
}

export const StyledSelect = <T,>(props: StyledSelectProps<T>): React.JSX.Element => {
	const classNames = [styles.styledSelect];
	if (props.disabled) {
		classNames.push(styles.disabled);
	}

	return (
		<div className={classNames.join(" ")}>
			<Select value={props.value} onChange={props.onChange} options={props.options} />
		</div>
	);
};

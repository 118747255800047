import { useMemo, useState } from "react";
import styles from "./HistoricalPositionsGraph.module.scss";
import { DataGroup, LineGraph } from "@fyendalscollection/app/lib/components/LineGraph";
import { HistoricalPositionModel } from "@fyendalscollection/app/shared";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { DaySelector } from "@fyendalscollection/app/lib/components/DaySelector";
import { LinkButton } from "@fyendalscollection/app/lib/components/LinkButton";
import { HelpModal } from "@fyendalscollection/app/features/collections/components/HelpModal";

export interface HistoricalPositionGraphProps {
	title?: string;
	historicalPositions?: HistoricalPositionModel[];
	loading: boolean;
	showHelp?: boolean;
}

export const HistoricalPositionsGraph = ({ title, historicalPositions, loading, showHelp }: HistoricalPositionGraphProps): React.JSX.Element => {
	const [showHelpModal, setShowHelpModal] = useState(false);

	const generateDataset = (data?: HistoricalPositionModel[]): Record<string, DataGroup> => {
		if (!data || data.length === 0) {
			return { };
		}

		return data.reduce((state: Record<string, DataGroup>, value: HistoricalPositionModel): Record<string, DataGroup> => {
			const cashPositionDataPoint = { x: new Date(value.date), y: value.cashPosition };
			const investedDataPoint = { x: new Date(value.date), y: value.invested };
			const gainDataPoint = { x: new Date(value.date), y: value.gain };
			const profitDataPoint = { x: new Date(value.date), y: value.profit };
			const marketValueDataPoint = { x: new Date(value.date), y: value.marketValue };

			if (!("Cash Position" in state)) {
				state["Cash Position"] = {
					id: "Cash Position",
					label: "Cash Position",
					data: [],
					color: "#E8CE17",
					premiumOnly: true
				};
			}

			if (!("Invested" in state)) {
				state["Invested"] = {
					id: "Invested",
					label: "Invested",
					data: [],
					color: "#3E90AA",
					premiumOnly: true
				};
			}

			if (!("Gain" in state)) {
				state["Gain"] = {
					id: "Gain",
					label: "Gain",
					data: [],
					color: "#5AAA3E",
					premiumOnly: true
				};
			}

			if (!("Profit" in state)) {
				state["Profit"] = {
					id: "Profit",
					label: "Profit",
					data: [],
					color: "#D74111",
					premiumOnly: true
				};
			}

			if (!("Market Value" in state)) {
				state["Market Value"] = {
					id: "Market Value",
					label: "Market Value",
					data: [],
					color: "#8E3EAA"
				};
			}

			if (value.cashPosition !== null) state["Cash Position"].data.push(cashPositionDataPoint);
			if (value.invested !== null) state["Invested"].data.push(investedDataPoint);
			if (value.gain !== null) state["Gain"].data.push(gainDataPoint);
			if (value.profit !== null) state["Profit"].data.push(profitDataPoint);
			if (value.marketValue !== null) state["Market Value"].data.push(marketValueDataPoint);

			return state;
		}, {});
	};

	const data = useMemo(() => generateDataset(historicalPositions), [historicalPositions]);
	return (
		<>
			<Loadable loading={loading}>
				{Object.keys(data).length > 0 &&
					<Container title={title}>
						<ContainerSegment>

							<div className={styles.graphLimiter}>
								<LineGraph
									data={[data["Cash Position"], data["Invested"], data["Market Value"], data["Profit"], data["Gain"]]}
									defaultSelected={["Invested", "Market Value"]}
								/>
							</div>

							<DaySelector>
								{showHelp &&
									<div>
										<LinkButton text="Help" onClick={() => setShowHelpModal(true)} />
									</div>
								}
							</DaySelector>
						</ContainerSegment>
					</Container>
				}
			</Loadable>
			<HelpModal showModal={showHelpModal} closeModal={() => setShowHelpModal(false)} />
		</>
	);
};

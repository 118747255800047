import {  useEffect, useState } from "react";
import styles from "./ModalCreateSubscription.module.scss";
import { usePaymentCards, useSubscriptionCreate } from "@fyendalscollection/app/shared";
import { PlanSelector, PlanDetails } from "@fyendalscollection/app/features/subscriptions/components/PlanSelector";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";
import { Paragraph } from "@fyendalscollection/app/lib/components/Paragraph";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { PaymentCardSelector } from "@fyendalscollection/app/lib/components/PaymentCardSelector";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { ModalAddPaymentCard } from "@fyendalscollection/app/features/paymentCards/modals/ModalAddPaymentCard";

export interface ModalCreateSubscriptionProps {
	visible: boolean;
	onCancel: () => void;
}

export const ModalCreateSubscription = ({ visible, onCancel }: ModalCreateSubscriptionProps): React.JSX.Element => {
	const [selectedPaymentCardId, setSelectedPaymentCardId] = useState<string | null>(null);
	const [planWithPromoCode, setPlanWithPromoCode] = useState<PlanDetails | null>(null);
	const [addPaymentCardModalVisible, setAddPaymentCardModalVisible] = useState(false);

	const cards = usePaymentCards();
	const subscriptionCreate = useSubscriptionCreate();

	useEffect(() => {
		if (subscriptionCreate.isSuccess) {
			onCancel();
			setSelectedPaymentCardId(null);
		}
	}, [subscriptionCreate.isSuccess]);

	const confirm = () => {
		if(selectedPaymentCardId && planWithPromoCode) {
			subscriptionCreate.mutate({
				planId: planWithPromoCode.planId,
				paymentCardId: selectedPaymentCardId,
				promoCode: planWithPromoCode.promoCode
			});
		}
	};

	return (
		<>
			<Modal visible={visible && !addPaymentCardModalVisible} onCancel={onCancel}>
				<Loadable loading={subscriptionCreate.isPending || cards.isLoading}>
					<TitleSecondary text="Subscribe" />

					<Paragraph>
						Please select a plan and payment card to start your subscription.
					</Paragraph>

					<PlanSelector onChange={x => setPlanWithPromoCode(x)} value={planWithPromoCode}/>

					{subscriptionCreate.isError &&
						<Container transparent={true}>
							<ContainerSegment>
								<ErrorDisplay message={subscriptionCreate.error.problem.description} />
							</ContainerSegment>
						</Container>
					}

					<PaymentCardSelector value={selectedPaymentCardId} onChange={setSelectedPaymentCardId} onAddPaymentCard={() => setAddPaymentCardModalVisible(true)} />

					<Container transparent={true}>
						<GreenButtonSet buttons={[
							{
								text: "Start Subscription",
								onClick: confirm,
								disabled: !planWithPromoCode || !selectedPaymentCardId
							}
						]} />
					</Container>

					<div className={styles.subtext}>
						Subscription renewal charges will occur 2 days before the
						auto renewal date.  By starting your subscription, you agree
						to our <a target="_blank" rel="noreferrer" className={styles.link} href={"https://fyendalscollection.com/terms-and-conditions"}>Terms and Conditions</a>.
					</div>
				</Loadable>
			</Modal>

			<ModalAddPaymentCard visible={addPaymentCardModalVisible} onCancel={() => setAddPaymentCardModalVisible(false)} />
		</>
	);
};

import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { Paragraph } from "@fyendalscollection/app/lib/components/Paragraph";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";

export interface ModalConfirmationProps {
	title: string;
	description: string;
	onConfirm: () => void;
	positiveButtonText?: string;
	negativeButtonText?: string;
	visible: boolean;
	onCancel: () => void;
}

export const ModalConfirmation = (props: ModalConfirmationProps): React.JSX.Element => {
	return (
		<>
			<Modal visible={props.visible} onCancel={props.onCancel}>
				<TitleSecondary text={props.title} />

				<Paragraph>
					{props.description}
				</Paragraph>

				<GreenButtonSet buttons={[
					{
						text: props.positiveButtonText || "Confirm",
						onClick: () => {
							props.onConfirm();
							props.onCancel();
						}
					},
					{
						text: props.negativeButtonText || "Cancel",
						onClick: props.onCancel
					}
				]} />
			</Modal>
		</>
	);
};

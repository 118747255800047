import { StorageService, useStorage } from "@fyendalscollection/app/shared";
import { createGlobalState } from "@fyendalscollection/app/shared";
import dayjs from "dayjs";
import { useEffect } from "react";

export interface BulkTransactionsState {
	[key: string]: BulkTransactionsDraft | undefined;
}

export interface BulkTransactionsDraft {
	itemsLeaving: BulkTransactionsEntry[];
	itemsEntering: BulkTransactionsEntry[];
	cashLeaving: number;
	cashEntering: number;
	occurredDate: string;
}

export interface BulkTransactionsEntry {
	productId: string;
	variantId: string;
	quantity: number;
}

enum BulkTransactionsActionType {
	AddLeavingItem,
	AddEnteringItem,
	RemoveLeavingItem,
	RemoveEnteringItem,
	SetLeavingItemQuantity,
	SetEnteringItemQuantity,
	SetCashLeaving,
	SetCashEntering,
	SetOccurredDate,
	Reset
}

type BulkTransactionsAction = {
	type: BulkTransactionsActionType.AddLeavingItem;
	collectionId: string;
	productId: string;
	variantId: string;
	quantity: number;
} | {
	type: BulkTransactionsActionType.AddEnteringItem;
	collectionId: string;
	productId: string;
	variantId: string;
	quantity: number;
} | {
	type: BulkTransactionsActionType.RemoveLeavingItem;
	collectionId: string;
	variantId: string;
} | {
	type: BulkTransactionsActionType.RemoveEnteringItem;
	collectionId: string;
	variantId: string;
} | {
	type: BulkTransactionsActionType.SetLeavingItemQuantity;
	collectionId: string;
	variantId: string;
	quantity: number;
} | {
	type: BulkTransactionsActionType.SetEnteringItemQuantity;
	collectionId: string;
	variantId: string;
	quantity: number;
} | {
	type: BulkTransactionsActionType.SetCashLeaving;
	collectionId: string;
	cash: number;
} | {
	type: BulkTransactionsActionType.SetCashEntering;
	collectionId: string;
	cash: number;
} | {
	type: BulkTransactionsActionType.SetOccurredDate;
	collectionId: string;
	occurredDate: string;
} | {
	type: BulkTransactionsActionType.Reset;
	collectionId: string;
};

const getInitialState = (storage: StorageService): BulkTransactionsState => {
	return storage.getPersistent<BulkTransactionsState>("bulkTransactionsState") || {};
};

const reducer = (state: BulkTransactionsState, action: BulkTransactionsAction): BulkTransactionsState => {
	switch (action.type) {
	case BulkTransactionsActionType.AddLeavingItem:
		if (state[action.collectionId]?.itemsLeaving.find(x => x.variantId === action.variantId)) {
			return state;
		}

		return {
			...state,
			[action.collectionId]: {
				...state[action.collectionId] || emptyBulkTransactionsDraft(),
				itemsLeaving: [
					...state[action.collectionId]?.itemsLeaving || [],
					{
						productId: action.productId,
						variantId: action.variantId,
						quantity: action.quantity
					}
				]
			}
		};
	case BulkTransactionsActionType.AddEnteringItem:
		if (state[action.collectionId]?.itemsEntering.find(x => x.variantId === action.variantId)) {
			return state;
		}

		return {
			...state,
			[action.collectionId]: {
				...state[action.collectionId] || emptyBulkTransactionsDraft(),
				itemsEntering: [
					...state[action.collectionId]?.itemsEntering || [],
					{
						productId: action.productId,
						variantId: action.variantId,
						quantity: action.quantity
					}
				]
			}
		};
	case BulkTransactionsActionType.RemoveLeavingItem:
		return {
			...state,
			[action.collectionId]: {
				...state[action.collectionId] || emptyBulkTransactionsDraft(),
				itemsLeaving: state[action.collectionId]?.itemsLeaving.filter(x => x.variantId !== action.variantId) || []
			}
		};
	case BulkTransactionsActionType.RemoveEnteringItem:
		return {
			...state,
			[action.collectionId]: {
				...state[action.collectionId] || emptyBulkTransactionsDraft(),
				itemsEntering: state[action.collectionId]?.itemsEntering.filter(x => x.variantId !== action.variantId) || []
			}
		};
	case BulkTransactionsActionType.SetLeavingItemQuantity:
		return {
			...state,
			[action.collectionId]: {
				...state[action.collectionId] || emptyBulkTransactionsDraft(),
				itemsLeaving: state[action.collectionId]?.itemsLeaving.map(x => x.variantId === action.variantId ? { ...x, quantity: action.quantity } : x) || []
			}
		};
	case BulkTransactionsActionType.SetEnteringItemQuantity:
		return {
			...state,
			[action.collectionId]: {
				...state[action.collectionId] || emptyBulkTransactionsDraft(),
				itemsEntering: state[action.collectionId]?.itemsEntering.map(x => x.variantId === action.variantId ? { ...x, quantity: action.quantity } : x) || []
			}
		};
	case BulkTransactionsActionType.SetCashLeaving:
		return {
			...state,
			[action.collectionId]: {
				...state[action.collectionId] || emptyBulkTransactionsDraft(),
				cashLeaving: action.cash
			}
		};
	case BulkTransactionsActionType.SetCashEntering:
		return {
			...state,
			[action.collectionId]: {
				...state[action.collectionId] || emptyBulkTransactionsDraft(),
				cashEntering: action.cash
			}
		};
	case BulkTransactionsActionType.SetOccurredDate:
		return {
			...state,
			[action.collectionId]: {
				...state[action.collectionId] || emptyBulkTransactionsDraft(),
				occurredDate: action.occurredDate
			}
		};
	case BulkTransactionsActionType.Reset:
		return {
			...state,
			[action.collectionId]: emptyBulkTransactionsDraft()
		};
	}
};

export const emptyBulkTransactionsDraft = (): BulkTransactionsDraft => ({
	itemsLeaving: [],
	itemsEntering: [],
	cashLeaving: 0,
	cashEntering: 0,
	occurredDate: dayjs().format("YYYY-MM-DD")
});

const [BulkTransactionsProvider, useBulkTransactionsReducer] = createGlobalState(reducer, getInitialState);
export { BulkTransactionsProvider };

export const useBulkTransactionsState = () => {
	const storage = useStorage();
	const [state, dispatch] = useBulkTransactionsReducer();

	useEffect(() => {
		const timeout = setTimeout(() => {
			storage.setPersistent("bulkTransactionsState", state);
		}, 250);

		return () => clearTimeout(timeout);
	}, [state]);

	const dispatchAddLeavingItem = (collectionId: string, variantId: string, productId: string, quantity: number) => {
		dispatch({
			type: BulkTransactionsActionType.AddLeavingItem,
			collectionId,
			variantId,
			productId,
			quantity
		});
	};

	const dispatchAddEnteringItem = (collectionId: string, variantId: string, productId: string, quantity: number) => {
		dispatch({
			type: BulkTransactionsActionType.AddEnteringItem,
			collectionId,
			variantId,
			productId,
			quantity
		});
	};

	const dispatchRemoveLeavingItem = (collectionId: string, variantId: string) => {
		dispatch({
			type: BulkTransactionsActionType.RemoveLeavingItem,
			collectionId,
			variantId
		});
	};

	const dispatchRemoveEnteringItem = (collectionId: string, variantId: string) => {
		dispatch({
			type: BulkTransactionsActionType.RemoveEnteringItem,
			collectionId,
			variantId
		});
	};

	const dispatchSetLeavingItemQuantity = (collectionId: string, variantId: string, quantity: number) => {
		dispatch({
			type: BulkTransactionsActionType.SetLeavingItemQuantity,
			collectionId,
			variantId,
			quantity
		});
	};

	const dispatchSetEnteringItemQuantity = (collectionId: string, variantId: string, quantity: number) => {
		dispatch({
			type: BulkTransactionsActionType.SetEnteringItemQuantity,
			collectionId,
			variantId,
			quantity
		});
	};

	const dispatchSetCashLeaving = (collectionId: string, cash: number) => {
		dispatch({
			type: BulkTransactionsActionType.SetCashLeaving,
			collectionId,
			cash
		});
	};

	const dispatchSetCashEntering = (collectionId: string, cash: number) => {
		dispatch({
			type: BulkTransactionsActionType.SetCashEntering,
			collectionId,
			cash
		});
	};

	const dispatchSetOccurredDate = (collectionId: string, occurredDate: string) => {
		dispatch({
			type: BulkTransactionsActionType.SetOccurredDate,
			collectionId,
			occurredDate
		});
	};

	const dispatchReset = (collectionId: string) => {
		dispatch({
			type: BulkTransactionsActionType.Reset,
			collectionId
		});
	};

	return {
		state,
		dispatchAddLeavingItem,
		dispatchAddEnteringItem,
		dispatchRemoveLeavingItem,
		dispatchRemoveEnteringItem,
		dispatchSetLeavingItemQuantity,
		dispatchSetEnteringItemQuantity,
		dispatchSetCashLeaving,
		dispatchSetCashEntering,
		dispatchSetOccurredDate,
		dispatchReset
	};
};

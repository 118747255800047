import React, { useContext, useEffect, useState } from "react";

export enum Theme {
	Unknown,
	Light,
	Dark
}

export interface ThemeProviderProps {
	children: React.ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps): React.JSX.Element => {
	const [theme, setTheme] = useState<Theme | null>(null);

	useEffect(() => {
		setTheme(getThemeFromWindow());
	}, []);

	useEffect(() => {
		const onChange = (e: MediaQueryListEvent) => {
			setTheme(e.matches ? Theme.Dark : Theme.Light);
		};

		window
			.matchMedia("(prefers-color-scheme: dark)")
			.addEventListener("change", onChange);

		return () => {
			window
				.matchMedia("(prefers-color-scheme: dark)")
				.removeEventListener("change", onChange);
		};
	}, []);

	if (!theme) {
		return <></>;
	}

	return (
		<ctx.Provider value={theme}>
			{children}
		</ctx.Provider>
	);
};

const getThemeFromWindow = (): Theme => window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
	? Theme.Dark
	: Theme.Light;

const ctx = React.createContext(getThemeFromWindow());

export const useTheme = () => useContext(ctx);

import styles from "./Limit.module.scss";

export interface LimitProps {
	children: React.ReactNode;
	force?: LimitWidth;
}

export enum LimitWidth {
	Massive,
	Tiny,
	Medium,
	Large,
}

export const Limit = ({ children, force = LimitWidth.Massive }: LimitProps): React.JSX.Element => {
	const classNames = [ styles.limit, getClassNameForLimitWidth(force) ];
	return (
		<div className={classNames.join(" ")}>
			{children}
		</div>
	);
};

const getClassNameForLimitWidth = (limitWidth: LimitWidth) => {
	switch (limitWidth) {
	case LimitWidth.Tiny:
		return styles.narrow;
	case LimitWidth.Medium:
		return styles.moderate;
	case LimitWidth.Large:
		return styles.large;
	default:
		return styles.standard;
	}
};

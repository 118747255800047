import { useEffect, useState } from "react";
import styles from "./PageCollections.module.scss";
import { InfiniteScroller } from "@fyendalscollection/app/lib/components/InfiniteScroller";
import { useUserPreferencesState } from "@fyendalscollection/app/shared";
import { useQueryParams } from "@fyendalscollection/app/lib/useQueryParams";
import { useNavigate } from "react-router-dom";
import { CollectionModel, CollectionsSortOrder, useCollections } from "@fyendalscollection/app/shared";
import { ModalCreateCollection } from "@fyendalscollection/app/features/collections/components/ModalCreateCollection";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { useSubscription } from "@fyendalscollection/app/shared";
import { CollectionCard } from "@fyendalscollection/app/features/collections/components/CollectionCard";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { StyledSelect } from "@fyendalscollection/app/lib/components/StyledSelect";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { CallToAction } from "@fyendalscollection/app/lib/components/CallToAction";
import { ModalUpdateCollection } from "@fyendalscollection/app/features/collections/components/ModalUpdateCollection";

export const PageCollections = (): React.JSX.Element => {
	useTitle("Collections");

	const queryParams = useQueryParams();
	const navigate = useNavigate();
	const openCreateModal = queryParams.get("create");

	const [modalCreateVisible, setModalCreateVisible] = useState(openCreateModal === "1");
	const [collectionToUpdate, setCollectionToUpdate] = useState<CollectionModel | null>(null);
	const { state, dispatchSetCollectionsSortOrder } = useUserPreferencesState();

	const collections = useCollections(state.collectionsSortOrder);
	const subscription = useSubscription();

	useEffect(() => {
		if (openCreateModal === "1") {
			navigate("", { replace: true });
		}
	}, [openCreateModal]);

	const collectionsResults = collections.data
		? collections.data.pages.flatMap(x => x.results)
		: [];

	const subtitle = "Manage your collections" + ((subscription.data && !subscription.data.activeSubscription) && collections.data
		? ` (${collectionsResults.length > 2 ? 2 : collectionsResults.length}/2 free collections created)`
		: "");

	const collectionList = collectionsResults.map(x => (
		<CollectionCard
			key={x.collectionId}
			id={x.collectionId}
			title={x.name}
			description={x.description}
			marketValue={x.marketValue}
			gain={x.gain}
			invested={x.invested}
			onCogClick={() => setCollectionToUpdate(x)}
			linkTo={x.collectionId}
			isValued={x.valued}
			pinned={x.pinned}
			image={x.image} />
	));

	return (
		<Loadable loading={collections.isLoading}>
			<Limit force={LimitWidth.Massive}>
				{
					collections.isError &&
					<Container>
						<ContainerSegment>
							<ErrorDisplay problemResponse={collections.error}/>
						</ContainerSegment>
					</Container>
				}

				<Breadcrumb crumbs={[{
					text: "Dashboard",
					link: "/dashboard"
				},
				{
					text: "Collections"
				}]} />

				<PageTitle
					title="Collections"
					subTitle={subtitle}
					primaryActions={
						<>
							<StyledSelect value={state.collectionsSortOrder} onChange={dispatchSetCollectionsSortOrder} options={[
								{ text: "Sort: Chronological", value: CollectionsSortOrder.Chronological },
								{ text: "Sort: Alphabetical", value: CollectionsSortOrder.Alphabetical },
								{ text: "Sort: Value", value: CollectionsSortOrder.Value },
								{ text: "Sort: Percentage Gain", value: CollectionsSortOrder.PercentageGain }
							]} />

							<GreenButtonSet buttons={[
								{
									text: "Create",
									onClick: () => setModalCreateVisible(true)
								},
								{
									text: "Import",
									link: "csv-import"
								}
							]} />
						</>
					} />

				{
					collections.data && collections.data.pages.flatMap(x => x.results).length === 0 &&
					<CallToAction
						title="Create your first collection!"
						subtitle="Collections are used for tracking the cards and products you own"
						buttonText="Create Collection"
						onClick={() => setModalCreateVisible(true)} />
				}

				<div className={styles.collectionList}>
					{collectionList}
					<InfiniteScroller forQuery={collections} />
				</div>
			</Limit>

			<ModalCreateCollection visible={modalCreateVisible} onCancel={() => setModalCreateVisible(false)} />
			<ModalUpdateCollection collection={collectionToUpdate} onCancel={() => setCollectionToUpdate(null)} />
		</Loadable>
	);
};

import { useNavigate } from "react-router-dom";
import { Banner } from "@fyendalscollection/app/lib/components/Banner";
import { useStorage, useSubscription } from "@fyendalscollection/app/shared";
import { useCallback, useState } from "react";
import { FREE_TRIAL_PROMO_CODE } from "@fyendalscollection/app/lib/config";

export const SubscriptionBanner = (): React.JSX.Element => {
	const storage = useStorage();
	const navigate = useNavigate();
	const subscription = useSubscription();
	const [hideBanner, setHideBanner] = useState(() => storage.getTemporary<boolean>("hideBanner") || false);

	const setHideBannerEnriched = useCallback((value: boolean) => {
		storage.setTemporary("hideBanner", value);
		setHideBanner(value);
	}, [setHideBanner]);

	if (hideBanner
		|| !subscription.isSuccess
		|| (!subscription.data.autoRenewFailureCount && subscription.data.activeSubscription)) {
		return <></>;
	}

	const [title, text] = getTitleAndText(subscription.data.hasPreviousSubscription, subscription.data.autoRenewFailureCount);

	return (
		<Banner
			title={title}
			text={text}
			onClick={() => navigate("/settings/subscription")}
			onCancel={() => setHideBannerEnriched(true)}
			isError={!!subscription.data.autoRenewFailureCount} />
	);
};

const getTitleAndText = (hasPreviousSubscription: boolean, autoRenewFailureCount: number): [string, string] => {
	if (!hasPreviousSubscription && FREE_TRIAL_PROMO_CODE) {
		return ["Free Trial", `Get all our premium features for free for 7 days.  Click here and use promo code ${FREE_TRIAL_PROMO_CODE}`];
	}

	if (autoRenewFailureCount) {
		return ["Payment Failure", "Your payment card declined, please click here to update your payment information."];
	}

	return ["Subscribe Now", "Unlock more features by going premium with Fyendal's Collection."];
};

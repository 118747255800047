import { useEffect, useState } from  "react";
import { Link, useNavigate } from "react-router-dom";
import { useQueryParams } from "@fyendalscollection/app/lib/useQueryParams";
import Tick from "@fyendalscollection/app/assets/tick.svg";
import { useEffectOnFirstMount } from "@fyendalscollection/app/lib/useEffectOnFirstMount";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { useFocus } from "@fyendalscollection/app/lib/useFocus";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { BannerMessage } from "@fyendalscollection/app/lib/components/BannerMessage";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { TextEntry } from "@fyendalscollection/app/lib/components/TextEntry";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { useAuthConcludeForgotPassword } from "@fyendalscollection/app/shared";

export const PageConcludeForgotPassword = (): React.JSX.Element => {
	useTitle("Forgot Password");

	const queryParams = useQueryParams();
	const navigate = useNavigate();
	const authConcludeForgotPassword = useAuthConcludeForgotPassword();

	const [ref, focus] = useFocus();

	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");

	const code = queryParams.get("code");
	const userId = queryParams.get("userId");

	const newPasswordValid = newPassword.length > 7;
	const confirmNewPasswordValid = !!confirmNewPassword && confirmNewPassword === newPassword;

	const formValid = newPasswordValid && confirmNewPasswordValid;
	// Focus the displayName TextEntry when we land on the page.
	useEffect(() => focus(), []);

	useEffectOnFirstMount(() => {
		if (!code || !userId) {
			navigate("../..");
		}
	});

	if (!userId || !code) {
		return <></>;
	}

	const getFormContent = (): React.JSX.Element => {
		if (authConcludeForgotPassword.isSuccess) {
			return (
				<>
					<Container>
						<ContainerSegment>
							<BannerMessage image={Tick} title="Password Change Success" content="We've successfully reset your password!  You can click the button below to login with your new password."/>
						</ContainerSegment>
					</Container>

					<Container transparent={true}>
						<GreenButtonSet buttons={[
							{
								text: "Back To Login",
								link: ".."
							}
						]} />
					</Container>
				</>
			);
		}

		if (authConcludeForgotPassword.isError && authConcludeForgotPassword.error.problem.problemType === "CodeExpired") {
			return (
				<Container>
					<ContainerSegment>
						Your reset password link has expired. Please repeat the process again.
						<Link to={"../initiate"}>Forgot Password</Link>
					</ContainerSegment>
				</Container>
			);
		}

		return (
			<>
				<Container>
					<ContainerSegment>
						Please enter a new password to use.
					</ContainerSegment>

					<ContainerSegment>
						<TextEntry
							ref={ref}
							placeholder="New Password"
							valid={newPasswordValid}
							type="password"
							value={newPassword}
							onChange={setNewPassword} />
					</ContainerSegment>

					<ContainerSegment>
						<TextEntry
							placeholder="Confirm Password"
							valid={confirmNewPasswordValid}
							type="password"
							value={confirmNewPassword}
							onChange={setConfirmNewPassword} />
					</ContainerSegment>

					{authConcludeForgotPassword.isError && <ContainerSegment>
						<ErrorDisplay problemResponse={authConcludeForgotPassword.error}/>
					</ContainerSegment>}

				</Container>

				<Container transparent={true}>
					<GreenButtonSet buttons={[
						{
							text: "Confirm",
							disabled: !formValid,
							onClick: () => authConcludeForgotPassword.mutate({
								userId,
								forgotPasswordCode: code,
								newPassword: newPassword
							})
						},
						{
							text: "Back",
							link: ".."
						}
					]} />
				</Container>
			</>
		);
	};

	return (
		<Loadable loading={authConcludeForgotPassword.isPending}>
			{getFormContent()}
		</Loadable>
	);
};

import planIcon from "@fyendalscollection/app/assets/plan.svg";
import plus from "@fyendalscollection/app/assets/plus.svg";
import star from "@fyendalscollection/app/assets/star.svg";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";
import { Currency, usePlanForPromoCode, usePlans } from "@fyendalscollection/app/shared";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ContainerListItemRadioButton } from "@fyendalscollection/app/lib/components/ContainerListItemRadioButton";
import { ContainerListItem, ImageSize } from "@fyendalscollection/app/lib/components/ContainerListItem";
import { useEffect, useState } from "react";
import { ModalTextEntry } from "@fyendalscollection/app/lib/components/ModalTextEntry";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";

export interface PlanSelectorProps {
	value: PlanDetails | null;
	onChange: (value: PlanDetails) => void;
}

export interface PlanDetails {
	planId: string;
	promoCode: string | null;
}

export const PlanSelector = ({ value, onChange }: PlanSelectorProps): React.JSX.Element => {
	const plans = usePlans();
	const promoCodePlan = usePlanForPromoCode();
	const [promoCodeModalVisible, setPromoCodeModalVisible] = useState(false);

	useEffect(() => {
		if (!plans.data || plans.data.length === 0 || plans.data.find(x => x.planId === value?.planId)) {
			return;
		}

		onChange({ planId: plans.data[0].planId, promoCode: null });
	}, [plans.data]);

	useEffect(() => {
		if (!promoCodePlan.data?.body.planId) {
			return;
		}

		onChange({ planId: promoCodePlan.data.body.planId, promoCode: promoCodePlan.variables || null });
	}, [promoCodePlan.data]);

	return (
		<>
			<Loadable loading={plans.isLoading || promoCodePlan.isPending}>
				<Container>
					{
						(plans.data || []).map(x => (
							<ContainerSegment key={x.planId} onClick={() => onChange({ planId: x.planId, promoCode: null })}>
								<ContainerListItemRadioButton
									image={planIcon}
									selected={!!value && value.planId === x.planId}
									title={x.name}
									subtitle={<PrettyMoney mode={MoneyDisplayMode.Standard} money={{ currency: Currency.USD, value: x.price }} />} />
							</ContainerSegment>
						))
					}

					{
						promoCodePlan.data ? (
							<ContainerSegment onClick={() => onChange({ planId: promoCodePlan.data.body.planId, promoCode: promoCodePlan.variables || null })}>
								<ContainerListItemRadioButton
									image={star}
									selected={!!value && value.planId === promoCodePlan.data.body.planId}
									title={promoCodePlan.data.body.name}
									subtitle={<PrettyMoney mode={MoneyDisplayMode.Standard} money={{ currency: Currency.USD, value: promoCodePlan.data.body.price }} />} />
							</ContainerSegment>
						) : (
							<ContainerSegment onClick={() => setPromoCodeModalVisible(true)}>
								<ContainerListItem
									title="Enter Promo Code"
									image={plus}
									imageSize={ImageSize.Small} />
							</ContainerSegment>
						)
					}

					{
						promoCodePlan.error &&
						<ContainerSegment>
							<ErrorDisplay problemResponse={promoCodePlan.error} />
						</ContainerSegment>
					}
				</Container>
			</Loadable>

			<Container> </Container>

			<ModalTextEntry
				title="Promo Code"
				description="Please enter your promo code"
				visible={promoCodeModalVisible}
				onCancel={() => setPromoCodeModalVisible(false)}
				onConfirm={promoCodePlan.mutate} />
		</>
	);
};

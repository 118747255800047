import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { InfiniteScroller } from "@fyendalscollection/app/lib/components/InfiniteScroller";
import { Limit } from "@fyendalscollection/app/lib/components/Limit";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";
import { StyledSelect } from "@fyendalscollection/app/lib/components/StyledSelect";
import { TabularData } from "@fyendalscollection/app/lib/components/TabularData";
import { TabularDataItem } from "@fyendalscollection/app/lib/components/TabularDataItem";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { OfficialDecksFormatFilter, OfficialDecksSortOrder, useOfficialDeckHeroes, useOfficialDecks, useUserPreferencesState } from "@fyendalscollection/app/shared";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import styles from "./PageOfficialDecks.module.scss";
import { StyledAutoCompleteBox } from "@fyendalscollection/app/lib/components/StyledAutoCompleteBox";

export const PageOfficialDecks = (): React.JSX.Element => {
	useTitle("Official Decks");

	const { state, dispatchSetOfficialDecksSortOrder, dispatchSetOfficialDecksFormatFilter, dispatchSetOfficialDecksHeroFilter } = useUserPreferencesState();

	const officialDecks = useOfficialDecks(state.officialDecksSortOrder, state.officialDecksHeroFilter, state.officialDecksFormatFilter);
	const officialDeckHeroes = useOfficialDeckHeroes(state.officialDecksFormatFilter);

	const officialDeckResults = officialDecks.data
		? officialDecks.data.pages.flatMap(x => x.results)
		: [];

	const officialDeckHeroesResults = officialDeckHeroes.data
		? officialDeckHeroes.data.map(x => x.hero)
		: [];

	return (
		<Limit>
			{
				officialDecks.isError &&
				<Container>
					<ContainerSegment>
						<ErrorDisplay problemResponse={officialDecks.error}/>
					</ContainerSegment>
				</Container>
			}

			<Breadcrumb crumbs={[{
				text: "Dashboard",
				link: "/dashboard"
			},
			{
				text: "Official Decks"
			}]} />

			<Loadable loading={officialDecks.isLoading}>
				<PageTitle
					title="Official Decks"
					subTitle="Pricing and financials for official LSS decks"
					primaryActions={
						<>
							<StyledAutoCompleteBox options={officialDeckHeroesResults} onChange={dispatchSetOfficialDecksHeroFilter} value={state.officialDecksHeroFilter} placeholder="Hero" />

							<StyledSelect value={state.officialDecksSortOrder} onChange={dispatchSetOfficialDecksSortOrder} options={[
								{ text: "Sort: Date", value: OfficialDecksSortOrder.Date},
								{ text: "Sort: Placing", value: OfficialDecksSortOrder.Placing},
								{ text: "Sort: Price High", value: OfficialDecksSortOrder.PriceHigh},
								{ text: "Sort: Price Low", value: OfficialDecksSortOrder.PriceLow}
							]} />

							<StyledSelect value={state.officialDecksFormatFilter} onChange={dispatchSetOfficialDecksFormatFilter} options={[
								{ text: "Filter: All", value: null},
								{ text: "Filter: Blitz", value: OfficialDecksFormatFilter.Blitz},
								{ text: "Filter: Classic Constructed", value: OfficialDecksFormatFilter.ClassicConstructed},
								{ text: "Filter: Commoner", value: OfficialDecksFormatFilter.Commoner},
								{ text: "Filter: Living Legend", value: OfficialDecksFormatFilter.LivingLegend}
							]} />
						</>
					} />

				<TabularData headers={["Decklist", "Date", "Country", "Event", "Format", "Hero", "Placing", "Total Price"]}>
					{
						officialDeckResults.map(x => (
							<TabularDataItem key={x.officialDeckId}>
								<>
									<Link className={styles.name} to={x.officialDeckId}>
										{x.name}
									</Link>
								</>
								<>{dayjs(x.date).format("DD MMM YYYY")}</>
								<>{x.country}</>
								<>{x.event}</>
								<>{x.format}</>
								<>{x.hero}</>
								<>{x.placing}</>
								<><PrettyMoney className={styles.price} money={x.totalPrice} mode={MoneyDisplayMode.Standard} /></>
							</TabularDataItem>
						))
					}
				</TabularData>
				<InfiniteScroller forQuery={officialDecks} />
			</Loadable>
		</Limit>
	);
};

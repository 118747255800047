import { useState, FocusEvent } from "react";
import lightDropdownArrow from "@fyendalscollection/app/assets/dropdown-arrow-light.svg";
import darkDropdownArrow from "@fyendalscollection/app/assets/dropdown-arrow-dark.svg";
import styles from "./Selector.module.scss";
import { SelectorCheckbox } from "@fyendalscollection/app/lib/components/SelectorCheckbox";
import { Theme, useTheme } from "@fyendalscollection/app/lib/ThemeContext";

export interface SelectorProps<T> {
	values: T[];
	onChange: (value: T[]) => void;
	options: SelectorOption<T>[];
	optionsDescription?: string;
	selectAll?: boolean;
	disabled?: boolean;
	classNames?: string;
}

export interface SelectorOption<T> {
	text: string;
	value: T;
}

export const Selector = <T,>({ values, onChange, options, optionsDescription, selectAll, disabled, classNames }: SelectorProps<T>): React.JSX.Element => {
	const [visible, setVisible] = useState(false);
	const theme = useTheme();

	const classNamesButton: string[] = [styles.button];
	if (disabled) {
		classNamesButton.push(styles.disabled);
	}
	if (classNames) {
		classNamesButton.push(classNames);
	}

	const handleDropdown = () => {
		if (disabled) {
			return;
		}
		setVisible(!visible);
	};

	const handleBlur = (e: FocusEvent<HTMLDivElement>) => {
		if (!e.currentTarget.contains(e.relatedTarget)) {
			setVisible(false);
		}
	};

	const onChangeInternal = (option: T) => {
		if (values.includes(option)) {
			onChange(values.filter(value => value != option));
			return;
		}
		onChange([...values, option]);
	};

	const handleSelectAll = () => {
		if (values.length == options.length) {
			onChange([]);
			return;
		}
		onChange(options.map(option => option.value));
	};

	const optionsDisplay = options.map((option, i) => (
		<div key={i}>
			<SelectorCheckbox
				label={option.text}
				onChange={() => onChangeInternal(option.value)}
				isChecked={values.includes(option.value)} />
		</div>
	));

	return (
		<div className={styles.selector} onBlur={handleBlur} tabIndex={0}>
			<button className={classNamesButton.join(" ")} onClick={handleDropdown} type="button">
				<div>
					{`${values.length} ${optionsDescription ? optionsDescription : "option"}${values.length == 1 ? "" : "s"} selected`}
				</div>
				<img className={disabled ? styles.disabled : undefined} src={theme === Theme.Light ? lightDropdownArrow : darkDropdownArrow}/>
			</button>
			{
				visible && (
					<div className={styles.dropdown}>
						{
							selectAll && (
								<div>
									<SelectorCheckbox
										label={"Select All"}
										onChange={handleSelectAll}
										isChecked={values.length == options.length} />
								</div>
							)
						}
						{optionsDisplay}
					</div>
				)
			}
		</div>
	);
};

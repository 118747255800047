import { MutationFunction } from "@tanstack/react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";

export interface AuthConcludeForgotPasswordRequestModel {
	userId: string;
	forgotPasswordCode: string;
	newPassword: string;
}

export const useAuthConcludeForgotPassword = () => {
	const http = useHTTP(HTTPAuthMode.None);

	const mutFunc: MutationFunction<Response, AuthConcludeForgotPasswordRequestModel> = requestModel => {
		return http<undefined, AuthConcludeForgotPasswordRequestModel>({
			method: "POST",
			url: "/auth/forgot/conclude",
			body: requestModel
		});
	};

	return useSimpleMutation(mutFunc);
};

import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import styles from "./ProductAffiliateLink.module.scss";
import TCGplayerLogo from "@fyendalscollection/app/assets/TCGplayer-logo.png";
import { getAffiliateLink } from "@fyendalscollection/app/lib/getAffiliateLink";

export interface ProductAffiliateLinkProps {
	tcgPlayerProductId?: number;
}

export const ProductAffiliateLink = ({ tcgPlayerProductId }: ProductAffiliateLinkProps): React.JSX.Element => {
	return (
		<GreenButtonSet buttons={[
			{
				text: <img className={styles.tcgPlayerLogo} src={TCGplayerLogo} />,
				link: getAffiliateLink(tcgPlayerProductId),
				newTabLink: true
			}
		]} />
	);
};

import { MutationFunction, useQueryClient } from "@tanstack/react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";
import { GoalCollectionModel } from "./models";

export interface SetGoalCollectionOptions {
	collectionId: string;
	requestModel: SetGoalCollectionRequestModel;
}

export interface SetGoalCollectionRequestModel {
	readonly goalCollectionId: string | null;
}

export interface SetGoalCollectionResponseModel {
	readonly goalCollection: GoalCollectionModel | null;
}

export const useSetGoalCollection = () => {
	const http = useHTTP(HTTPAuthMode.Required);
	const queryClient = useQueryClient();

	const mutFunc: MutationFunction<Response<SetGoalCollectionResponseModel>, SetGoalCollectionOptions> = options => {
		return http<SetGoalCollectionResponseModel, SetGoalCollectionRequestModel>({
			method: "PUT",
			url: `/collection/${options.collectionId}/goal-collection`,
			body: options.requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: (data, options) => {
			queryClient.setQueryData(["goalCollection", options.collectionId], data.body);
			queryClient.invalidateQueries({ queryKey: ["progressSummaries", options.collectionId] });
			queryClient.invalidateQueries({ queryKey: ["collectionProgress", options.collectionId] });
		}
	});
};

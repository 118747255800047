import styles from "./FrameLogin.module.scss";
import icon from "@fyendalscollection/app/assets/icon.svg";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { Outlet } from "react-router-dom";

export const FrameLogin = (): React.JSX.Element => {
	return (
		<Limit force={LimitWidth.Tiny}>
			<div className={styles.authTitle}>
				<img src={icon} />
				<div>Fyendal&apos;s Collection</div>
			</div>
			<Outlet />
		</Limit>
	);
};

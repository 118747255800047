import React, { useContext, useMemo, useReducer } from "react";
import { StorageService, useStorage } from "../storage";

export interface GlobalStateComponentProps {
	children: React.ReactNode;
}

export type StateInitializerFunc<S> = (storage: StorageService) => S;

export const createGlobalState = <S, A>(reducer: React.Reducer<S, A>, stateInitializer: StateInitializerFunc<S>): [(props: GlobalStateComponentProps) => React.JSX.Element, () => [S, React.Dispatch<A>]] => {
	const ctx = React.createContext<[S, React.Dispatch<A>] | null>(null);

	const StateProviderComponent = ({ children }: GlobalStateComponentProps): React.JSX.Element => {
		const storage = useStorage();
		const initialState = useMemo(() => stateInitializer(storage), []);
		const [state, dispatch] = useReducer(reducer, initialState);
		return (
			<ctx.Provider value={[state, dispatch]}>
				{children}
			</ctx.Provider>
		);
	};

	const useStateContext = () => useContext(ctx) as [S, React.Dispatch<A>];
	return [StateProviderComponent, useStateContext];
};

import { MutationFunction } from "@tanstack/react-query";
import { HTTPAuthMode, Response, useHTTP, useSimpleMutation } from "../../http";
import { Currency, PriceSource } from "../../domain";

export interface PublicCollectionCurrencyAndPriceSourceResponseModel {
	currency: Currency;
	priceSource: PriceSource;
}

export const usePublicCollectionCurrencyAndPriceSource = () => {
	const http = useHTTP(HTTPAuthMode.UseIfPresent);

	const mutFunc: MutationFunction<Response<PublicCollectionCurrencyAndPriceSourceResponseModel>, string> = shareToken => {
		return http<PublicCollectionCurrencyAndPriceSourceResponseModel>({
			method: "GET",
			url: `/collection/public/${shareToken}/currency-price-source`
		});
	};

	return useSimpleMutation(mutFunc);
};

import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";
import { TabularData } from "@fyendalscollection/app/lib/components/TabularData";
import { TabularDataItem } from "@fyendalscollection/app/lib/components/TabularDataItem";
import { useSponsorPerformance } from "@fyendalscollection/app/shared";

export const PageSponsorPerformance = (): React.JSX.Element => {
	const sponsorPerformance = useSponsorPerformance();

	return (
		<Limit force={LimitWidth.Medium}>
			<Loadable loading={sponsorPerformance.isLoading}>
				<Breadcrumb crumbs={[
					{
						text: "Dashboard",
						link: "/dashboard"
					},
					{
						text: "Sponsor Performance"
					}
				]} />

				<PageTitle title="Sponsor Breakdown" subTitle="Track your sponsorship performance"/>

				{
					sponsorPerformance.error &&
					<Container>
						<ContainerSegment>
							<ErrorDisplay problemResponse={sponsorPerformance.error} />
						</ContainerSegment>
					</Container>
				}

				{
					sponsorPerformance.data &&
					<TabularData headers={["Month", "Active Subscriptions", "Subscription Revenue", "Commissions"]}>
						{
							sponsorPerformance.data.map(x => (
								<TabularDataItem key={x.month}>
									<>{x.month}</>
									<>{x.activeSubscriptions}</>
									<><PrettyMoney mode={MoneyDisplayMode.Standard} money={x.subscriptionRevenue} /></>
									<><PrettyMoney mode={MoneyDisplayMode.Standard} money={x.commissions} /></>
								</TabularDataItem>
							))
						}
					</TabularData>
				}
			</Loadable>
		</Limit>
	);
};

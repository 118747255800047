import { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import styles from "./PageSubscription.module.scss";
import tick from "@fyendalscollection/app/assets/tick.svg";
import { Currency, SubscriptionModel, useChangePaymentCard, usePaymentCards, useSubscription } from "@fyendalscollection/app/shared";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { ModalCancelAutoRenew } from "@fyendalscollection/app/lib/components/ModalCancelAutorenew";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ContainerListItem } from "@fyendalscollection/app/lib/components/ContainerListItem";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";
import { LinkButton } from "@fyendalscollection/app/lib/components/LinkButton";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { ModalCreateSubscription } from "@fyendalscollection/app/features/subscriptions/modals/ModalCreateSubscription";
import { SubscriptionPlan } from "@fyendalscollection/app/features/subscriptions/components/SubscriptionPlan";
import { getPaymentCardProviderImage } from "@fyendalscollection/app/lib/getPaymentCardProviderImage";
import { ModalSelectPaymentCard } from "@fyendalscollection/app/features/subscriptions/modals/ModalSelectPaymentCard";

export const PageSubscription = (): React.JSX.Element => {
	useTitle("Subscription");

	const subscription = useSubscription();

	const [createModalVisible, setCreateModalVisible] = useState(false);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);
	const [wasSubscribedOnMount, setWasSubscribedOnMount] = useState<boolean | null>(null);

	useEffect(() => {
		if (!subscription.data || wasSubscribedOnMount !== null) {
			return;
		}

		setWasSubscribedOnMount(subscription.data.activeSubscription !== null);
	}, [subscription.data]);

	const pageLimit = subscription.isLoading || subscription.data && subscription.data.activeSubscription
		? LimitWidth.Tiny
		: LimitWidth.Medium;

	return (
		<Limit force={pageLimit}>
			<Breadcrumb crumbs={[{
				text: "Settings",
				link: "/settings"
			},
			{
				text: "Subscription"
			}]}/>

			<PageTitle
				title="Subscription"
				subTitle="Manage your access to premium features" />

			<Loadable loading={subscription.isLoading}>
				{
					subscription.data
						? <ActiveSubscriptionInfoDisplay
							subscription={subscription.data}
							setCreateModalVisible={setCreateModalVisible}
							setDeleteModalVisible={setDeleteModalVisible}
							wasSubscribedOnMount={wasSubscribedOnMount} />
						: <LoadingDisplay />
				}
			</Loadable>

			<ModalCreateSubscription
				visible={createModalVisible}
				onCancel={() => setCreateModalVisible(false)} />

			<ModalCancelAutoRenew
				visible={deleteModalVisible}
				onCancel={() => setDeleteModalVisible(false)} />
		</Limit>
	);
};

interface ActiveSubscriptionInfoDisplayProps {
	subscription: SubscriptionModel;
	setCreateModalVisible: (visible: boolean) => void;
	setDeleteModalVisible: (visible: boolean) => void;
	wasSubscribedOnMount: boolean | null;
}

const ActiveSubscriptionInfoDisplay = (props: ActiveSubscriptionInfoDisplayProps): React.JSX.Element => {
	const {
		subscription,
		setCreateModalVisible,
		setDeleteModalVisible,
		wasSubscribedOnMount
	} = props;

	const autoRenewDisplay = subscription.autoRenewPaymentCardId && subscription.autoRenewPlanId && subscription.autoRenewPlanName ? (
		<ContainerSegment>
			<ContainerListItem title="Auto Renew Plan" subtitle={subscription.autoRenewPlanName}>
				<b><PrettyMoney mode={MoneyDisplayMode.Standard} money={{ currency: Currency.USD, value: subscription.autoRenewPlanPrice }} /></b>
			</ContainerListItem>
		</ContainerSegment>
	) : (
		<></>
	);

	const buttons = [
		{
			text: "Change Subscription",
			onClick: () => setCreateModalVisible(true)
		}
	];

	if (subscription.autoRenewPaymentCardId && subscription.autoRenewPlanId && subscription.autoRenewPlanName && wasSubscribedOnMount) {
		buttons.unshift({
			text: "Cancel Subscription",
			onClick: () => setDeleteModalVisible(true)
		});
	}

	return !subscription.activeSubscription ? (
		<NoSubscriptionDisplay setCreateModalVisible={setCreateModalVisible} />
	) : (
		<>
			<Container title="Subscription Details">
				<ContainerSegment>
					<ContainerListItem
						title="Current Plan"
						subtitle={`${subscription.activeSubscription.planName}
							expires ${dayjs(subscription.activeSubscription.expiryDate).format("DD MMM YYYY")}`}>
						<LinkButton
							text={"Terms and Conditions"}
							link="https://fyendalscollection.com/terms-and-conditions"
							newTab={true} />
					</ContainerListItem>
				</ContainerSegment>

				{autoRenewDisplay}
				<AutoRenewPaymentCardDisplay subscription={subscription} />
			</Container>

			<GreenButtonSet buttons={buttons} />
		</>
	);
};

interface AutoRenewPaymentCardDisplayProps {
	subscription: SubscriptionModel;
}

const AutoRenewPaymentCardDisplay = ({ subscription }: AutoRenewPaymentCardDisplayProps): React.JSX.Element => {
	const paymentCards = usePaymentCards();
	const changePaymentCard = useChangePaymentCard();

	const paymentCard = useMemo(() => {
		if (!paymentCards.data) {
			return null;
		}

		return paymentCards.data.find(x => x.paymentCardId === subscription.autoRenewPaymentCardId) || null;
	}, [paymentCards.data, subscription.autoRenewPaymentCardId]);

	const [changePaymentCardModalVisible, setChangePaymentCardModalVisible] = useState(false);

	useEffect(() => {
		if (!changePaymentCard.isSuccess) {
			return;
		}

		setChangePaymentCardModalVisible(false);
	}, [changePaymentCard.isSuccess]);

	if (!paymentCard) {
		return <></>;
	}

	return (
		<>
			<ModalSelectPaymentCard
				visible={changePaymentCardModalVisible}
				onCancel={() => setChangePaymentCardModalVisible(false)}
				onPaymentCardSelected={paymentCardId => changePaymentCard.mutate({ paymentCardId })}
				isLoading={changePaymentCard.isPending}
				error={changePaymentCard.error} />

			<ContainerSegment>
				<ContainerListItem
					image={getPaymentCardProviderImage(paymentCard.provider)}
					title="Auto Renew Card"
					subtitle={`Card ending in ${paymentCard.last4}`}>
					<LinkButton text="Change Payment Card" onClick={() => setChangePaymentCardModalVisible(true)} />
				</ContainerListItem>
			</ContainerSegment>
		</>
	);
};

interface NoSubscriptionDisplayProps {
	setCreateModalVisible: (visible: boolean) => void;
}

const NoSubscriptionDisplay = ({ setCreateModalVisible }: NoSubscriptionDisplayProps): React.JSX.Element => {
	return (
		<div className={styles.subscriptionPlans}>
			<SubscriptionPlan title="Free" description="We will always offer these features to everyone for free!" amount={0}>
				<div className={styles.featureTitle}>Free Features</div>
				<div className={styles.features}>
					<div>
						<img src={tick} />
						<div>Organize your products into collections</div>
					</div>
					<div>
						<img src={tick} />
						<div>Track the market value of your collections and products</div>
					</div>
					<div>
						<img src={tick} />
						<div>View recent Ebay sales data</div>
					</div>
					<div>
						<img src={tick} />
						<div>Import up to 5000 rows of CSV</div>
					</div>
					<div>
						<img src={tick} />
						<div>Set your preferred currency for pricing information</div>
					</div>
					<div>
						<img src={tick} />
						<div>Buy your favourite products through TCGplayer</div>
					</div>
					<div>
						<img src={tick} />
						<div>Regularly updated pricing information from TCGplayer</div>
					</div>
				</div>
			</SubscriptionPlan>

			<SubscriptionPlan title="Premium" subscribeButton={() => setCreateModalVisible(true)} description="Upgrade your experience with these premium features!" amount={4.99} discounted={true}>
				<div className={styles.featureTitle}>Additional Features</div>
				<div className={styles.features}>
					<div>
						<img src={tick} />
						<div>View the Estimated Value (EV) for sealed product</div>
					</div>
					<div>
						<img src={tick} />
						<div>View latest market trends by product</div>
					</div>
					<div>
						<img src={tick} />
						<div>View your total invested value</div>
					</div>
					<div>
						<img src={tick} />
						<div>View potential profits made on your products</div>
					</div>
					<div>
						<img src={tick} />
						<div>Track profit and loss through sales</div>
					</div>
					<div>
						<img src={tick} />
						<div>View the cash position for any of your collections</div>
					</div>
					<div>
						<img src={tick} />
						<div>Historical graphs for all your collection data</div>
					</div>
					<div>
						<img src={tick} />
						<div>Export your collections in CSV format</div>
					</div>
					<div>
						<img src={tick} />
						<div>Unlimited CSV imports</div>
					</div>
					<div>
						<img src={tick} />
						<div>Direct our future by participating in feature polls</div>
					</div>
					<div>
						<img src={tick} />
						<div>Share your collections publicly</div>
					</div>
				</div>
			</SubscriptionPlan>
		</div>
	);
};

const LoadingDisplay = (): React.JSX.Element => {
	return (
		<Container>
			<ContainerSegment> </ContainerSegment>
		</Container>
	);
};

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import compareLight from "@fyendalscollection/app/assets/compare-light.svg";
import compareDark from "@fyendalscollection/app/assets/compare-dark.svg";
import mergeLight from "@fyendalscollection/app/assets/merge-light.svg";
import mergeDark from "@fyendalscollection/app/assets/merge-dark.svg";
import oneLight from "@fyendalscollection/app/assets/one-light.svg";
import oneDark from "@fyendalscollection/app/assets/one-dark.svg";
import dollarLight from "@fyendalscollection/app/assets/dollar-light.svg";
import dollarDark from "@fyendalscollection/app/assets/dollar-dark.svg";
import massDark from "@fyendalscollection/app/assets/mass-dark.svg";
import massLight from "@fyendalscollection/app/assets/mass-light.svg";
import styles from "./PageCollectionOperations.module.scss";
import duplicateLight from "@fyendalscollection/app/assets/duplicate-light.svg";
import duplicateDark from "@fyendalscollection/app/assets/duplicate-dark.svg";
import { Theme, useTheme } from "@fyendalscollection/app/lib/ThemeContext";
import { ModalTcgPlayerMassEntryOperation } from "@fyendalscollection/app/features/operations/components/ModalTcgPlayerMassEntryOperation";
import tcgPlayerLogo from "@fyendalscollection/app/assets/TCGplayer-logo.png";
import { useCollection, useOperationDuplicate } from "@fyendalscollection/app/shared";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { Limit } from "@fyendalscollection/app/lib/components/Limit";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { CollectionTab, CollectionTabSelector } from "@fyendalscollection/app/features/collections/components/CollectionTabSelector";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { ContainerListItem } from "@fyendalscollection/app/lib/components/ContainerListItem";
import { LinkButton } from "@fyendalscollection/app/lib/components/LinkButton";
import { ModalCompareOperation } from "@fyendalscollection/app/features/operations/components/ModalCompareOperation";
import { ModalMergeOperation } from "@fyendalscollection/app/features/operations/components/ModalMergeOperation";
import { ModalConfirmation } from "@fyendalscollection/app/lib/components/ModalConfirmation";
import { ModalNormaliseQuantityOperation } from "@fyendalscollection/app/features/operations/components/ModalNormaliseQuantityOperation";
import { ModalNormaliseUnitAmountOperation } from "@fyendalscollection/app/features/operations/components/ModalNormaliseUnitAmountOperation";

export const PageCollectionOperations = (): React.JSX.Element => {
	const theme = useTheme();
	const [modalCompareOperationVisible, setModalCompareOperationVisible] = useState(false);
	const [modalMergeOperationVisible, setModalMergeOperationVisible] = useState(false);
	const [modalDuplicateOperationVisible, setModalDuplicateOperationVisible] = useState(false);
	const [modalNormaliseQuantityOperationVisible, setModalNormaliseQuantityOperationVisible] = useState(false);
	const [modalNormaliseUnitAmountOperationVisible, setModalNormaliseUnitAmountOperationVisible] = useState(false);
	const [modalTcgPlayerMassEntryLink, setModalTcgPlayerMassEntryLink] = useState(false);
	const collectionId = useParams()["collectionId"] as string;
	const collection = useCollection(collectionId);
	const duplicateOperation = useOperationDuplicate();
	const navigate = useNavigate();

	useTitle(collection.data ? collection.data.name : "Collection");

	useEffect(() => {
		if (duplicateOperation.data) {
			navigate(`/collections/${duplicateOperation.data.body.collectionId}`);
		}
	}, [duplicateOperation.data]);

	return (
		<Limit>
			<Loadable loading={collection.isLoading || duplicateOperation.isPending}>
				{collection.data && (
					<>
						<Breadcrumb crumbs={[
							{
								text: "Dashboard",
								link: "/dashboard"
							},
							{
								text: "Collections",
								link: "/collections"
							},
							{
								text: collection.data.name,
								link: `/collections/${collectionId}`
							},
							{
								text: "Tools"
							}
						]} />

						<PageTitle
							title={collection.data.name}
							subTitle={collection.data.description}
							primaryActions={<CollectionTabSelector selected={CollectionTab.Tools} />} />

						{
							duplicateOperation.isError && (
								<Container>
									<ContainerSegment>
										<ErrorDisplay problemResponse={duplicateOperation.error}/>
									</ContainerSegment>
								</Container>
							)
						}

						<div className={styles.limit}>
							<Container>
								<ContainerSegment onClick={() => setModalTcgPlayerMassEntryLink(true)}>
									<ContainerListItem
										title="Buy with TCGplayer"
										image={tcgPlayerLogo}
										subtitle="Purchase this collection with TCGplayer">
										<LinkButton text="Buy" onClick={() => undefined} />
									</ContainerListItem>
								</ContainerSegment>
								<ContainerSegment onClick={() => navigate("../transactions/bulk")}>
									<ContainerListItem
										title="Mass Transaction Entry"
										image={theme === Theme.Light ? massLight : massDark}
										subtitle="Record a box opening or trade with ease">
										<LinkButton text="Mass Entry" onClick={() => undefined} />
									</ContainerListItem>
								</ContainerSegment>
								<ContainerSegment onClick={() => setModalCompareOperationVisible(true)}>
									<ContainerListItem
										title="Compare Collection"
										image={theme === Theme.Light ? compareLight : compareDark}
										subtitle="See the differences between another collection">
										<LinkButton text="Compare" onClick={() => undefined} />
									</ContainerListItem>
								</ContainerSegment>
								<ContainerSegment onClick={() => setModalDuplicateOperationVisible(true)}>
									<ContainerListItem
										title="Duplicate Collection"
										image={theme === Theme.Light ? duplicateLight : duplicateDark}
										subtitle="Duplicate an existing collection">
										<LinkButton text="Duplicate" onClick={() => undefined} />
									</ContainerListItem>
								</ContainerSegment>
								<ContainerSegment onClick={() => setModalMergeOperationVisible(true)}>
									<ContainerListItem
										title="Merge Collection"
										image={theme === Theme.Light ? mergeLight : mergeDark}
										subtitle="Add another collection's transactions into this collection">
										<LinkButton text="Merge" onClick={() => undefined} />
									</ContainerListItem>
								</ContainerSegment>
								<ContainerSegment onClick={() => setModalNormaliseQuantityOperationVisible(true)}>
									<ContainerListItem
										title="Normalize Quantity"
										image={theme === Theme.Light ? oneLight : oneDark}
										subtitle="Set all transaction quantities to a specific value">
										<LinkButton text="Normalize" onClick={() => undefined} />
									</ContainerListItem>
								</ContainerSegment>
								<ContainerSegment onClick={() => setModalNormaliseUnitAmountOperationVisible(true)}>
									<ContainerListItem
										title="Normalize Unit Amount"
										image={theme === Theme.Light ? dollarLight : dollarDark}
										subtitle="Set all transaction unit amounts to a specific value">
										<LinkButton text="Normalize" onClick={() => undefined} />
									</ContainerListItem>
								</ContainerSegment>
							</Container>
						</div>

						{
							collection.data && (
								<>
									<ModalCompareOperation
										visible={modalCompareOperationVisible}
										onCancel={() => setModalCompareOperationVisible(false)}
										collection={collection.data} />

									<ModalMergeOperation
										visible={modalMergeOperationVisible}
										onCancel={() => setModalMergeOperationVisible(false)}
										collection={collection.data} />

									<ModalConfirmation
										title="Duplicate Collection"
										description="Are you sure you want to duplicate this collection?"
										visible={modalDuplicateOperationVisible}
										onCancel={() => setModalDuplicateOperationVisible(false)}
										onConfirm={() => duplicateOperation.mutate(collectionId)} />

									<ModalNormaliseQuantityOperation
										visible={modalNormaliseQuantityOperationVisible}
										onCancel={() => setModalNormaliseQuantityOperationVisible(false)}
										collectionId={collection.data.collectionId} />

									<ModalNormaliseUnitAmountOperation
										visible={modalNormaliseUnitAmountOperationVisible}
										onCancel={() => setModalNormaliseUnitAmountOperationVisible(false)}
										collectionId={collection.data.collectionId} />

									<ModalTcgPlayerMassEntryOperation
										visible={modalTcgPlayerMassEntryLink}
										onCancel={() => setModalTcgPlayerMassEntryLink(false)}
										collectionId={collectionId} />
								</>
							)
						}
					</>
				)}
			</Loadable>
		</Limit>
	);
};

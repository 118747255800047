import styles from "./Select.module.scss";

export interface SelectProps<T> {
	options: SelectOption<T>[];
	onChange?: (value: T) => void;
	value: T;
	className?: string;
}

export interface SelectOption<T> {
	text: string;
	value: T;
}

export const Select = <T,>(props: SelectProps<T>): React.JSX.Element => {
	const classNamesOption: string[] = [];
	const classNamesSelect: string[] = [styles.select];

	if (props.className) {
		classNamesOption.push(props.className);
		classNamesSelect.push(props.className);
	}

	if (!props.onChange) {
		classNamesSelect.push(styles.disabled);
	}

	const options = props.options.map((x, i) => <option key={i} value={i} className={classNamesOption.join(" ")}>{x.text}</option>);
	const selectedIndex = props.options.findIndex(x => x.value === props.value);

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		if (!props.onChange) {
			return;
		}
		props.onChange(props.options[e.target.selectedIndex].value);
	};

	return (
		<select
			className={classNamesSelect.join(" ")}
			value={selectedIndex} onChange={handleChange}>
			{options}
		</select>
	);
};

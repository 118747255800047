import { MouseEvent } from "react";
import styles from "./NotificationsList.module.scss";
import openEnvelopeLight from "@fyendalscollection/app/assets/open-envelope-light.svg";
import openEnvelopeDark from "@fyendalscollection/app/assets/open-envelope-dark.svg";
import { Link } from "react-router-dom";
import { ThemedImage } from "@fyendalscollection/app/lib/components/ThemedImage";
import { useMarkNotificationRead, useNotifications } from "@fyendalscollection/app/shared";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";

export interface NotificationsListProps {
	notificationsLimit: boolean;
	onNotificationClick?: () => void;
}

export const NotificationsList = (props: NotificationsListProps): React.JSX.Element => {
	const notifications = useNotifications();

	const sourceData = notifications.data ? notifications.data.pages.flatMap(x => x.results) : [];
	const data = props.notificationsLimit ? sourceData.slice(0, 5) : sourceData;

	if (notifications.isError) {
		return (
			<Container>
				<ContainerSegment>
					<ErrorDisplay problemResponse={notifications.error}/>
				</ContainerSegment>
			</Container>
		);
	}

	if (data.length < 1 && notifications.isSuccess) {
		return (
			<Container>
				<ContainerSegment>
					You don&apos;t have any notifications yet!  Check back soon!
				</ContainerSegment>
			</Container>
		);
	}

	return (
		<Loadable loading={notifications.isLoading}>
			<Container>
				{
					notifications.isLoading &&
					<div style={{ height: "64px", minWidth: "200px" }}></div>
				}
				{
					data.map((x) => (
						<ContainerSegment key={x.notificationId}>
							<Link onClick={props.onNotificationClick} to={`/notifications/${x.notificationId}`} >
								<NotificationItem {...x} />
							</Link>
						</ContainerSegment>
					))
				}
			</Container>
			<Container transparent={true}><></></Container>
		</Loadable>
	);
};

interface NotificationItemProps {
	notificationId: string;
	subject: string;
	readAt: string | null;
}

const NotificationItem = ({ notificationId, subject, readAt }: NotificationItemProps): React.JSX.Element => {
	const markNotificationRead = useMarkNotificationRead();

	const onMarkRead = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		markNotificationRead.mutate(notificationId);
	};

	const readIndicatorClassNames = [styles.readIndicator];
	if (!readAt) {
		readIndicatorClassNames.push(styles.unread);
	}

	const subjectClassNames = [styles.subject];
	if (!readAt) {
		subjectClassNames.push(styles.unread);
	}

	return (
		<div className={styles.notificationItem}>
			<div className={readIndicatorClassNames.join(" ")}></div>
			<div className={subjectClassNames.join(" ")}>{subject}</div>
			<div className={styles.gap}></div>
			{!readAt && <ThemedImage srcLight={openEnvelopeLight} srcDark={openEnvelopeDark} onClick={onMarkRead} />}
		</div>
	);
};

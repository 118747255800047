import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import styles from "./PageCsvTutorial.module.scss";
import csvIcon from "@fyendalscollection/app/assets/csv.png";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ContainerListItem } from "@fyendalscollection/app/lib/components/ContainerListItem";
import { LinkButton } from "@fyendalscollection/app/lib/components/LinkButton";

export const PageCsvTutorial = (): React.JSX.Element => {
	useTitle("CSV Import and Export");

	return (
		<Limit force={LimitWidth.Large}>
			<PageTitle title="CSV Import and Export" subTitle="Using Fyendal's Collection CSV features" />

			<Container title="CSV Example">
				<ContainerSegment>
					We recommend downloading this CSV to use as a reference.  It contains a few example transactions for some Welcome to Rathe Ninja cards.
				</ContainerSegment>
				<ContainerSegment>
					<ContainerListItem image={csvIcon} title="csv-example-1.csv" subtitle="644 B">
						<LinkButton text="Download" newTab={true} link="https://fyendalscollection-static.s3.us-west-1.amazonaws.com/csv-example-1.csv" />
					</ContainerListItem>
				</ContainerSegment>
			</Container>

			<Container title="CSV Import">
				<ContainerSegment>
					When importing a CSV, the following fields are supported:
				</ContainerSegment>
				<div className={styles.tableHolder}>
					<table className={styles.table}>
						<thead>
							<tr>
								<th>Field</th>
								<th>Required</th>
								<th>Default Value</th>
								<th>Notes</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Quantity</td>
								<td>No</td>
								<td>1</td>
								<td>The quantity of product that was purchased or sold.</td>
							</tr>
							<tr>
								<td>Identifier</td>
								<td>Yes</td>
								<td></td>
								<td>The identifier for the product that was purchased or sold e.g. WTR000-1ST-CF.</td>
							</tr>
							<tr>
								<td>Description</td>
								<td>No</td>
								<td>(Ignored)</td>
								<td>This field can be uploaded, but will be ignored.  Only the identifier is considered.</td>
							</tr>
							<tr>
								<td>Action</td>
								<td>No</td>
								<td>Purchase</td>
								<td>Indicates if the transaction was a Purchase or a Sale.</td>
							</tr>
							<tr>
								<td>UnitAmount</td>
								<td>Yes</td>
								<td></td>
								<td>The price that each unit was purchased/sold for.</td>
							</tr>
							<tr>
								<td>Currency</td>
								<td>No</td>
								<td>USD</td>
								<td>The currency that was used to complete the transaction.</td>
							</tr>
							<tr>
								<td>Date</td>
								<td>No</td>
								<td>Today&apos;s Date</td>
								<td>Needs to be in dd MMM yyyy format e.g. 28 Jul 2023.</td>
							</tr>
						</tbody>
					</table>
				</div>
			</Container>
		</Limit>
	);
};

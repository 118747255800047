import maintenance from "@fyendalscollection/app/assets/maintenance.svg";
import { BannerMessage } from "@fyendalscollection/app/lib/components/BannerMessage";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";

export const PageMaintenance = (): React.JSX.Element => {
	return (
		<>
			<Container>
				<ContainerSegment>
					<BannerMessage image={maintenance} title="Undergoing Maintenance" content="Fyendal's Collection is undergoing maintenance and will be unavailable for a short while." />
				</ContainerSegment>
				<ContainerSegment>
					Click the &quot;Try Again&quot; button below to reload the site.  If you land back here, it means we&apos;re still working on stuff!
				</ContainerSegment>
			</Container>

			<Container transparent={true}>
				<GreenButtonSet buttons={[
					{
						text: "Try Again",
						link: "/"
					}
				]} />
			</Container>
		</>
	);
};

import { useUserPreferencesState } from "@fyendalscollection/app/shared";
import styles from "./PerformanceDeltasFilter.module.scss";
import { Currency, PerformanceDeltaSet, PerformanceDeltaSort, PerformanceDeltaStep, getCurrencySymbol, usePreferredCurrency } from "@fyendalscollection/app/shared";
import { StyledSelector } from "@fyendalscollection/app/lib/components/StyledSelector";
import { StyledSelect } from "@fyendalscollection/app/lib/components/StyledSelect";
import { NumericEntry } from "@fyendalscollection/app/lib/components/NumericEntry";

export interface PerformanceDeltasFilterProps {
	includeSets?: boolean;
}

export const PerformanceDeltasFilter = ({ includeSets }: PerformanceDeltasFilterProps): React.JSX.Element => {
	const {
		state,
		dispatchSetPerformanceDeltaStep,
		dispatchSetPerformanceDeltaMinimum,
		dispatchSetPerformanceDeltaSort,
		dispatchSetPerformanceDeltaSets
	} = useUserPreferencesState();

	const {
		performanceDeltaStep,
		performanceDeltaMinimum,
		performanceDeltaSort,
		performanceDeltaSets
	} = state;

	const preferredCurrency = usePreferredCurrency();

	return (
		<>
			{
				includeSets && (
					<StyledSelector
						values={performanceDeltaSets}
						onChange={dispatchSetPerformanceDeltaSets}
						optionsDescription={"set"}
						selectAll={true}
						options={[
							{
								text: "Welcome to Rathe 1st Edition",
								value: PerformanceDeltaSet.Wtr1stEdition
							},
							{
								text: "Welcome to Rathe Unlimited",
								value: PerformanceDeltaSet.WtrUnlimitedEdition
							},
							{
								text: "Arcane Rising 1st Edition",
								value: PerformanceDeltaSet.Arc1stEdition
							},
							{
								text: "Arcane Rising Unlimited",
								value: PerformanceDeltaSet.ArcUnlimitedEdition
							},
							{
								text: "Crucible of War 1st Edition",
								value: PerformanceDeltaSet.Cru1stEdition
							},
							{
								text: "Crucible of War Unlimited",
								value: PerformanceDeltaSet.CruUnlimitedEdition
							},
							{
								text: "Monarch 1st Edition",
								value: PerformanceDeltaSet.Mon1stEdition
							},
							{
								text: "Monarch Unlimited",
								value: PerformanceDeltaSet.MonUnlimitedEdition
							},
							{
								text: "Tales of Aria 1st Edition",
								value: PerformanceDeltaSet.Ele1stEdition
							},
							{
								text: "Tales of Aria Unlimited",
								value: PerformanceDeltaSet.EleUnlimitedEdition
							},
							{
								text: "Everfest 1st Edition",
								value: PerformanceDeltaSet.Evr1stEdition
							},
							{
								text: "Uprising",
								value: PerformanceDeltaSet.Upr
							},
							{
								text: "Dynasty",
								value: PerformanceDeltaSet.Dyn
							},
							{
								text: "Outsiders",
								value: PerformanceDeltaSet.Out
							},
							{
								text: "Dusk till Dawn",
								value: PerformanceDeltaSet.Dtd
							},
							{
								text: "Bright Lights",
								value: PerformanceDeltaSet.Evo
							},
							{
								text: "Heavy Hitters",
								value: PerformanceDeltaSet.Hvy
							},
							{
								text: "Part the Mistveil",
								value: PerformanceDeltaSet.Mst
							},
							{
								text: "Rosetta",
								value: PerformanceDeltaSet.Ros
							},
							{
								text: "The Hunted",
								value: PerformanceDeltaSet.Hnt
							},
							{
								text: "Local Game Store Promos",
								value: PerformanceDeltaSet.Lgs
							},
							{
								text: "Hero Card Promos",
								value: PerformanceDeltaSet.Her
							},
							{
								text: "Tournament Legal Promos",
								value: PerformanceDeltaSet.Fab
							},
							{
								text: "Judge Promos",
								value: PerformanceDeltaSet.Jdg
							},
							{
								text: "Non-Tournament Legal Promos",
								value: PerformanceDeltaSet.Lss
							}
						]} />
				)
			}

			<StyledSelect value={performanceDeltaSort} onChange={dispatchSetPerformanceDeltaSort} options={[
				{ text: "Sort: Percentage Gain", value: PerformanceDeltaSort.PercentageGain },
				{ text: "Sort: Percentage Loss", value: PerformanceDeltaSort.PercentageLoss },
				{ text: "Sort: Flat Gain", value: PerformanceDeltaSort.FlatGain },
				{ text: "Sort: Flat Loss", value: PerformanceDeltaSort.FlatLoss },
				{ text: "Sort: Highest Sales", value: PerformanceDeltaSort.HighestSales }
			]} />

			<StyledSelect value={performanceDeltaStep} onChange={dispatchSetPerformanceDeltaStep} options={[
				{ text: "Time: 24 Hours", value: PerformanceDeltaStep.Step24Hours },
				{ text: "Time: 3 Days", value: PerformanceDeltaStep.Step3Days },
				{ text: "Time: 7 Days", value: PerformanceDeltaStep.Step7Days },
				{ text: "Time: 14 Days", value: PerformanceDeltaStep.Step14Days },
				{ text: "Time: 1 Month", value: PerformanceDeltaStep.Step1Month },
				{ text: "Time: 3 Months", value: PerformanceDeltaStep.Step3Months },
				{ text: "Time: 6 Months", value: PerformanceDeltaStep.Step6Months },
				{ text: "Time: 1 Year", value: PerformanceDeltaStep.Step1Year }
			]} />

			<div className={styles.configHolder}>
				<div className={styles.text}>MIN {getCurrencySymbol(preferredCurrency.data ? preferredCurrency.data.currency : Currency.USD)}</div>
				<NumericEntry
					value={performanceDeltaMinimum}
					onChange={dispatchSetPerformanceDeltaMinimum}
					maxDecimalPlaces={0}
					minValue={0}
					maxValue={500000000} />
			</div>
		</>
	);
};

import { useState } from "react";
import { Link } from "react-router-dom";
import { TabularData, TabularDataHeaderStyle } from "@fyendalscollection/app/lib/components/TabularData";
import { TabularDataItem } from "@fyendalscollection/app/lib/components/TabularDataItem";
import { InfiniteScroller } from "@fyendalscollection/app/lib/components/InfiniteScroller";
import { ModalCurrency } from "@fyendalscollection/app/lib/components/ModalCurrency";
import rubbishBinIconLight from "@fyendalscollection/app/assets/rubbish-bin-light.svg";
import rubbishBinIconDark from "@fyendalscollection/app/assets/rubbish-bin-dark.svg";
import errorBubbleIcon from "@fyendalscollection/app/assets/error-bubble.svg";
import currencyExchangeIcon from "@fyendalscollection/app/assets/currency-exchange.svg";
import styles from "./PagePriceOverrides.module.scss";
import { ThemedImage } from "@fyendalscollection/app/lib/components/ThemedImage";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { CallToAction } from "@fyendalscollection/app/lib/components/CallToAction";
import { ProductImage } from "@fyendalscollection/app/lib/components/ProductImage";
import { ModalCreatePriceOverride } from "@fyendalscollection/app/features/settings/components/ModalCreatePriceOverrride";
import { usePriceOverride } from "@fyendalscollection/app/features/settings/hooks/usePriceOverride";
import { PriceOverrideDetailsModel, usePriceOverrideDelete, usePriceOverrideDetails } from "@fyendalscollection/app/shared";
import { NumericEntry } from "@fyendalscollection/app/lib/components/NumericEntry";
import { SearchPanelUsage, SearchPanelVariant, useSearchPanel } from "@fyendalscollection/app/lib/useSearchPanel";

export const PagePriceOverrides = (): React.JSX.Element => {
	useTitle("Price Overrides");

	const [variant, setVariant] = useState<SearchPanelVariant | null>(null);
	const showSearchPanel = useSearchPanel(SearchPanelUsage.SingleVariant, setVariant);

	const priceOverrideDetails = usePriceOverrideDetails();

	const display = priceOverrideDetails.data &&
		priceOverrideDetails.data.pages.flatMap(x => x.results).map(priceOverride => (
			<PriceOverrideListItem key={priceOverride.priceOverrideId} priceOverride={priceOverride} />
		));

	return (
		<Loadable loading={priceOverrideDetails.isLoading}>
			<Limit force={LimitWidth.Medium}>

				<Breadcrumb crumbs={[{
					text: "Settings",
					link: "/settings"
				},
				{
					text: "Price Overrides"
				}]}/>

				<PageTitle
					title="Price Overrides"
					subTitle="Manage your custom price overrides"
					primaryActions={
						<GreenButtonSet buttons={[
							{
								text: "Add Price Override",
								onClick: showSearchPanel
							}
						]} />
					} />

				{
					priceOverrideDetails.isError && (
						<Container>
							<ContainerSegment>
								<ErrorDisplay problemResponse={priceOverrideDetails.error}/>
							</ContainerSegment>
						</Container>
					)
				}

				{
					display && display.length === 0 && (
						<CallToAction
							title="Add your first price override!"
							subtitle="Price overrides will be used when we calculate the value of your collections"
							buttonText="Add Price Override"
							onClick={showSearchPanel} />
					)
				}

				{
					display && display.length > 0 && (
						<TabularData headerStyle={TabularDataHeaderStyle.CenteredExceptFirst} headers={["Product", "Override", "Currency", "Remove"]}>
							{display}
						</TabularData>
					)
				}

				<InfiniteScroller forQuery={priceOverrideDetails}/>
			</Limit>

			<ModalCreatePriceOverride
				variant={variant}
				onCancel={() => setVariant(null)} />
		</Loadable>
	);
};

interface PriceOverrideListItemProps {
	priceOverride: PriceOverrideDetailsModel;
}

const PriceOverrideListItem = ({ priceOverride }: PriceOverrideListItemProps): React.JSX.Element => {
	const [price, setPrice] = useState(priceOverride.price.value);
	const [currency, setCurrency] = useState(priceOverride.price.currency);
	const [currencyModalVisible, setCurrencyModalVisible] = useState(false);

	const priceOverrideUpdate = usePriceOverride(price, currency, priceOverride);
	const priceOverrideDelete = usePriceOverrideDelete();

	const handleRemove = () => {
		priceOverrideDelete.mutate(priceOverride.priceOverrideId);
	};

	return (
		<TabularDataItem>
			<>
				<Loadable loading={priceOverrideUpdate.isPending || priceOverrideDelete.isPending}>
					<Link className={styles.productDisplay} to={`/browse/product/${priceOverride.productId}`}>
						<ProductImage className={styles.productImage} image={priceOverride.imageUrl} />
						<div>
							<div>{priceOverride.productName}</div>
							<div>{priceOverride.fcId}</div>
						</div>
					</Link>
				</Loadable>
			</>
			<>
				<NumericEntry
					value={price}
					onChange={setPrice}
					maxDecimalPlaces={2}
					minValue={0}
					maxValue={500000} />
			</>
			<>
				<div className={styles.currency} onClick={() => setCurrencyModalVisible(true)}>
					<img src={currencyExchangeIcon} />
					<div>{priceOverride.price.currency}</div>
				</div>
				<ModalCurrency
					visible={currencyModalVisible}
					onCancel={() => setCurrencyModalVisible(false)}
					value={currency}
					onChange={setCurrency} />
			</>
			<>
				<div className={styles.iconHolder}>
					{
						priceOverrideUpdate.isError || priceOverrideDelete.isError ? (
							<img className={styles.errorIcon} src={errorBubbleIcon} />
						) : (
							<ThemedImage className={styles.removeIcon} srcLight={rubbishBinIconLight} srcDark={rubbishBinIconDark} onClick={handleRemove} />
						)
					}
				</div>
			</>
		</TabularDataItem>
	);
};

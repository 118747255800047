import { useEffect, useRef } from "react";
import { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query";
import styles from "./InfiniteScroller.module.scss";
import { PaginatedResponseModel, ProblemResponse } from "@fyendalscollection/app/shared";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";

export interface InfiniteScrollerProps<T> {
	forQuery: UseInfiniteQueryResult<InfiniteData<PaginatedResponseModel<T>, number>, ProblemResponse>;
	scrollRef?: React.RefObject<HTMLDivElement | null>;
}

export const InfiniteScroller = <T,>({ forQuery, scrollRef }: InfiniteScrollerProps<T>): React.JSX.Element => {
	const ref = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		let hasCalledForNextPage = false;
		const scrollElement = scrollRef && scrollRef.current;

		const windowScrollHandler = () => {
			if (!ref.current || forQuery.isFetchingNextPage || hasCalledForNextPage) {
				return;
			}

			const elementBounds = ref.current.getBoundingClientRect();
			const portionVisible = Math.min(window.innerHeight - elementBounds.y, elementBounds.height) / elementBounds.height;
			const isVisible = portionVisible > 0.2;

			if (isVisible && forQuery.hasNextPage) {
				hasCalledForNextPage = true;
				forQuery.fetchNextPage();
			}
		};

		if (scrollElement) {
			scrollElement.addEventListener("scroll", windowScrollHandler);
			windowScrollHandler();
			return () => scrollElement.removeEventListener("scroll", windowScrollHandler);
		} else {
			window.addEventListener("scroll", windowScrollHandler);
			windowScrollHandler();
			return () => window.removeEventListener("scroll", windowScrollHandler);
		}

	}, [forQuery.hasNextPage, forQuery.fetchNextPage, forQuery.data]);

	const classNames = [styles.infiniteScroller];
	if (!forQuery.hasNextPage) {
		classNames.push(styles.hidden);
	}

	return (
		<Loadable loading={forQuery.hasNextPage || false}>
			<div ref={ref} className={classNames.join(" ")}></div>
		</Loadable>
	);
};

import { useEffect, useState } from "react";
import styles from "./ModalCreatePriceOverrride.module.scss";
import { Currency,  usePreferredCurrency, usePriceOverrideCreate, usePriceOverrideDetails } from "@fyendalscollection/app/shared";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";
import { Paragraph } from "@fyendalscollection/app/lib/components/Paragraph";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ContainerListItem } from "@fyendalscollection/app/lib/components/ContainerListItem";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { NumericEntry } from "@fyendalscollection/app/lib/components/NumericEntry";
import { SearchPanelVariant } from "@fyendalscollection/app/lib/useSearchPanel";

export interface ModalCreatePriceOverrideProps {
	variant: SearchPanelVariant | null;
	onCancel: () => void;
}

export const ModalCreatePriceOverride = ({ variant, onCancel }: ModalCreatePriceOverrideProps): React.JSX.Element => {
	const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

	const preferredCurrency = usePreferredCurrency();
	const priceOverrideCreate = usePriceOverrideCreate();

	const onConfirm = (priceValue: number) => {
		setErrorMessage(undefined);

		if (variant && !isNaN(priceValue)) {
			priceOverrideCreate.mutate({
				variantId: variant.variantId,
				price: {
					currency: preferredCurrency.data ? preferredCurrency.data.currency : Currency.USD,
					value: priceValue
				}
			});
		}
	};

	useEffect(() => {
		if (priceOverrideCreate.isSuccess) {
			onCancel();
		}
	}, [priceOverrideCreate.isSuccess]);

	useEffect(() => {
		if (priceOverrideCreate.error) {
			setErrorMessage(priceOverrideCreate.error.problem.description);
		}
	}, [priceOverrideCreate.error]);

	useEffect(() => setErrorMessage(undefined), [variant]);

	return (
		<Modal visible={!!variant} onCancel={onCancel}>
			<Loadable loading={priceOverrideCreate.isPending}>
				<div className={styles.modal}>
					<div>
						<TitleSecondary text="Add Price Override" />

						<Paragraph>
							Price overrides will be used instead of market prices when we calculate the value of your collections
						</Paragraph>
					</div>

					<ErrorDisplay message={errorMessage} />

					{
						variant &&
						<PriceSourceConfigurer variant={variant} onConfirm={onConfirm} />
					}
				</div>
			</Loadable>
		</Modal>
	);
};

interface PriceSourceConfigurerProps {
	variant: SearchPanelVariant;
	onConfirm: (price: number) => void;
}

const PriceSourceConfigurer = ({ variant, onConfirm }: PriceSourceConfigurerProps): React.JSX.Element => {
	const [price, setPrice] = useState(variant.marketPrice?.value || 0);
	const priceOverrideDetails = usePriceOverrideDetails();

	const isDuplicate = !!(priceOverrideDetails.data &&
		priceOverrideDetails.data.pages
			.flatMap(x => x.results)
			.find((priceOverride) => priceOverride.variantId == variant.variantId));

	return (
		<>
			<Container>
				<ContainerSegment>
					<ContainerListItem
						title={variant.productName}
						subtitle={variant.fcId}
						image={variant.imageUrl}>
					</ContainerListItem>
				</ContainerSegment>

				<ContainerSegment>
					<ContainerListItem
						title={!isDuplicate ? "Price Override:" : (
							<ContainerSegment>
								<ErrorDisplay message="You already have a price override for this variant"/>
							</ContainerSegment>
						)}>
						<div className={isDuplicate ? styles.disabled : ""}>
							<NumericEntry
								value={price}
								onChange={setPrice}
								maxDecimalPlaces={2}
								minValue={0}
								maxValue={500000} />
						</div>
					</ContainerListItem>
				</ContainerSegment>
			</Container>

			<GreenButtonSet buttons={[
				{
					text: "Confirm",
					onClick: () => onConfirm(price),
					disabled: isDuplicate
				}
			]} />
		</>
	);
};

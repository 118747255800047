import { useEffect, useState } from "react";
import { InfiniteScroller } from "@fyendalscollection/app/lib/components/InfiniteScroller";
import { CollectionsSortOrder, useCollections, useSetGoalCollection } from "@fyendalscollection/app/shared";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { ContainerListItemRadioButton } from "@fyendalscollection/app/lib/components/ContainerListItemRadioButton";

export interface ModalConfigureGoalCollectionProps {
	collectionId: string;
	visible: boolean;
	onCancel: () => void;
}

export const ModalConfigureGoalCollection = ({ collectionId, visible, onCancel }: ModalConfigureGoalCollectionProps): React.JSX.Element => {
	const collections = useCollections(CollectionsSortOrder.Alphabetical);
	const setGoalCollection = useSetGoalCollection();
	const [selectedCollectionId, setSelectedCollectionId] = useState<string | null>(null);

	useEffect(() => {
		if (!visible) {
			return;
		}

		setSelectedCollectionId(null);
	}, [visible]);

	useEffect(() => {
		if (!setGoalCollection.isSuccess) {
			return;
		}

		onCancel();
	}, [setGoalCollection.isSuccess]);

	const onConfirm = () => {
		if (!selectedCollectionId) {
			return;
		}

		setGoalCollection.mutate({
			collectionId,
			requestModel: {
				goalCollectionId: selectedCollectionId
			}
		});
	};

	return (
		<Modal visible={visible} onCancel={onCancel}>
			<Loadable loading={collections.isLoading || setGoalCollection.isPending}>
				<TitleSecondary text="Goal Collection" />

				Fyendal&apos;s Collection allows you to track your progress towards
				completing your goals by setting another collection as your
				&quot;goal state&quot;.
				<br /><br />
				Please select the collection you would like to use as the goal for
				this collection.
				<br /><br />

				{
					collections.error &&
					<Container>
						<ContainerSegment>
							<ErrorDisplay problemResponse={collections.error} />
						</ContainerSegment>
					</Container>
				}

				{
					setGoalCollection.error &&
					<Container>
						<ContainerSegment>
							<ErrorDisplay problemResponse={setGoalCollection.error} />
						</ContainerSegment>
					</Container>
				}

				{
					collections.data &&
					<Container>
						{
							collections.data.pages.flatMap(x => x.results).filter(x => x.collectionId !== collectionId).map(x => (
								<ContainerSegment key={x.collectionId}>
									<ContainerListItemRadioButton
										title={x.name}
										selected={selectedCollectionId === x.collectionId}
										onClick={() => setSelectedCollectionId(x.collectionId)} />
								</ContainerSegment>
							))
						}
					</Container>
				}

				<InfiniteScroller forQuery={collections} />

				<GreenButtonSet buttons={[
					{
						text: "Confirm",
						disabled: !selectedCollectionId,
						onClick: onConfirm
					}
				]} />
			</Loadable>
		</Modal>
	);
};

import { EmblemRarity } from "@fyendalscollection/app/shared";

export const getColorForEmblemRarity = (rarity: EmblemRarity): string => {
	switch (rarity) {
	case EmblemRarity.Rare:
		return "#124AED";
	case EmblemRarity.Majestic:
		return "#ED2A12";
	case EmblemRarity.Legendary:
		return "#DD9945";
	case EmblemRarity.Fabled:
		return "#5AAA3E";
	default:
		return "#666666";
	}
};

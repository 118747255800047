import { MutationFunction } from "@tanstack/react-query";
import { HTTPAuthMode, Response, useHTTP, useHTTPEnvironment, useSimpleMutation } from "../../http";

export interface AuthLoginRequestModel {
	emailAddress: string;
	password: string;
	mfaCode?: string;
}

export type AuthLoginResponseModel = {
	emailVerified: false;
} | {
	emailVerified: true;
	accessToken?: string;
};

export const useAuthLogin = () => {
	const http = useHTTP(HTTPAuthMode.None);
	const httpEnvironment = useHTTPEnvironment();

	const mutFunc: MutationFunction<Response<AuthLoginResponseModel>, AuthLoginRequestModel> = requestModel => {
		return http<AuthLoginResponseModel, AuthLoginRequestModel>({
			method: "POST",
			url: "/auth/login",
			body: requestModel
		});
	};

	return useSimpleMutation(mutFunc, {
		onSuccess: data => {
			if (data.body.emailVerified && data.body.accessToken) {
				httpEnvironment.setAccessToken(data.body.accessToken);
			}
		}
	});
};

import { useState } from "react";
import styles from "./PageEvTracker.module.scss";
import { InfiniteScroller } from "@fyendalscollection/app/lib/components/InfiniteScroller";
import { useUserPreferencesState } from "@fyendalscollection/app/shared";
import { TabularData } from "@fyendalscollection/app/lib/components/TabularData";
import { TabularDataItem } from "@fyendalscollection/app/lib/components/TabularDataItem";
import { Link } from "react-router-dom";
import { EvProductType, EvSort, EvStep, useEvItems } from "@fyendalscollection/app/shared";
import { useSubscription } from "@fyendalscollection/app/shared";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { Limit } from "@fyendalscollection/app/lib/components/Limit";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { LinkButton } from "@fyendalscollection/app/lib/components/LinkButton";
import { EvTrackerFilter } from "@fyendalscollection/app/features/ev/components/EvTrackerFilter";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";
import { PremiumPromotionContainer } from "@fyendalscollection/app/lib/components/PremiumPromotionContainer";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { ProductImage } from "@fyendalscollection/app/lib/components/ProductImage";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";

export const PageEvTracker = (): React.JSX.Element => {
	useTitle("EV Tracker");

	const { state } = useUserPreferencesState();

	const {
		evStep,
		evSort,
		evProductType
	} = state;

	const subscription = useSubscription();
	const [helpModal, setHelpModal] = useState(false);

	return (
		<Limit>
			<Breadcrumb crumbs={[
				{
					text: "Dashboard",
					link: "/dashboard"
				},
				{
					text: "EV Tracker"
				}
			]} />

			<PageTitle
				title="EV Tracker"
				subTitle="Track the estimated value of various Flesh and Blood sets"
				primaryActions={
					<>
						<LinkButton text="Help" onClick={() => setHelpModal(true)}/>
						<EvTrackerFilter />
					</>
				} />

			<Modal visible={helpModal} onCancel={() => setHelpModal(false)}>
				<TitleSecondary text="EV Tracker" />
				<ul>
					<li><b>Current Estimated Value</b> - Our calculated estimated value of the sealed product using TCGplayer pricing.</li>
					<br/>
					<li><b>Market Value</b> - The current TCGplayer market price of the sealed product.</li>
					<br/>
					<li><b>Difference</b> - The difference between the Current Estimated Value and the Market Value.</li>
					<br/>
					<li><b>Previous Estimated Value</b> - The previous estimated value using the Time period selected.</li>
					<br/>
					<li><b>Change</b> - The change in estimated value from the Previous Estimated Value to now.</li>
				</ul>
			</Modal>

			{
				subscription.data && (
					subscription.data.activeSubscription
						? <PageEvTrackerInternal step={evStep} sort={evSort} productType={evProductType} />
						: <PremiumPromotionContainer featureName="EV Tracker" />
				)
			}
		</Limit>
	);
};

interface PageEVTrackerInternal {
	step: EvStep;
	sort: EvSort;
	productType: EvProductType | null;
}

const PageEvTrackerInternal = ({ step, sort, productType }: PageEVTrackerInternal): React.JSX.Element => {
	const evItems = useEvItems(step, sort, productType);

	return (
		<Loadable loading={evItems.isLoading}>
			<TabularData headers={["Product", "Current Estimated Value", "Market Value", "Difference", "Previous Estimated Value", "Change"]}>
				{
					(!evItems.data ? [] : evItems.data.pages).flatMap(x => x.results).map(x => (
						<TabularDataItem key={x.productId}>
							<>
								<Link className={styles.deltaDisplay} to={`/ev-tracker/breakdown/${x.setType}/${x.productType}`}>
									<ProductImage className={styles.productImage} image={x.productImage} />
									<div>
										<div>{x.productName}</div>
										<div>{x.fcId}</div>
									</div>
								</Link>
							</>
							<><PrettyMoney className={styles.amount} money={x.currentEstimatedValue} mode={MoneyDisplayMode.Standard} /></>
							<><PrettyMoney className={styles.amount} money={x.marketPrice} mode={MoneyDisplayMode.Standard} /></>
							<><PrettyMoney className={styles.amount} money={x.marketPriceDelta} mode={MoneyDisplayMode.DeltaPercentage} originalValue={x.marketPrice.value}/></>
							<><PrettyMoney className={styles.amount} money={x.previousEstimatedValue} mode={MoneyDisplayMode.Standard} /></>
							<><PrettyMoney className={styles.amount} money={x.delta} mode={MoneyDisplayMode.DeltaPercentage} originalValue={x.previousEstimatedValue.value}/></>
						</TabularDataItem>
					))
				}
			</TabularData>
			<InfiniteScroller forQuery={evItems} />
		</Loadable>
	);
};

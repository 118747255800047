import { useState } from "react";
import styles from "./PageTitle.module.scss";
import { Emblem } from "@fyendalscollection/app/features/emblems/components/Emblem";
import { EmblemRarity } from "@fyendalscollection/app/shared";
import { LinkButton } from "@fyendalscollection/app/lib/components/LinkButton";

export interface PageTitleProps {
	title: string;
	subTitle: string;
	primaryActions?: React.ReactNode;
	secondaryActions?: React.ReactNode;
	emblem?: EmblemDetails;
}

export interface EmblemDetails {
	emblemId: string;
	count: number;
	rarity: EmblemRarity;
}

export const PageTitle = (props: PageTitleProps): React.JSX.Element => {
	const [secondaryActionsVisible, setSecondaryActionsVisible] = useState(false);

	const classNames = [styles.secondaryActions];
	if (secondaryActionsVisible) {
		classNames.push(styles.visible);
	}

	return (
		<div className={styles.pageTitle}>
			<div className={styles.pageTitleAndPrimaryActions}>
				<div className={styles.titles}>
					{
						props.emblem &&
						<div className={styles.emblem}>
							<Emblem emblemId={props.emblem.emblemId} count={props.emblem.count} rarity={props.emblem.rarity} />
						</div>
					}

					<div>
						<div className={styles.title}>{props.title}</div>
						<div className={styles.subTitle}>{props.subTitle}</div>
					</div>
				</div>

				{
					props.primaryActions &&
					<div>
						<div className={styles.primaryActions}>{props.primaryActions}</div>
					</div>
				}
			</div>
			<div className={styles.secondaryActionsContainer}>
				{
					props.secondaryActions &&
					<>
						<div className={classNames.join(" ")}>{props.secondaryActions}</div>
						<div className={styles.hideShowText}><LinkButton text={secondaryActionsVisible ? "Hide additional options" : "Show additional options"} onClick={() => setSecondaryActionsVisible(!secondaryActionsVisible)}/></div>
					</>
				}
			</div>
		</div>
	);
};

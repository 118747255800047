import { createContext } from "react";
import styles from "./TabularData.module.scss";
import { InfiniteData, UseInfiniteQueryResult } from "@tanstack/react-query";
import { InfiniteScroller } from "@fyendalscollection/app/lib/components/InfiniteScroller";
import { PaginatedResponseModel, ProblemResponse } from "@fyendalscollection/app/shared";

export const TabularDataContext = createContext<string[]>([ ]);

export interface TabularDataProps<T> {
	headers: string[];
	headerStyle?: TabularDataHeaderStyle;
	children: React.ReactNode;
	infiniteScrollerQuery?: UseInfiniteQueryResult<InfiniteData<PaginatedResponseModel<T>, number>, ProblemResponse>;
}

export enum TabularDataHeaderStyle {
	LeftAligned,
	CenteredExceptFirst
}

export const TabularData = <T,>(props: TabularDataProps<T>): React.JSX.Element => {
	const {
		headers,
		headerStyle = TabularDataHeaderStyle.LeftAligned,
		children,
		infiniteScrollerQuery
	} = props;

	const classNames = [ styles.tabularData ];
	if (headerStyle === TabularDataHeaderStyle.CenteredExceptFirst) {
		classNames.push(styles.centeredExceptFirst);
	}

	return (
		<TabularDataContext.Provider value={headers}>
			<div className={classNames.join(" ")}>
				<div>
					{
						headers.map(x => <div key={x}>{x}</div>)
					}
				</div>
				{children}
			</div>

			<div>
				{
					infiniteScrollerQuery &&
					<InfiniteScroller forQuery={infiniteScrollerQuery} />
				}
			</div>
		</TabularDataContext.Provider>
	);
};

import styles from "./Banner.module.scss";
import close from "@fyendalscollection/app/assets/close.svg";

export interface BannerProps {
	title: string;
	text: string;
	onClick?: () => void;
	onCancel?: () => void;
	isError?: boolean;
}

export const Banner = (props: BannerProps): React.JSX.Element => {
	const closeImage = props.onCancel ? <img onClick={props.onCancel} src={close}/> : <></>;

	const titleClassNames = props.onClick
		? styles.title + " " + styles.pointer
		: styles.title;

	const textClassNames = props.onClick
		? styles.text + " " + styles.pointer
		: styles.text;

	const gapClassNames = props.onClick
		? styles.gap + " " + styles.pointer
		: styles.gap;

	const bannerClassNames = props.isError
		? styles.banner + " " + styles.error
		: styles.banner;

	return (
		<div className={bannerClassNames}>
			<div>
				<div>
					<div onClick={props.onClick} className={titleClassNames}>{props.title}</div>
					<div onClick={props.onClick} className={textClassNames}>{props.text}</div>
				</div>
				<div onClick={props.onClick} className={gapClassNames}></div>
				{closeImage}
			</div>
		</div>
	);
};

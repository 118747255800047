
import { UseMutationResult, UseQueryResult } from "@tanstack/react-query";
import { ProblemResponse } from "@fyendalscollection/app/shared";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";

export interface SmartBoxProps<T> {
	switchOn: UseQueryResult<T, ProblemResponse> | UseMutationResult<T, ProblemResponse>;
	loading?: () => React.JSX.Element;
	success?: (data: T) => React.JSX.Element;
	error?: (error: ProblemResponse) => React.JSX.Element;
}

export const SmartBox = <T, >(props: SmartBoxProps<T>): React.JSX.Element => {
	const {
		switchOn,
		loading,
		success,
		error
	} = props;

	if (loading && (("isLoading" in switchOn && switchOn.isLoading) || ("isPending" in switchOn && switchOn.isPending))) {
		return loading();
	}

	if (success && switchOn.isSuccess) {
		return success(switchOn.data);
	}

	if (switchOn.isError) {
		return error
			? error(switchOn.error)
			: <ErrorDisplay problemResponse={switchOn.error}/>;
	}

	return <></>;
};

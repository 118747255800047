import { useState, useEffect } from "react";
import csvIcon from "@fyendalscollection/app/assets/csv.png";
import styles from "./PageImport.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { FileSelector } from "@fyendalscollection/app/lib/components/FileSelector";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { GetImportResponseModelInvalidRow, GetImportStatusResponseModelImport, useAbortImport, useCollection, useConfirmImport, useImportPreflight, useImportStatus, useImportUpload, useUpdateImportRow } from "@fyendalscollection/app/shared";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { ContainerListItem } from "@fyendalscollection/app/lib/components/ContainerListItem";
import { LinkButton } from "@fyendalscollection/app/lib/components/LinkButton";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { ErrorIndicator } from "@fyendalscollection/app/lib/components/ErrorIndicator";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";

export const PageImport = (): React.JSX.Element => {
	useTitle("Import CSV");

	const collectionId = useParams()["collectionId"];
	const importStatus = useImportStatus(collectionId);
	const abortImport = useAbortImport();

	const importId = importStatus.data?.import?.importId;

	return (
		<Limit force={LimitWidth.Massive}>
			{
				collectionId
					? <HeaderWithCollection collectionId={collectionId} />
					: <HeaderWithoutCollection />
			}

			{
				importStatus.error &&
				<Container>
					<ContainerSegment>
						<ErrorDisplay problemResponse={importStatus.error} />
					</ContainerSegment>
				</Container>
			}

			{
				importStatus.data && !importId && <ImportUpload collectionId={collectionId} />
			}

			{
				importStatus.data?.import &&
				<Loadable loading={abortImport.isPending}>
					<ImportEditor import={importStatus.data.import} />
				</Loadable>
			}
		</Limit>
	);
};

interface ImportUploadProps {
	collectionId: string | undefined;
}

const ImportUpload = ({ collectionId }: ImportUploadProps): React.JSX.Element => {
	const importPreflight = useImportPreflight();
	const importUpload = useImportUpload();
	const [file, setFile] = useState<File | null>(null);

	useEffect(() => {
		if (!file || importPreflight.isPending) {
			return;
		}

		getHeadersLineOfFile(file, headers => {
			if (!headers) {
				setFile(null);
				return;
			}

			importPreflight.mutate({
				collectionId,
				requestModel: {
					headers
				}
			});
		});
	}, [file]);

	useEffect(() => {
		if (!importPreflight.data || !file) {
			return;
		}

		importUpload.mutate({
			csvImportToken: importPreflight.data.body.csvImportToken,
			file: file
		});
	}, [importPreflight.data]);

	useEffect(() => {
		if (!importPreflight.error && !importUpload.error) {
			return;
		}

		setFile(null);
	}, [importPreflight.error, importUpload.error]);

	return (
		<Loadable loading={importPreflight.isPending || importUpload.isPending}>
			<Container title="CSV Upload">
				<ContainerSegment>
					<FileSelector file={file} onFileSelected={setFile} accept="text/csv" />
				</ContainerSegment>

				{
					importPreflight.error &&
					<ContainerSegment>
						<ErrorDisplay problemResponse={importPreflight.error} />
					</ContainerSegment>
				}

				{
					importUpload.error &&
					<ContainerSegment>
						<ErrorDisplay problemResponse={importUpload.error} />
					</ContainerSegment>
				}
			</Container>

			<Container title="Help">
				<ContainerSegment>
					The easiest way to get started with CSV Import is to download our CSV template file below.  It contains a row for every product we support with a quantity of 0.  You can read a more in-depth explanation of our format by <a className={styles.link} target="_blank" rel="noreferrer" href="/collections/csv-tutorial">clicking here</a>.
				</ContainerSegment>
				<ContainerSegment>
					<ContainerListItem image={csvIcon} title="csv-import-template.csv" subtitle="672 kB">
						<LinkButton text="Download" newTab={true} link="https://cdn.fyendalscollection.com/csv-import-template.csv" />
					</ContainerListItem>
				</ContainerSegment>
			</Container>
		</Loadable>
	);
};

interface ImportEditorProps {
	import: GetImportStatusResponseModelImport;
}

const ImportEditor = (props: ImportEditorProps): React.JSX.Element => {
	const abortImport = useAbortImport();
	const confirmImport = useConfirmImport();
	const navigate = useNavigate();

	useEffect(() => {
		if (!confirmImport.data) {
			return;
		}

		navigate(`/collections/${confirmImport.data.body.collectionId}`);
	}, [confirmImport.data]);

	return (
		<Loadable loading={abortImport.isPending || confirmImport.isPending}>
			<Container title="Import Status">
				<ContainerSegment>
					<div className={styles.importStatus}>
						<div>✅ {props.import.validRowCount} Valid Rows</div>
						<div>❌ {props.import.invalidRowCount} Invalid Rows</div>
					</div>
				</ContainerSegment>
				{
					props.import.invalidRowCount === 0 &&
					<ContainerSegment>
						💚 All rows in your uploaded CSV document are valid.
					</ContainerSegment>
				}
				{
					props.import.invalidRowCount > 0 &&
					<ContainerSegment>
						💔 Some rows in your uploaded CSV document are invalid and require fixing.  You can fix them below, or abort the import and try again later.
					</ContainerSegment>
				}
			</Container>

			{
				confirmImport.error &&
				<Container>
					<ContainerSegment>
						<ErrorDisplay problemResponse={confirmImport.error} />
					</ContainerSegment>
				</Container>
			}

			{
				props.import.invalidRowCount > 0 &&
				<Container title="Edit Invalid Rows">
					<div className={styles.csvPreviewer}>
						<table>
							<tbody>
								<tr>
									<th>Quantity</th>
									<th>Identifier</th>
									<th>Action</th>
									<th>Unit Amount</th>
									<th>Currency</th>
									<th>Date</th>
									<th></th>
								</tr>
								<ImportEditorRow row={props.import.invalidRows[0]} />
								{
									props.import.invalidRows.slice(1).map(x => <ImportDisplayRow key={x.importRowId} row={x} />)
								}
							</tbody>
						</table>
					</div>
				</Container>
			}

			<div className={styles.buttonHolder}>
				<GreenButtonSet buttons={[
					{
						text: "Confirm",
						disabled: props.import.invalidRowCount !== 0,
						onClick: () => confirmImport.mutate(props.import.importId)
					},
					{
						text: "Abort",
						onClick: () => abortImport.mutate()
					}
				]} />
			</div>
		</Loadable>
	);
};

interface ImportEditorRowProps {
	row: GetImportResponseModelInvalidRow;
}

const ImportEditorRow = ({ row }: ImportEditorRowProps): React.JSX.Element => {
	const updateImportRow = useUpdateImportRow();
	const [quantityText, setQuantityText] = useState(row.quantityText);
	const [fcIdText, setFcIdText] = useState(row.fcIdText);
	const [actionText, setActionText] = useState(row.actionText);
	const [unitAmountText, setUnitAmountText] = useState(row.unitAmountText);
	const [currencyText, setCurrencyText] = useState(row.currencyText);
	const [dateText, setDateText] = useState(row.dateText);

	useEffect(() => {
		setQuantityText(row.quantityText);
		setFcIdText(row.fcIdText);
		setActionText(row.actionText);
		setUnitAmountText(row.unitAmountText);
		setCurrencyText(row.currencyText);
		setDateText(row.dateText);
	}, [row]);

	const hasChanges = quantityText !== row.quantityText ||
		fcIdText !== row.fcIdText ||
		actionText !== row.actionText ||
		unitAmountText !== row.unitAmountText ||
		currencyText !== row.currencyText ||
		dateText !== row.dateText;

	const onClick = () => {
		if (!hasChanges) {
			return;
		}

		updateImportRow.mutate({
			importRowId: row.importRowId,
			quantityText,
			fcIdText,
			actionText,
			unitAmountText,
			currencyText,
			dateText
		});
	};

	return (
		<tr>
			<td>
				<div className={styles.rowInput}>
					<input type="text" value={quantityText} onChange={e => setQuantityText(e.target.value)} />
					{
						row.quantityError && quantityText === row.quantityText &&
						<ErrorIndicator error={row.quantityError} />
					}
				</div>
			</td>
			<td>
				<div className={styles.rowInput}>
					<input type="text" value={fcIdText} onChange={e => setFcIdText(e.target.value)} />
					{
						row.fcIdError && fcIdText === row.fcIdText &&
						<ErrorIndicator error={row.fcIdError} />
					}
				</div>
			</td>
			<td>
				<div className={styles.rowInput}>
					<input type="text" value={actionText} onChange={e => setActionText(e.target.value)} />
					{
						row.actionError && actionText === row.actionText &&
						<ErrorIndicator error={row.actionError} />
					}
				</div>
			</td>
			<td>
				<div className={styles.rowInput}>
					<input type="text" value={unitAmountText} onChange={e => setUnitAmountText(e.target.value)} />
					{
						row.unitAmountError && unitAmountText === row.unitAmountText &&
						<ErrorIndicator error={row.unitAmountError} />
					}
				</div>
			</td>
			<td>
				<div className={styles.rowInput}>
					<input type="text" value={currencyText} onChange={e => setCurrencyText(e.target.value)} />
					{
						row.currencyError && currencyText === row.currencyText &&
						<ErrorIndicator error={row.currencyError} />
					}
				</div>
			</td>
			<td>
				<div className={styles.rowInput}>
					<input type="text" value={dateText} onChange={e => setDateText(e.target.value)} />
					{
						row.dateError && dateText === row.dateText &&
						<ErrorIndicator error={row.dateError} />
					}
				</div>
			</td>
			<td>
				<LinkButton text="Save Changes" disabled={!hasChanges || updateImportRow.isPending} onClick={onClick} />
			</td>
		</tr>
	);
};

interface ImportDisplayRowProps {
	row: GetImportResponseModelInvalidRow;
}

const ImportDisplayRow = ({ row }: ImportDisplayRowProps): React.JSX.Element => {
	return (
		<tr>
			<td>
				<div className={styles.displayCell}>
					<div>{row.quantityText}</div>
					{
						row.quantityError &&
						<ErrorIndicator error={row.quantityError} />
					}
				</div>
			</td>
			<td>
				<div className={styles.displayCell}>
					<div>{row.fcIdText}</div>
					{
						row.fcIdError &&
						<ErrorIndicator error={row.fcIdError} />
					}
				</div>
			</td>
			<td>
				<div className={styles.displayCell}>
					<div>{row.actionText}</div>
					{
						row.actionError &&
						<ErrorIndicator error={row.actionError} />
					}
				</div>
			</td>
			<td>
				<div className={styles.displayCell}>
					<div>{row.unitAmountText}</div>
					{
						row.unitAmountError &&
						<ErrorIndicator error={row.unitAmountError} />
					}
				</div>
			</td>
			<td>
				<div className={styles.displayCell}>
					<div>{row.currencyText}</div>
					{
						row.currencyError &&
						<ErrorIndicator error={row.currencyError} />
					}
				</div>
			</td>
			<td>
				<div className={styles.displayCell}>
					<div>{row.dateText}</div>
					{
						row.dateError &&
						<ErrorIndicator error={row.dateError} />
					}
				</div>
			</td>
			<td></td>
		</tr>
	);
};

interface HeaderWithCollectionProps {
	collectionId: string;
}

const HeaderWithCollection = ({ collectionId }: HeaderWithCollectionProps): React.JSX.Element => {
	const collection = useCollection(collectionId);

	return (
		<>
			<Breadcrumb crumbs={[
				{
					text: "Dashboard",
					link: "/dashboard"
				},
				{
					text: "Collections",
					link: "/collections"
				},
				{
					text: collection.data ? collection.data.name : "Collection",
					link: `/collections/${collectionId}`
				},
				{
					text: "Import"
				}
			]} />

			<PageTitle
				title="Import CSV"
				subTitle="Append transactions to your existing collection from a CSV file" />
		</>
	);
};

const HeaderWithoutCollection = (): React.JSX.Element => {
	return (
		<>
			<Breadcrumb crumbs={[
				{
					text: "Dashboard",
					link: "/dashboard"
				},
				{
					text: "Collections",
					link: "/collections"
				},
				{
					text: "Import"
				}
			]} />

			<PageTitle
				title="Import CSV"
				subTitle="Create a new collection from a CSV file" />
		</>
	);
};

const getHeadersLineOfFile = (file: File, callback: (headers: string | null) => void) => {
	const reader = new FileReader();

	reader.addEventListener("load", e => {
		if (!e.target || typeof e.target.result !== "string") {
			return;
		}

		const lines = e.target.result.split("\n");
		callback(lines[0]);
	});

	reader.addEventListener("error", () => callback(null));
	reader.readAsText(file);
};

import clipboardLight from "@fyendalscollection/app/assets/clipboard-light.svg";
import clipboardDark from "@fyendalscollection/app/assets/clipboard-dark.svg";
import styles from "./ModalShareableLink.module.scss";
import { ThemedImage } from "@fyendalscollection/app/lib/components/ThemedImage";
import { useShareableLink } from "@fyendalscollection/app/shared";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";
import { Paragraph } from "@fyendalscollection/app/lib/components/Paragraph";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";

export interface ModalShareableLinkProps {
	visible: boolean;
	onCancel: () => void;
	collectionId: string;
}

export const ModalShareableLink = (props: ModalShareableLinkProps): React.JSX.Element => {
	return (
		<Modal visible={props.visible} onCancel={props.onCancel}>
			<TitleSecondary text="Share collection" />

			<Paragraph>
				The link below can be used to view the contents of this collection publicly.
				<br/>
				<br/>
				Only market values and quantities are displayed.  No statistics around investments, gains or profits are ever public.
			</Paragraph>

			{
				props.visible &&
				<ShareableLinkBox collectionId={props.collectionId} />
			}
		</Modal>
	);
};

interface ShareableLinkBoxProps {
	collectionId: string;
}

const ShareableLinkBox = (props: ShareableLinkBoxProps): React.JSX.Element => {
	const shareableLink = useShareableLink(props.collectionId);

	const copyToClipboard = async (copyText: string) => {
		await navigator.clipboard.writeText(copyText);
	};

	return (
		<Loadable loading={shareableLink.isLoading}>
			{
				shareableLink.isError &&
				<Container>
					<ContainerSegment>
						<ErrorDisplay problemResponse={shareableLink.error}/>
					</ContainerSegment>
				</Container>
			}

			{
				shareableLink.data &&
				<Container>
					<ContainerSegment>
						<div className={styles.shareableLinkContainer}>
							<div>{shareableLink.data.shareableLink}</div>
							<ThemedImage srcLight={clipboardLight} srcDark={clipboardDark} onClick={() => copyToClipboard(shareableLink.data.shareableLink)} />
						</div>
					</ContainerSegment>
				</Container>
			}
		</Loadable>
	);
};

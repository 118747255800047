import crown from "@fyendalscollection/app/assets/crown.svg";
import styles from "./PremiumPromotionContainer.module.scss";
import { Link } from "react-router-dom";

export interface PremiumPromotionContainerProps {
	featureName: string;
}

export const PremiumPromotionContainer = ({ featureName }: PremiumPromotionContainerProps): React.JSX.Element => {
	return (
		<Link to="/settings/subscription" className={styles.premiumPromotionContainer}>
			<div>
				<img src={crown} />
				<div>
					<div>{featureName} is for Premium Users</div>
					<div>
						Become a Premium User now and access {featureName} plus
						heaps of other features!
					</div>
				</div>
				<div className={styles.linkButton}>Subscribe Now</div>
			</div>
		</Link>
	);
};

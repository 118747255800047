import dayjs from "dayjs";
import { useEffect } from "react";
import { Currency, TransactionAction, TransactionDetailsModel, TransactionUpdateRequestModel, useTransactionUpdate } from "@fyendalscollection/app/shared";

export const useTransaction = (
	quantity: number,
	action: TransactionAction,
	unitAmount: number,
	currency: Currency,
	date: Date,
	collectionId: string,
	transaction: TransactionDetailsModel
) => {

	const updateTransaction = useTransactionUpdate();

	const performTransactionUpdate = (requestModel: TransactionUpdateRequestModel) => {
		updateTransaction.mutate(
			{
				requestModel: requestModel,
				transactionId: transaction.transactionId,
				collectionId: collectionId,
				variantId: transaction.variantId
			}
		);
	};

	useEffect(() => {
		const oldOccurredDate = dayjs(transaction.occurred).format("YYYY-MM-DD");
		const newOccurredDate = dayjs(date).format("YYYY-MM-DD");

		const requestModel: TransactionUpdateRequestModel = {
			occurredDate: newOccurredDate !== oldOccurredDate ? newOccurredDate : undefined,
			action: action != transaction.action ? action : undefined,
			quantity: quantity != transaction.quantity ? quantity : undefined,
			unitAmount: roundNumber2dp(unitAmount) != roundNumber2dp(transaction.unitAmount.value) || currency != transaction.unitAmount.currency
				? {
					value: roundNumber2dp(unitAmount),
					currency: currency
				}
				: undefined
		};

		if (requestModel.occurredDate == undefined &&
			requestModel.action == undefined &&
			requestModel.quantity == undefined &&
			requestModel.unitAmount == undefined) {
			return;
		}

		const timer = setTimeout(() => {
			if (isNaN(quantity) || isNaN(unitAmount)) {
				return;
			}

			performTransactionUpdate(requestModel);
		}, 500);

		return () => clearTimeout(timer);
	}, [quantity, action, unitAmount, currency, date]);

	return updateTransaction;
};

const roundNumber2dp = (num: number): number => {
	return Math.round(num * 100) / 100;
};

import React, { useContext } from "react";

export interface HTTPEnvironment {
	getAccessToken: () => string | null;
	setAccessToken: (accessToken: string) => void;
	enableMaintenanceMode: () => void;
	getApiBaseUrl: () => string;
}

export interface HTTPEnvironmentProviderProps {
	httpEnvironment: HTTPEnvironment;
	children: React.ReactNode;
}

export const HTTPEnvironmentProvider = ({ httpEnvironment: environment, children }: HTTPEnvironmentProviderProps): React.JSX.Element => {
	return (
		<HttpEnvironmentContext.Provider value={environment}>
			{children}
		</HttpEnvironmentContext.Provider>
	);
};

export const HttpEnvironmentContext = React.createContext<HTTPEnvironment | null>(null);
export const useHTTPEnvironment = () => useContext(HttpEnvironmentContext) as HTTPEnvironment;

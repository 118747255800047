import React from "react";
import "@fyendalscollection/app/styles/base.scss";
import Utc from "dayjs/plugin/utc";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Router } from "./Router";
import { ScreenSizeProvider } from "./lib/ScreenSizeContext";
import dayjs from "dayjs";
import { ThemeProvider } from "./lib/ThemeContext";
import { STRIPE_PUBLISHABLE_KEY } from "@fyendalscollection/app/lib/config";
import { AuthStateProvider, BrowserStorageService, HTTPEnvironmentProvider, UserPreferencesStateProvider } from "@fyendalscollection/app/shared";
import { useAppHTTPEnvironment } from "@fyendalscollection/app/lib/useAppHTTPEnvironment";
import { StorageProvider } from "@fyendalscollection/app/shared";
import { SearchPanelSubscriptionManager, SearchPanelProvider } from "@fyendalscollection/app/lib/useSearchPanel";

dayjs.extend(Utc);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			staleTime: 300 * 1000
		}
	}
});

const searchPanelManager = new SearchPanelSubscriptionManager();

const browserStorageService = new BrowserStorageService();
const stripeLoadResult = loadStripe(STRIPE_PUBLISHABLE_KEY);

const ELEMENT_OPTIONS = {
	fonts: [
		{
			cssSrc: "https://fonts.googleapis.com/css2?family=Rubik:wght@400;600;700&display=swap"
		}
	]
};

const Inner = (): React.JSX.Element => (
	<HTTPEnvironmentProvider httpEnvironment={useAppHTTPEnvironment()}>
		<Router />
	</HTTPEnvironmentProvider>
);

const Main = (): React.JSX.Element => (
	<React.StrictMode>
		<Elements stripe={stripeLoadResult} options={ELEMENT_OPTIONS}>
			<BrowserRouter>
				<QueryClientProvider client={queryClient}>
					<SearchPanelProvider manager={searchPanelManager}>
						<StorageProvider storageService={browserStorageService}>
							<ThemeProvider>
								<ScreenSizeProvider>
									<AuthStateProvider>
										<UserPreferencesStateProvider>
											<Inner />
										</UserPreferencesStateProvider>
									</AuthStateProvider>
								</ScreenSizeProvider>
							</ThemeProvider>
						</StorageProvider>
					</SearchPanelProvider>
				</QueryClientProvider>
			</BrowserRouter>
		</Elements>
	</React.StrictMode>
);

ReactDOM.createRoot(document.getElementById("root")!).render(<Main />);

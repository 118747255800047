import styles from "./Container.module.scss";

export interface ContainerProps {
	title?: string;
	transparent?: boolean;
	children: React.ReactNode;
}

export const Container = ({ title, transparent, children }: ContainerProps): React.JSX.Element => {
	const classNames = [ styles.container ];
	if (transparent) {
		classNames.push(styles.transparent);
	}

	return (
		<>
			{title && <div className={styles.title}>{title}</div>}
			<div className={classNames.join(" ")}>
				{children}
			</div>
		</>
	);
};

export interface ContainerSegmentProps {
	children: React.ReactNode;
	padding?: boolean;
	onClick?: () => void;
}

export const ContainerSegment = ({ children, padding = true, onClick }: ContainerSegmentProps): React.JSX.Element => {
	const classNames = [ styles.containerSegment ];
	if (padding) {
		classNames.push(styles.padding);
	}
	if (onClick) {
		classNames.push(styles.onClick);
	}

	return (
		<div className={classNames.join(" ")} onClick={onClick}>
			{children}
		</div>
	);
};

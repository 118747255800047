import visa from "@fyendalscollection/app/assets/visa.svg";
import mastercard from "@fyendalscollection/app/assets/mastercard.svg";
import amex from "@fyendalscollection/app/assets/amex.svg";
import card from "@fyendalscollection/app/assets/card.svg";

export const getPaymentCardProviderImage = (provider: string) => {
	switch (provider) {
	case "visa":
		return visa;
	case "mastercard":
		return mastercard;
	case "amex":
		return amex;
	default:
		return card;
	}
};

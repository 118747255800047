import { QueryClient } from "@tanstack/react-query";

export const clearCacheAfterTransactionMutation = (queryClient: QueryClient, collectionId: string, variantId?: string) => {
	queryClient.invalidateQueries({ queryKey: ["transactionSummaries"] });
	queryClient.invalidateQueries({ queryKey: ["collections"] });
	queryClient.invalidateQueries({ queryKey: ["collection", collectionId] });
	queryClient.invalidateQueries({ queryKey: ["dashboardCurrentPosition"] });
	queryClient.invalidateQueries({ queryKey: ["dashboardHistoricalPositions"] });
	queryClient.invalidateQueries({ queryKey: ["progressSummaries"] });
	queryClient.invalidateQueries({ queryKey: ["collectionProgress"] });
	queryClient.invalidateQueries({ queryKey: ["findInCollections"] });

	if (variantId) {
		queryClient.invalidateQueries({ queryKey: ["collectionVariantDetails", collectionId, variantId] });
	}
};

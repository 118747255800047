import { HTTPAuthMode, useHTTP, useSimpleQuery } from "../../http";
import { HistoricalPositionModel } from "./models/HistoricalPositionModel";

export const useCollectionHistoricalPositions = (collectionId: string, days: number, dateStr: string) => {
	const http = useHTTP(HTTPAuthMode.Required);
	return useSimpleQuery(["collection", collectionId, days, dateStr], () => {
		return http<HistoricalPositionModel[]>({
			method: "GET",
			url: `/collection/${collectionId}/historical-positions?days=${days}&date=${dateStr}`
		});
	}, { placeholderData: x => x });
};

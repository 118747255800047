import { useEffect, useState } from "react";
import { useAuthState } from "@fyendalscollection/app/shared";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { useFocus } from "@fyendalscollection/app/lib/useFocus";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { TextEntry } from "@fyendalscollection/app/lib/components/TextEntry";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { useAuthChangePassword } from "@fyendalscollection/app/shared";

export const PageChangePassword = (): React.JSX.Element => {
	useTitle("Change Password");

	const authChangePassword = useAuthChangePassword();

	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");

	const [ref, focus] = useFocus();

	const { dispatchAuthLoggedOut } = useAuthState();

	// Focus the currentPassword TextEntry when we land on the page.
	useEffect(() => focus(), []);

	useEffect(() => {
		if (authChangePassword.isSuccess) {
			dispatchAuthLoggedOut();
		}
	}, [authChangePassword.isSuccess]);

	const currentPasswordValid = currentPassword.length > 7;
	const newPasswordValid = newPassword.length > 7;
	const confirmPasswordValid = !!confirmNewPassword && confirmNewPassword === newPassword;

	const formValid = currentPasswordValid && newPasswordValid && confirmPasswordValid;

	return (
		<Loadable loading={authChangePassword.isPending}>
			<Limit force={LimitWidth.Tiny}>

				<Breadcrumb crumbs={[{
					text: "Settings",
					link: "/settings"
				},
				{
					text: "Change Password"
				}]}/>

				<PageTitle
					title="Change Password"
					subTitle="Please note that you will be logged out after changing your password." />

				<form>
					<Container>
						<ContainerSegment>
							<TextEntry
								ref={ref}
								placeholder="Current Password"
								type="password"
								value={currentPassword}
								onChange={setCurrentPassword}
								newPassword={true} />
						</ContainerSegment>

						<ContainerSegment>
							<TextEntry
								placeholder="New Password"
								valid={newPasswordValid}
								type="password"
								value={newPassword}
								onChange={setNewPassword}
								newPassword={true} />
						</ContainerSegment>

						<ContainerSegment>
							<TextEntry
								placeholder="Confirm New Password"
								valid={confirmPasswordValid}
								type="password"
								value={confirmNewPassword}
								onChange={setConfirmNewPassword}
								newPassword={true} />
						</ContainerSegment>

						{authChangePassword.isError && <ContainerSegment>
							<ErrorDisplay problemResponse={authChangePassword.error}/>
						</ContainerSegment>}
					</Container>

					<ContainerSegment>
						<GreenButtonSet buttons={[
							{
								text: "Confirm",
								disabled: !formValid,
								onClick: () => authChangePassword.mutate({
									currentPassword,
									newPassword
								})
							}
						]} />
					</ContainerSegment>
				</form>
			</Limit>
		</Loadable>
	);
};

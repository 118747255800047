import React, { useCallback, useMemo, useState } from "react";
import styles from "./AutoCompleteBox.module.scss";

export interface AutoCompleteBoxProps {
	options: string[];
	value: string | null;
	placeholder: string;
	onChange: (option: string | null) => void;
}

export const AutoCompleteBox = ({ options, value, placeholder, onChange }: AutoCompleteBoxProps): React.JSX.Element => {
	const [text, setText] = useState("");
	const [hasFocus, setHasFocus] = useState(false);
	const autoSuffix = useMemo(() => {
		if (!text || !hasFocus) {
			return "";
		}

		const firstMatch = options.find(x => x.toLowerCase().indexOf(text.toLowerCase()) === 0);
		if (!firstMatch) {
			return "";
		}

		return firstMatch.substring(text.length);
	}, [text, hasFocus]);

	const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key !== "Enter" && e.key !== "Tab") {
			return;
		}

		e.preventDefault();
		e.stopPropagation();

		const firstMatch = options.find(x => x.toLowerCase().indexOf(text.toLowerCase()) === 0);
		if (!firstMatch) {
			return;
		}

		setText("");
		onChange(firstMatch);
	}, [text, options]);

	return (
		<div className={styles.autoCompleteBox}>
			{
				!value &&
				<>
					<input value={text} placeholder={placeholder} onFocus={() => setHasFocus(true)} onBlur={() => setHasFocus(false)} onChange={e => setText(e.target.value)} onKeyDown={onKeyDown} />
					<div className={styles.autoComplete}>
						<div>{text}</div>
						<div>{autoSuffix}</div>
					</div>
				</>
			}
			{
				value &&
				<div className={styles.selectedValue} onClick={() => onChange(null)}>
					{value}
				</div>
			}
		</div>
	);
};

import { Theme, useTheme } from "@fyendalscollection/app/lib/ThemeContext";
import { useMemo } from "react";

const GRAPH_WIDTH = 160;
const GRAPH_HEIGHT = 40;
const GRAPH_SAFE_AREA_SIZE = 12;

export interface VolumeGraphProps {
	data: number[];
	className?: string;
}

export const VolumeGraph = ({ data, className }: VolumeGraphProps): React.JSX.Element => {
	const theme = useTheme();
	const [graphColor, textColor] = useMemo(() => theme === Theme.Dark ? ["#67AA4F", "#FFFFFF55"] : ["#5AAA3E", "#00000055"], [theme]);
	const [svgPath, maxVolume] = useMemo(() => getSvgPathAndMaxVolume(data), [data]);
	const totalSales = useMemo(() => data.reduce((x, y) => x + y), [data]);
	const averageSalesPerDay = useMemo(() => Math.round((totalSales / Math.max(data.length, 1)) * 100) / 100, [totalSales, data.length]);
	const svgPathArea = useMemo(() => expandSvgPathInToArea(svgPath), [svgPath]);

	return (
		<svg className={className} width={GRAPH_WIDTH} height={GRAPH_HEIGHT + GRAPH_SAFE_AREA_SIZE}>
			<defs>
				<linearGradient id="fillGradient" x1="0" y1="0" x2="0" y2="1">
					<stop offset="0%" stopColor={graphColor} />
					<stop offset="90%" stopColor={`${graphColor}00`} />
				</linearGradient>
			</defs>

			<path d={svgPath} fill="none" stroke={graphColor} strokeWidth="2" strokeLinecap="round" />
			<path d={svgPathArea} fill="url(#fillGradient)" />

			{
				maxVolume > 0 &&
				<>
					<text x="0" y="8" fontFamily="Rubik" fill={textColor} fontSize={8}>{maxVolume}</text>
					<text x="0" y={GRAPH_HEIGHT + 10} fontFamily="Rubik" fill={textColor} fontSize={9}>{totalSales} sales / {data.length} days / {averageSalesPerDay} per day</text>
				</>
			}

			{
				maxVolume < 1 &&
				<>
					<text x="0" y="8" fontFamily="Rubik" fill={textColor} fontSize={8}>0</text>
					<text x="0" y={GRAPH_HEIGHT + 10} fontFamily="Rubik" fill={textColor} fontSize={9}>Insufficient sales data</text>
				</>
			}
		</svg>
	);
};

const getSvgPathAndMaxVolume = (data: number[]): [string, number] => {
	if (data.length < 2) {
		return [`M 0 ${GRAPH_HEIGHT} L ${GRAPH_WIDTH} ${GRAPH_HEIGHT}`, 0];
	}

	const maxVolume = Math.max(...data, 5);
	const xd = GRAPH_WIDTH / (data.length - 1);
	const svgPath = data.map((point, i) => {
		const x = Math.round(xd * i * 100) / 100;
		const y = Math.round((GRAPH_HEIGHT - (point / maxVolume) * GRAPH_HEIGHT) * 100) / 100;

		return `${i === 0 ? "M" : "L"} ${x} ${y}`;
	}).join(" ");

	return [svgPath, maxVolume];
};

const expandSvgPathInToArea = (svgPath: string): string => {
	return `${svgPath} V ${GRAPH_HEIGHT} H 0 Z`;
};

import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { PublicCollectionTopBar } from "@fyendalscollection/app/features/collections/components/PublicCollectionTopBar";
import { TabularData } from "@fyendalscollection/app/lib/components/TabularData";
import { TabularDataItem } from "@fyendalscollection/app/lib/components/TabularDataItem";
import styles from "./PagePublicCollection.module.scss";
import { InfiniteScroller } from "@fyendalscollection/app/lib/components/InfiniteScroller";
import { Gallery, GalleryItem } from "@fyendalscollection/app/lib/components/Gallery";
import { CurrencySelector } from "@fyendalscollection/app/lib/components/CurrencySelector";
import { PriceSourceSelector } from "@fyendalscollection/app/lib/components/PriceSourceSelector";
import icon from "@fyendalscollection/app/assets/icon.svg";
import { useDebouncedState } from "@fyendalscollection/app/lib/useDebouncedState";
import { CollectionProductSearch } from "@fyendalscollection/app/features/collections/components/CollectionProductSearch";
import { Currency, PriceSource, PublicTransactionSummariesSortOrder, PublicTransactionSummariesView, useAuthState, usePublicCollection, usePublicCollectionCurrencyAndPriceSource, usePublicTransactionSummariesByShareToken, useUserPreferencesState } from "@fyendalscollection/app/shared";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { StyledSelect } from "@fyendalscollection/app/lib/components/StyledSelect";
import { ProductImage } from "@fyendalscollection/app/lib/components/ProductImage";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";
import { SearchSyntaxModal } from "@fyendalscollection/app/features/products/components/SearchSyntaxModal";
import { CallToAction } from "@fyendalscollection/app/lib/components/CallToAction";

export const PagePublicCollection = (): React.JSX.Element => {
	const {
		state
	} = useUserPreferencesState();

	const params = useParams();
	const shareToken = params["shareToken"] as string;

	const publicCollectionCurrencyAndPriceSource = usePublicCollectionCurrencyAndPriceSource();

	useEffect(() => {
		if (!state.publicCollectionCurrency || !state.publicCollectionPriceSource) {
			publicCollectionCurrencyAndPriceSource.mutate(shareToken);
		}
	}, [shareToken]);

	const currency = state.publicCollectionCurrency || publicCollectionCurrencyAndPriceSource.data?.body.currency;
	const priceSource = state.publicCollectionPriceSource || publicCollectionCurrencyAndPriceSource.data?.body.priceSource;

	if (publicCollectionCurrencyAndPriceSource.isError) {
		return (
			<Limit force={LimitWidth.Medium}>
				<Container>
					<ContainerSegment>
						<ErrorDisplay problemResponse={publicCollectionCurrencyAndPriceSource.error}/>
					</ContainerSegment>
				</Container>
			</Limit>
		);
	}

	if (!currency || !priceSource) {
		return (
			<Limit force={LimitWidth.Medium}>
				<Loadable loading={true}>
					<></>
				</Loadable>
			</Limit>
		);
	}

	return (
		<PagePublicCollectionInternal currency={currency} priceSource={priceSource} shareToken={shareToken} />
	);
};

interface PagePublicCollectionInternalProps {
	currency: Currency;
	priceSource: PriceSource;
	shareToken: string;
}

const PagePublicCollectionInternal = (props: PagePublicCollectionInternalProps): React.JSX.Element => {
	const [debouncedSearchQuery, searchQuery, setSearchQuery] = useDebouncedState("", 500);
	const [showSyntaxModal, setShowSyntaxModal] = useState(false);

	const {
		state: userPreferencesState,
		dispatchSetPublicTransactionSummariesSortOrder,
		dispatchSetPublicTransactionSummariesView,
		dispatchSetPublicCollectionCurrency,
		dispatchSetPublicCollectionPriceSource
	} = useUserPreferencesState();

	const {
		state: authState
	} = useAuthState();

	const publicCollection = usePublicCollection(props.shareToken, props.currency, props.priceSource);

	const publicTransactionSummaries = usePublicTransactionSummariesByShareToken(props.shareToken, props.currency, props.priceSource, debouncedSearchQuery, userPreferencesState.publicTransactionSummariesSortOrder);

	useTitle(publicCollection.data ? publicCollection.data.name : "Public Collection");

	const pages = publicTransactionSummaries.data?.pages ?? [];

	const noSearchResults = (
		<CallToAction
			title="No search results found!"
			subtitle="Try a different search term or click the help icon in the search bar for our filters"
		/>
	);

	return (
		<Limit>
			<Loadable loading={publicCollection.isLoading}>
				{
					publicCollection.isError &&
					<Container>
						<ContainerSegment>
							<ErrorDisplay problemResponse={publicCollection.error}/>
						</ContainerSegment>
					</Container>
				}

				{publicCollection.data &&
					<>
						<div className={styles.titleDisplay}>
							<Link to="/login" className={styles.title}>
								<img src={icon} />
								<div>Fyendal&apos;s Collection</div>
							</Link>

							{
								!authState.accessToken &&
								<GreenButtonSet buttons={[
									{
										text: "Create Account",
										link: "/login"
									}
								]}/>
							}
						</div>

						<PageTitle
							title={publicCollection.data.name}
							subTitle={`Owned by ${publicCollection.data.displayName}`}
							emblem={publicCollection.data}
							primaryActions={
								<>
									<PriceSourceSelector styled={true} value={userPreferencesState.publicCollectionPriceSource || props.priceSource} onChange={dispatchSetPublicCollectionPriceSource} />

									<CurrencySelector styled={true} value={userPreferencesState.publicCollectionCurrency || props.currency} onChange={dispatchSetPublicCollectionCurrency} />

									<StyledSelect value={userPreferencesState.publicTransactionSummariesView} onChange={dispatchSetPublicTransactionSummariesView} options={[
										{ text: "View: Gallery", value: PublicTransactionSummariesView.Gallery },
										{ text: "View: List", value: PublicTransactionSummariesView.List }
									]} />

									<StyledSelect value={userPreferencesState.publicTransactionSummariesSortOrder} onChange={dispatchSetPublicTransactionSummariesSortOrder} options={[
										{ text: "Sort: Alphabetical", value: PublicTransactionSummariesSortOrder.Alphabetical },
										{ text: "Sort: Quantity", value: PublicTransactionSummariesSortOrder.Quantity },
										{ text: "Sort: Market Value", value: PublicTransactionSummariesSortOrder.MarketValue },
										{ text: "Sort: Unit Value", value: PublicTransactionSummariesSortOrder.UnitValue }
									]} />
								</>
							} />

						<PublicCollectionTopBar totalQuantity={publicCollection.data.totalQuantity} totalMarketValue={publicCollection.data.totalMarketValue} />

						<CollectionProductSearch
							value={searchQuery}
							onChange={setSearchQuery}
							helpOnClick={() => setShowSyntaxModal(true)} />
						{
							publicTransactionSummaries.isError &&
								<div>
									<ErrorDisplay problemResponse={publicTransactionSummaries.error} />
								</div>
						}

						<SearchSyntaxModal showModal={showSyntaxModal} closeModal={() => setShowSyntaxModal(false)} />

						<Loadable loading={publicTransactionSummaries.isLoading}>
							{
								pages.flatMap(x => x.results).length === 0 && !publicTransactionSummaries.isLoading && !publicTransactionSummaries.error ?
									noSearchResults
									:
									userPreferencesState.publicTransactionSummariesView === PublicTransactionSummariesView.List ? (
										<>
											<TabularData headers={["Product", "QTY", "Unit Value", "Total Value"]}>
												{
													pages.flatMap(x => x.results).map(x => (
														<TabularDataItem key={x.variantId}>
															<>
																<Link className={styles.productDisplay} to={`/browse/product/${x.productId}`}>
																	<ProductImage className={styles.productImage} image={x.imageUrl} />
																	<div>
																		<div>{x.productName}</div>
																		<div>{x.fcId}</div>
																	</div>
																</Link>
															</>
															<><div className={styles.transactionDetails}>{x.quantity}</div></>
															<><PrettyMoney preferredCurrency={props.currency} className={styles.transactionDetails} money={x.marketValue} mode={MoneyDisplayMode.Standard} /></>
															<><PrettyMoney preferredCurrency={props.currency} className={styles.transactionDetails} money={x.totalMarketValue} mode={MoneyDisplayMode.Standard} /></>
														</TabularDataItem>
													))
												}
											</TabularData>
											<InfiniteScroller forQuery={publicTransactionSummaries} />
										</>
									) : (
										<Gallery>
											{
												pages.flatMap(x => x.results).map(x => (
													<GalleryItem
														key={x.variantId}
														title={x.productName}
														subtitle={x.fcId}
														imageUrl={x.imageUrl}
														imageLink={`/browse/product/${x.productId}`}
														details={[
															{
																description: "Quantity",
																value: x.quantity
															},
															{
																description: "Market Value",
																value: <PrettyMoney preferredCurrency={props.currency} money={x.marketValue} mode={MoneyDisplayMode.Standard} />
															},
															{
																description: "Total Value",
																value: <PrettyMoney preferredCurrency={props.currency} money={x.totalMarketValue} mode={MoneyDisplayMode.Standard} />
															}
														]} />
												))
											}
											<InfiniteScroller forQuery={publicTransactionSummaries} />
										</Gallery>
									)
							}
						</Loadable>
					</>
				}
			</Loadable>
		</Limit>
	);
};

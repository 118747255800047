import { useState } from "react";
import { CurrencySelector } from "./CurrencySelector";
import { Currency } from "@fyendalscollection/app/shared";
import { TitleSecondary } from "@fyendalscollection/app/lib/components/TitleSecondary";
import { Modal } from "@fyendalscollection/app/lib/components/Modal";
import { Paragraph } from "@fyendalscollection/app/lib/components/Paragraph";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";

export interface ModalCurrencyProps {
	visible: boolean;
	onCancel: () => void;
	value: Currency;
	onChange: (value: Currency) => void;
}

export const ModalCurrency = ({ visible, onCancel, value, onChange }: ModalCurrencyProps): React.JSX.Element => {
	const [selectedCurrency, setSelectedCurrency] = useState(value);

	return (
		<Modal visible={visible} onCancel={onCancel}>
			<TitleSecondary text="Currency" />
			<Paragraph>
				Please select a currency from the list below.
			</Paragraph>
			<Container>
				<ContainerSegment>
					<CurrencySelector value={selectedCurrency} onChange={setSelectedCurrency} />
				</ContainerSegment>
			</Container>
			<GreenButtonSet buttons={[
				{
					text: "Confirm",
					onClick: () => {
						onChange(selectedCurrency);
						onCancel();
					}
				}
			]} />
		</Modal>
	);
};

import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TabularData, TabularDataHeaderStyle } from "@fyendalscollection/app/lib/components/TabularData";
import { InfiniteScroller } from "@fyendalscollection/app/lib/components/InfiniteScroller";
import dayjs from "dayjs";
import { ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { TransactionAction, useCollection, useCollectionVariantDetails, useTransactionCreate, useVariantTransactionDetails } from "@fyendalscollection/app/shared";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { Limit, LimitWidth } from "@fyendalscollection/app/lib/components/Limit";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { GreenButtonSet } from "@fyendalscollection/app/lib/components/GreenButtonSet";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { TransactionItem, TransactionItemDestination } from "@fyendalscollection/app/features/transactions/components/TransactionItem";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { TopBar } from "@fyendalscollection/app/features/collections/components/TopBar";

export const PageVariantTransactions = (): React.JSX.Element => {
	const params = useParams();
	const collectionId = params.collectionId || "";
	const variantId = params.variantId || "";
	const collection = useCollection(collectionId);
	const navigate = useNavigate();

	const transactionDetails = useVariantTransactionDetails(collectionId, variantId);
	const collectionVariantDetails = useCollectionVariantDetails(collectionId, variantId);
	const transactionCreate = useTransactionCreate();

	useTitle(collectionVariantDetails.data ? collectionVariantDetails.data.productName : "Loading...");

	const display = !transactionDetails.data || !collectionId ? [] :
		transactionDetails.data.pages.flatMap(x => x.results).map(transaction => (
			<TransactionItem key={transaction.transactionId} transaction={transaction} collectionId={collectionId} destination={TransactionItemDestination.ProductPage} />
		));

	useEffect(() => {
		if (transactionDetails.data && transactionDetails.data.pages.flatMap(x => x.results).length === 0) {
			navigate(`/collections/${collectionId}`);
		}
	}, [transactionDetails.data]);

	return (
		<Limit force={LimitWidth.Massive}>
			<Loadable loading={collection.isLoading}>
				{
					collection.data && collectionVariantDetails.data && (
						<>
							<Breadcrumb crumbs={[
								{
									text: "Dashboard",
									link: "/dashboard"
								},
								{
									text: "Collections",
									link: "/collections"
								},
								{
									text: collection.data.name,
									link: `/collections/${collectionId}`
								},
								{
									text: "Transactions",
									link: `/collections/${collectionId}/transactions`
								},
								{
									text: collectionVariantDetails.data.productName
								}
							]} />

							<PageTitle
								title={collectionVariantDetails.data.productName}
								subTitle={collectionVariantDetails.data.variantName}
								primaryActions={
									<GreenButtonSet buttons={[
										{
											text: "New Transaction",
											onClick: () => transactionCreate.mutate({
												requestModel: {
													variantId: variantId,
													occurredDate: dayjs().format("YYYY-MM-DD"),
													action: TransactionAction.Purchase,
													quantity: 1,
													unitAmount: {
														value: collectionVariantDetails.data.marketValue.value / collectionVariantDetails.data.quantity,
														currency: collectionVariantDetails.data.marketValue.currency
													}
												},
												collectionId: collectionId
											})
										}
									]} />
								} />

							{transactionCreate.isError && <ContainerSegment>
								<ErrorDisplay problemResponse={transactionCreate.error}/>
							</ContainerSegment>}

							<TopBar
								marketValue={collectionVariantDetails.data.marketValue}
								invested={collectionVariantDetails.data.invested}
								gain={collectionVariantDetails.data.gain}
								profit={collectionVariantDetails.data.profit}
								cashPosition={collectionVariantDetails.data.cashPosition}
								quantity={collectionVariantDetails.data.quantity}
								averageBuyPrice={collectionVariantDetails.data.averageBuyPrice} />
							{
								display && display.length > 0 && (
									<TabularData headerStyle={TabularDataHeaderStyle.CenteredExceptFirst} headers={["Product", "QTY", "Action", "Unit Amount", "Total Amount", "Date", ""]}>
										{display}
									</TabularData>
								)
							}
						</>
					)
				}
			</Loadable>
			<InfiniteScroller forQuery={transactionDetails}/>
		</Limit>
	);
};

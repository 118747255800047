import { useParams, Link } from "react-router-dom";
import { Gallery, GalleryItem } from "@fyendalscollection/app/lib/components/Gallery";
import { TabularData } from "@fyendalscollection/app/lib/components/TabularData";
import { TabularDataItem } from "@fyendalscollection/app/lib/components/TabularDataItem";
import { PremiumPromotionContainer } from "@fyendalscollection/app/lib/components/PremiumPromotionContainer";
import { InfiniteScroller } from "@fyendalscollection/app/lib/components/InfiniteScroller";
import styles from "./PageCollectionPerformanceDeltas.module.scss";
import { CollectionPerformanceDeltasView, PerformanceDeltaSort, PerformanceDeltaStep, useCollection, useCollectionPerformanceDeltas, useUserPreferencesState } from "@fyendalscollection/app/shared";
import { useTitle } from "@fyendalscollection/app/lib/useTitle";
import { useSubscription } from "@fyendalscollection/app/shared";
import { Limit } from "@fyendalscollection/app/lib/components/Limit";
import { Loadable } from "@fyendalscollection/app/lib/components/Loadable";
import { Breadcrumb } from "@fyendalscollection/app/lib/components/Breadcrumb";
import { ErrorDisplay } from "@fyendalscollection/app/lib/components/ErrorDisplay";
import { CollectionTab, CollectionTabSelector } from "@fyendalscollection/app/features/collections/components/CollectionTabSelector";
import { StyledSelect } from "@fyendalscollection/app/lib/components/StyledSelect";
import { PageTitle } from "@fyendalscollection/app/lib/components/PageTitle";
import { Container, ContainerSegment } from "@fyendalscollection/app/lib/components/Container";
import { MoneyDisplayMode, PrettyMoney } from "@fyendalscollection/app/lib/components/PrettyMoney";
import { ProductImage } from "@fyendalscollection/app/lib/components/ProductImage";
import { PerformanceDeltasFilter } from "@fyendalscollection/app/features/performanceDeltas/components/PerformanceDeltasFilter";
import { VolumeGraph } from "@fyendalscollection/app/lib/components/VolumeGraph";
import { ENABLE_SALE_VOLUMES } from "@fyendalscollection/app/lib/config";

export const PageCollectionPerformanceDeltas = (): React.JSX.Element => {
	const { collectionId } = useParams();
	const collection = useCollection(collectionId || "");

	useTitle(collection.data ? collection.data.name : "Collection");

	const { state, dispatchSetCollectionPerformanceDeltasView } = useUserPreferencesState();

	const {
		collectionPerformanceDeltasView,
		performanceDeltaStep,
		performanceDeltaMinimum,
		performanceDeltaSort
	} = state;

	const subscription = useSubscription();

	return (
		<Limit>
			<Loadable loading={collection.isLoading}>
				{collection.data && (
					<>
						<Breadcrumb crumbs={[
							{
								text: "Dashboard",
								link: "/dashboard"
							},
							{
								text: "Collections",
								link: "/collections"
							},
							{
								text: collection.data.name,
								link: `/collections/${collectionId}`
							},
							{
								text: "Trends"
							}
						]} />

						<PageTitle
							title={collection.data.name}
							subTitle={collection.data.description}
							primaryActions={<CollectionTabSelector selected={CollectionTab.Trends} />}
							secondaryActions={
								<>
									<StyledSelect value={collectionPerformanceDeltasView} onChange={dispatchSetCollectionPerformanceDeltasView} options={[
										{ text: "View: List", value: CollectionPerformanceDeltasView.List },
										{ text: "View: Gallery", value: CollectionPerformanceDeltasView.Gallery }
									]} />
									<PerformanceDeltasFilter />
								</>} />

						{
							collectionId && subscription.data && (
								subscription.data.activeSubscription
									? <PageCollectionPerformanceDeltasInternal
										collectionId={collectionId}
										view={collectionPerformanceDeltasView}
										step={performanceDeltaStep}
										minimum={performanceDeltaMinimum}
										sort={performanceDeltaSort} />
									: <PremiumPromotionContainer featureName="Collection Trends" />
							)
						}
					</>
				)}
			</Loadable>
		</Limit>
	);
};

interface PageCollectionPerformanceDeltasInternalProps {
	collectionId: string;
	view: CollectionPerformanceDeltasView;
	step: PerformanceDeltaStep;
	minimum: number;
	sort: PerformanceDeltaSort;
}

const PageCollectionPerformanceDeltasInternal = ({ collectionId, view, step, minimum, sort }: PageCollectionPerformanceDeltasInternalProps): React.JSX.Element => {
	const collectionPerformanceDeltas = useCollectionPerformanceDeltas(collectionId, step, minimum, sort);

	if (collectionPerformanceDeltas.error) {
		return (
			<Container>
				<ContainerSegment>
					<ErrorDisplay problemResponse={collectionPerformanceDeltas.error} />
				</ContainerSegment>
			</Container>
		);
	}

	const pages = collectionPerformanceDeltas.data?.pages ?? [];
	const headers = ENABLE_SALE_VOLUMES
		? [ "Product", "Sale Volumes", "Gain", "Current Price", "Previous Price" ]
		: [ "Product", "Gain", "Current Price", "Previous Price" ];

	return (
		<Loadable loading={collectionPerformanceDeltas.isLoading}>
			{
				view == CollectionPerformanceDeltasView.List ? (
					<TabularData headers={headers}>
						{
							pages.flatMap(x => x.results).map(x => (
								<TabularDataItem key={x.variantId}>
									<>
										<Link className={styles.productDisplay} to={`/browse/product/${x.productId}`}>
											<ProductImage className={styles.productImage} image={x.imageUrl} />
											<div>
												<div>{x.productName}</div>
												<div>{x.fcId}</div>
											</div>
										</Link>
									</>
									{ ENABLE_SALE_VOLUMES && <><VolumeGraph data={x.saleVolumes} /></> }
									<><PrettyMoney className={styles.price} money={x.delta} mode={MoneyDisplayMode.DeltaPercentage} originalValue={x.previousPrice.value} /></>
									<><PrettyMoney className={styles.price} money={x.currentPrice} mode={MoneyDisplayMode.Standard} /></>
									<><PrettyMoney className={styles.price} money={x.previousPrice} mode={MoneyDisplayMode.Standard} /></>
								</TabularDataItem>
							))
						}
					</TabularData>
				) : (
					<Gallery>
						{
							pages.flatMap(x => x.results).map(x => (
								<GalleryItem
									key={x.variantId}
									title={x.productName}
									subtitle={x.fcId}
									imageUrl={x.imageUrl}
									imageLink={`/browse/product/${x.productId}`}
									details={[
										{
											description: "Gain",
											value: <PrettyMoney className={styles.price} money={x.delta} mode={MoneyDisplayMode.DeltaPercentage} originalValue={x.previousPrice.value} />
										},
										{
											description: "Current",
											value: <PrettyMoney className={styles.price} money={x.currentPrice} mode={MoneyDisplayMode.Standard} />
										},
										{
											description: "Previous",
											value: <PrettyMoney className={styles.price} money={x.previousPrice} mode={MoneyDisplayMode.Standard} />
										}
									]} />
							))
						}
					</Gallery>
				)
			}
			<InfiniteScroller forQuery={collectionPerformanceDeltas} />
		</Loadable>
	);
};
